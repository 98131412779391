import styled from 'styled-components';

export const ProductContainerStyled = styled.div`
  display: flex;
  //flex-direction: row;
  width: 100%;
  height: 100%;
  text-align: center;
  align-content: center;
  overflow: visible;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
`;

export const StateText = styled.span<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? '#03CE2F' : '#CE0327')};
`;
