import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSchedule } from '../../../api/quotations';
import { IFetchedScheduleResponse } from '../../../api/quotations/models';

export const fetchScheduleAsync = createAsyncThunk('schedule/fetch', async (token: string, { rejectWithValue }) => {
  try {
    const data: IFetchedScheduleResponse = await fetchSchedule(token);
    if (data.statusCode !== 200) throw new Error(data.message);
    return data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});
