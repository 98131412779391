import { IMenu } from '../../api/menu/models';
import { IExtraService, PricingType } from '../../models/IExtraService';

type CalculatePricesProps = {
  childrenQuantity: number;
  extraServices: string[];
  extraServicesList: IExtraService[];
  menuSelected: IMenu;
  peopleQuantity: number;
};

export const calculatePrices = ({
  childrenQuantity,
  extraServices,
  extraServicesList,
  menuSelected,
  peopleQuantity,
}: CalculatePricesProps) => {
  const totalValueExtraServices = extraServices.reduce((prev, current) => {
    const [item] = extraServicesList.filter((item) => item._id === current);
    const total =
      item.pricingType === PricingType.UNIQUE
        ? item.price
        : item.pricingType === PricingType.PER_ADULT
        ? item.price * peopleQuantity
        : item.price * (peopleQuantity + childrenQuantity);
    return prev + total;
  }, 0);

  const childrenMenuValue =
    menuSelected?.hasChildrenMenu && menuSelected.childrenMenu
      ? menuSelected.childrenMenu?.basic_children_price * childrenQuantity
      : 0;

  const adultMenuValue = menuSelected.basic_person_price * peopleQuantity;
  const total = adultMenuValue + childrenMenuValue + totalValueExtraServices;

  const suggestedPrice =
    peopleQuantity >= menuSelected.minimal_people
      ? menuSelected.discount_price * peopleQuantity + childrenMenuValue + totalValueExtraServices
      : total;

  return { adultMenuValue, childrenMenuValue, suggestedPrice, total, totalValueExtraServices };
};
