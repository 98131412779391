import React, { Dispatch, SetStateAction, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import CheckBox from '../../../components/MenuTable/components/CheckBox';
import Modal from '../../../components/Modal';
import Image from '../../../components/ProductImageDetail';
import Text from '../../../components/Text';
import TextArea from '../../../components/TextArea';
import useForm from '../../../hooks/useForm';
import { getAuthenticatedToken } from '../../../redux/auth';
import { postSaladAsync } from '../../../redux/saladSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadImage } from '../../../utils/file';
import { ContainerVisibility } from '../EditSalad/styles';
import { fieldSchema } from './schema';
import { ContainerInput, StyledButtons } from './styles';

interface ICreateSaladProps {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  onClickDelete: () => void;
}

interface ICreateSalad {
  name: string;
  description?: string;
  file: File | undefined;
  isActive: string;
}

const CreateSalad = ({ isOpent, setIsOpent }: ICreateSaladProps) => {
  const { fields, errors, onChangeField, isValidForm, onSubmit, resetForm } = useForm({ fieldSchema });
  const [image, setImage] = useState<{ file: File; src: string | undefined }>();

  const isValidInputs = !!(isValidForm && image);

  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const onSend = () => {
    if (!onSubmit()) return;

    const salad: ICreateSalad = {
      name: fields.name,
      file: image?.file,
      description: fields.description,
      isActive: String(fields.isActive),
    };

    dispatch(postSaladAsync({ salad, token }));
    reset();
  };

  const reset = () => {
    setImage(undefined);
    resetForm();
    setIsOpent(false);
  };

  const onChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const src = await loadImage(e.target.files[0]);
      setImage({ src, file: e.target.files[0] });
    }
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="sm">
      <Text type="body" alignment="start" color="principal">
        Nueva ensalada
      </Text>
      <Image onChange={onChangeImage} src={image?.src} data-testid="image-input" />
      <ContainerInput>
        <Input
          value={fields.name}
          onChange={(value) => onChangeField({ name: 'name', value })}
          label="Nombre"
          placeholder="Escribe el nombre de la ensalada"
          data-testid="name-input"
          border
          messageError={errors.name?.message}
          error={errors.name?.error}
        />
      </ContainerInput>
      <ContainerInput>
        <TextArea
          value={fields.description}
          onChange={(e) => onChangeField({ name: 'description', value: e.target.value })}
          label="Descripción"
          placeholder="Escribe una descripción"
          data-testid="description-input"
        />
      </ContainerInput>
      <ContainerInput>
        <Text type="body" alignment="start">
          Visible en la web
        </Text>
        <ContainerVisibility>
          <CheckBox
            checked={fields.isActive}
            onChange={(e) => onChangeField({ name: 'isActive', value: e.target.checked })}
            data-testid="active-test"
          />
          <div>
            <Text type="body" alignment="start">
              Si
            </Text>
          </div>
        </ContainerVisibility>
      </ContainerInput>

      <StyledButtons>
        <Button size="xs" variant="black" onClick={reset} data-testid="reset-form-btn">
          Cancelar
        </Button>
        <Button data-testid="button-test-add" size="xs" onClick={onSend} disabled={!isValidInputs}>
          Agregar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default CreateSalad;
