import styled from 'styled-components';
import { handleColorType } from '../../../../theme';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const TableWrapperStyled = styled.div`
  padding: 5px 6px;
  margin-bottom: 32px;
  > section {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
`;

export const TitleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding-right: 6px;
  margin-bottom: 0.5vh;
  gap: 12px;
  ${mediaQuery.mobileOnly(`
    flex-wrap: wrap;
    justify-content: center;
  `)}
`;

export const StatusWrapperStyled = styled.div<{ status }>`
  background: ${({ status }) => handleColorType(status)};
  color: white;
  height: 4vh;
  line-height: 4vh;
  text-align: center;
  padding: 0 0.6vw;
  border-radius: 3px;
  margin: 0 0.8vw;
`;

export const CellStyled = styled.td`
  font-weight: normal;
  height: 55px;
  font-size: 16px;
  line-height: 55px;
  vertical-align: middle;
  padding-left: 1vw;
  :first-child {
    width: 75px;
    text-align: center;
    padding-left: 0;
  }
  :last-child {
    padding-left: 4vw;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 2;
  }
`;
