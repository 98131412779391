export const toGMTDate = (value: string) => {
  const pad = (n: number) => (n < 10 ? `0${n}` : n);
  const gmtDate = new Date(value);
  const date = `${gmtDate.getFullYear()}-${pad(gmtDate.getMonth() + 1)}-${pad(gmtDate.getDate())}`;
  const time = gmtDate.toTimeString().slice(0, 5);
  return { date, time };
};

export const toIsoDate = (date: string, time: string) => new Date(date + ' ' + time).toISOString();

export const toDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(+year, +month - 1, +day);
};
