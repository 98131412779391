import styled from 'styled-components';
import { theme } from '../../../../theme';
import ImageLogin from '../../../../assets/imageLogin/Rectangle.svg';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const ContainerImage = styled.div`
  display: flex;
  width: 50%;
  height: auto;
  background-image: url(${ImageLogin});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaQuery.mobileOnly(`
   display: none;
  `)}
  ${mediaQuery.tabletOnly(`
   display: none;
  `)}
  ${mediaQuery.laptopToDesktop(`
   display: none;
  `)}
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100vh;
`;

export const LoginContainerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #131313;
  height: auto;
  width: 50%;

  ${mediaQuery.mobileOnly(`
   width: 100%;
  `)}
  ${mediaQuery.tabletOnly(`
   width: 100%;
  `)}
  ${mediaQuery.laptopToDesktop(`
   width: 100%;
  `)}
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 0.1rem;
  box-sizing: border-box;
  width: 100%;
`;

export const InputContainerWrapper = styled.div`
  width: 435px;
  border-radius: 20px;
  background-color: #222222;
  box-sizing: border-box;
  ${mediaQuery.mobileOnly(`
   max-width: 435px;
   width: 90%;
  `)}
`;

export const Link = styled.a`
  color: ${theme.text.color.principal};
  font-size: ${theme.text.type.h3.weight.bold};
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  outline: none;
  cursor: pointer;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
`;
