import styled from 'styled-components';
import { ButtonProps } from '..';
import { theme } from '../../../theme';

export const ButtonWrapper = styled.button<ButtonProps>`
  width: ${({ fullWidth, size = 'sm' }) => (fullWidth ? '100%' : theme.button.sizes[size].width)};
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
  height: ${({ size = 'sm' }) => theme.button.sizes[size].height};
  max-height: 50px;

  transition: 0.15s all ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-family: 'Mulish';
  font-weight: bold;
  border-radius: ${({ pill }) => (pill ? theme.button.borderRadius.pill : theme.button.borderRadius.normal)};

  font-size: ${({ size = 'sm' }) => theme.button.sizes[size].fontSize};
  line-height: ${({ size = 'sm' }) => theme.button.sizes[size].lineHeight};
  background-color: ${({ variant = 'default' }) => theme.button.type[variant].backgroundColor};
  border-color: ${({ variant = 'default'}) =>
    theme.button.type[variant].borderColor};
  color: ${({ variant = 'default' }) => theme.button.type[variant].color};
  &:disabled {
    cursor: not-allowed;
    background-color: ${() => theme.button.type.disabled.backgroundColor};
    border-color: ${() => theme.button.type.disabled.borderColor};
    color: ${() => theme.button.type.disabled.color};
  }
`;

//theme.button.type.outline.backgroundColor
