/* istanbul ignore file */
import { IBaseFetch, IFetchResponse } from '../../models/IFetch';
import { IDisabledDate, IQuotation, IQuotationPaginated, ISchedule } from '../../models/IQuotation';
import { IFetchPaginatedQuotations } from '../../redux/quotationSlice/thunks';
import {
  IAllQuotationsResponse,
  ICreatedQuotationResponse,
  ICreateQuotation,
  IDisabledDateResponse,
  IUpdatedQuotationResponse,
  IUpdateQuotation,
} from './models';
import URL from './url';

export const fetchSchedule = async (token: string): Promise<IFetchResponse<ISchedule[]>> => {
  const { url, params } = URL.getSchedule(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const fetchPaginatedQuotations = async (
  props: IFetchPaginatedQuotations
): Promise<IFetchResponse<IQuotationPaginated>> => {
  const { url, params } = URL.getPaginatedQuotations(props);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const fetchAllQuotations = async (
  token: string,
  update: string
): Promise<IFetchResponse<IAllQuotationsResponse>> => {
  const { url, params } = URL.getAllQuotations(token, update);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const createQuotation = async (
  token: string,
  body: ICreateQuotation
): Promise<IFetchResponse<ICreatedQuotationResponse>> => {
  const { url, params } = URL.postQuotation(token, body);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const updateQuotation = async (
  token: string,
  body: IUpdateQuotation,
  quotationId: string
): Promise<IFetchResponse<IUpdatedQuotationResponse>> => {
  const { url, params } = URL.putQuotation(token, body, quotationId);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const deleteQuotation = async (quotationId: string, token: string): Promise<IFetchResponse<IQuotation[]>> => {
  const { url, params } = URL.deleteQuotation(quotationId, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const notifyConfirmationQuotation = async (quotationId: string, token: string): Promise<IBaseFetch> => {
  const { url, params } = URL.nofityConfirmationQuotation(quotationId, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const fetchDisabledDates = async (token: string): Promise<IFetchResponse<IDisabledDate[]>> => {
  const { url, params } = URL.getDisabledDates(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const postDisabledDate = async (date: string, token: string): Promise<IFetchResponse<IDisabledDateResponse>> => {
  const { url, params } = URL.postDisabledDate(date, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const deleteDisabledDate = async (date: string, token: string): Promise<IFetchResponse<IDisabledDate[]>> => {
  const { url, params } = URL.deleteDisabledDate(date, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
