import { Dispatch, SetStateAction } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Select from '../../../components/InputSelect';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { getAuthenticatedToken } from '../../../redux/auth';
import { createExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { parseNumber } from '../../../utils/parseNumber';
import { fieldSchema } from './schema';
import { ButtonContainer } from './styles';
import { PricingType } from '../../../models/IExtraService';

export type CreateExtraServiceProps = {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
};

const CreateExtraService = ({ isOpent, setIsOpent }: CreateExtraServiceProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const { fields, onChangeField, errors, isValidForm, onSubmit, resetForm } = useForm({
    fieldSchema,
    validateOnInit: true,
  });

  const onSend = () => {
    if (!onSubmit()) return;
    dispatch(createExtraServicesAsync({ token, extraService: fields }));
    setIsOpent(false);
    resetForm();
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="sm" isStatic>
      <Text type="h2">Nuevo servicio</Text>
      <Input
        data-testid="create-extra-service-name"
        label="Nombre"
        placeholder="Escribe el nombre del servicio"
        labelColor="selected"
        value={fields.name}
        onChange={(value) => onChangeField({ name: 'name', value })}
        error={errors?.name?.error}
        messageError={errors?.name?.message}
      />
      <Input
        data-testid="create-extra-service-price"
        label="Precio"
        labelColor="selected"
        value={parseNumber.withMillerDot(fields.price)}
        onChange={(value) => onChangeField({ name: 'price', value: parseNumber.clean(value) })}
        error={errors?.price?.error}
        messageError={errors?.price?.message}
      />
      <Select
        label="Tipo precio"
        value={fields.pricingType}
        onChange={(value) => onChangeField({ name: 'pricingType', value })}
        options={Object.values(PricingType)}
      />
      <ButtonContainer>
        <Button data-testid="create-extra-service-btn" size="xs" disabled={!isValidForm} onClick={onSend}>
          Crear
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default CreateExtraService;
