import styled from 'styled-components';
import { mediaQuery } from '../../../utils/mediaQuery';

import { handleColorType, theme } from '../../../theme';
import { ListBodyValueProps } from '../../Quotation/QuotationManager';

import { QuotationStatusProps } from '..';

export const HomeLayout = styled.div`
  display: flex;
  width: 100%;
  column-gap: 1vw;
  margin-top: 3vh;
  ${mediaQuery.mobileToDesktop(`
  flex-direction: column;
`)}
`;

//Top side notifications
export const NotificationDisplay = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1vw;
  margin-top: 20px;
  ${mediaQuery.tabletToLaptop(`
    grid-template-columns: repeat(2, 1fr);
  `)}
  ${mediaQuery.mobileOnly(`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

export const NotificationCard = styled.div`
  display: flex;
  background: #222222;
  column-gap: 1vw;
  padding: 10px;
  align-items: center;
  :first-of-type {
    background: #eb9f1a;
  }
`;

export const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
`;

export const DataInfo = styled.div`
  padding: 5px 6px;
  font-size: 30px;
`;

export const LeftSide = styled.div`
  flex: 50;
`;

export const RightSide = styled.div`
  flex: 50;
`;

export const SideWrapper = styled.div`
  background: #222222;
  padding: 2vh 1vw;
  color: white;
  margin: 12px 0;
  ${mediaQuery.mobileOnly(`
    height: fit-content;
  `)}
  ${mediaQuery.tabletToLaptop(`
    height: fit-content;
  `)}
`;

export const LeftSideWrapper = styled.div`
  background: #222222;
  color: white;
  ${mediaQuery.mobileOnly(`
    height: fit-content;
  `)}
  ${mediaQuery.tabletToLaptop(`
    height: fit-content;
  `)}
`;

export const EventWrapper = styled.div`
  background: #222222;
  align-items: center;
  color: #808080;
  padding: 1.5vh 0;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  :last-child {
    margin: 5px 0;
  }
  ${mediaQuery.mobileToLaptop(`
   grid-template-columns: repeat(2, 1fr);
   font-size: 12px;
  `)}
`;

export const EventInfo = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  height: 100%;

  column-gap: 5px;
  white-space: nowrap;
  padding: 0 16px;
  margin: 6px auto;
  :first-child {
    border-right: 1px solid #808080;
  }
`;

export const MenuEventInfo = styled.div<QuotationStatusProps>`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  color: ${({ status }) => handleColorType(status)};
`;

export const NoEvent = styled.div`
  font-size: 16px;
  align-items: center;
  height: 37px;
  white-space: nowrap;

  padding-left: 20%;
  align-items: center;
  line-height: 37px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ListContainer = styled.div`
  width: 100%;
  background: ${theme.text.color.card};
  color: ${theme.text.color.texts};
  margin: 12px 0;
`;

export const ListHeader = styled.div`
  display: none;
  gap: 0;
  ${mediaQuery.laptopToUp(`
    display: flex;
    justify-content: center;
    padding: 12px;
    gap: 10px;
    > :last-child {
      max-width: 60px;
    }
  `)}
`;

export const ListHeaderColumn = styled.div`
  width: 18%;
`;

export const ListBodyColumn = styled.div`
  width: 50%;
  ${mediaQuery.laptopToUp(`
    display: none;
  `)}
`;

export const ListBodyButtonContainer = styled.div`
  padding: 10px 0;
  > button {
    max-width: 60px;
  }
`;

export const ListBodyValue = styled.div<ListBodyValueProps>`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 14px;
  color: ${({ status }) => handleColorType(status)};
  padding: 10px 0;
  ${mediaQuery.laptopToUp(`
    width: 18%;
  `)}

  &:nth-child(8) {
    padding-right: 4px;
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0;
  padding: 12px;
  border-top: 1px solid #4b4b4b;
  ${mediaQuery.laptopToUp(`
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: center;
  `)}
`;

export const EventsMessageStyled = styled.div`
  display: flex;
  background: ${theme.text.color.card};
  gap: 40px;
  padding: 20px;
  margin-bottom: 22px;
`;
