import { Dispatch, SetStateAction } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { getFieldSchema } from '../../../utils/getFieldSchema';
import { fieldSchema } from '../CreateMenu/schemas';
import { StyledButtons } from '../CreateMenu/styles';

export interface IEditMenuNameProps {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  name: string;
  onSend: (value: string) => void;
}

const EditMenuName = ({ isOpent, setIsOpent, name, onSend }: IEditMenuNameProps) => {
  const schema = getFieldSchema({ fieldSchema, fieldValues: { name } });
  const { fields, errors, onChangeField, isValidForm } = useForm({ fieldSchema: schema });

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="xs" isStatic>
      <Text type="h2" weight="bold">
        Editar nombre del menú
      </Text>
      <Input
        value={fields.name}
        onChange={(value) => onChangeField({ name: 'name', value })}
        label="Nombre del menú"
        placeholder="Escribe el nombre del menú"
        data-testid="name-input"
        labelColor="selected"
        error={errors?.name?.error}
        messageError={errors?.name?.message}
        border
        autoFocus
      />
      <StyledButtons>
        <Button data-testid="edit-button-menu" size="xs" onClick={() => onSend(fields.name)} disabled={!isValidForm}>
          Guardar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default EditMenuName;
