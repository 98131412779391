import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDessert } from '../../models/IDessert';
import { TFetchStatus } from '../../models/IFetch';
import { RootState } from '../../store';
import { IDessertState } from './models';
import { deleteDessertAsync, fetchAllDessertsAsync, postDessertAsync, putDessertAsync } from './thunks';

export const initialState: IDessertState = {
  desserts: [],
  status: 'idle',
};

export const dessertSlice = createSlice({
  name: 'desserts',
  initialState,
  reducers: {
    updateDessertList: (state, action: PayloadAction<IDessertState['desserts']>) => {
      state.desserts = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* Fetch desserts */
    builder
      .addCase(fetchAllDessertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllDessertsAsync.fulfilled, (state, action) => {
        state.desserts = action.payload.data ? action.payload.data : [];
        state.status = 'success';
      })
      .addCase(fetchAllDessertsAsync.rejected, (state) => {
        state.status = 'failed';
        state.desserts = [];
      });
    /* Post dessert */
    builder
      .addCase(postDessertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postDessertAsync.fulfilled, (state, action) => {
        state.desserts = action.payload?.data ? action.payload.data.list : [];
        state.status = 'success';
      })
      .addCase(postDessertAsync.rejected, (state) => {
        state.status = 'failed';
      });
    /* Put dessert */
    builder
      .addCase(putDessertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putDessertAsync.fulfilled, (state, action) => {
        state.desserts = action.payload?.data ? action.payload.data.list : [];
        state.status = 'success';
      })
      .addCase(putDessertAsync.rejected, (state) => {
        state.status = 'failed';
      });
    /* Delete dessert */
    builder
      .addCase(deleteDessertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDessertAsync.fulfilled, (state, action) => {
        state.desserts = action.payload.data ? action.payload.data : [];
        state.status = 'success';
      })
      .addCase(deleteDessertAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { updateDessertList } = dessertSlice.actions;

export const getDesserts = (state: RootState): IDessert[] => state.desserts.desserts;

export const getDessertsStatus = (state: RootState): TFetchStatus => state.desserts.status;

export default dessertSlice.reducer;
