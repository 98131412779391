/* istanbul ignore file */
import { IFetchOptions } from '../../../models/IFetch';

const URL = process.env.REACT_APP_API_URL;

export default {
  postLogin: (email: string, password: string): IFetchOptions => ({
    url: `${URL}/auth/login/`,
    params: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    },
  }),
  forgotPassword: (email: string): IFetchOptions => ({
    url: `${URL}/auth/forgot-password/`,
    params: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    },
  }),
  resetPassword: (code: string, newPassword: string): IFetchOptions => ({
    url: `${URL}/auth/reset-password/`,
    params: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, newPassword }),
    },
  }),
};
