import styled from 'styled-components';

export const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerInput = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const SwitchContainerStyled = styled.section`
  width: 100%;
  padding: 12px 0;

  > div:nth-child(2) {
    display: flex;
    padding-top: 10px;
    gap: 10px;
  }
`;
