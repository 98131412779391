import styled from 'styled-components';

export const QuotationFiltersContainer = styled.div``;

export const InputContainer = styled.div`
  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
`;
