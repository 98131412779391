import { ChangeEventHandler } from 'react';
import Icon from '../Icon';
import { CustomArrowStyled, CustomSelectStyled, OptionStyled, SelectStyled } from './styles';

export type SelectProps = {
  options: any[];
  onchange?: ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string;
  width?: 'fit' | 'full';
  allOption?: number;
  disabled?: boolean;
  disabledMessage?: string;
  onClick?: () => void;
  name?: string;
};

const defaultProps: SelectProps = { options: [], width: 'fit', disabled: false, disabledMessage: '' };

const Select: React.FC<SelectProps> = ({
  disabled,
  disabledMessage,
  width,
  options,
  onchange,
  defaultValue,
  allOption,
  onClick,
  name,
}) => {
  return (
    <CustomSelectStyled>
      <SelectStyled
        data-testid="select-test"
        value={defaultValue}
        width={width}
        onChange={onchange}
        onClick={onClick}
        name={name}
      >
        {disabled && (
          <OptionStyled hidden value={undefined}>
            {disabledMessage}
          </OptionStyled>
        )}
        {options.map((opt, i) => {
          return (
            <OptionStyled key={i} value={i === allOption ? 'All' : opt}>
              {opt}
            </OptionStyled>
          );
        })}
      </SelectStyled>
      <CustomArrowStyled>
        <Icon name="caret" />
      </CustomArrowStyled>
    </CustomSelectStyled>
  );
};

Select.defaultProps = defaultProps;
export default Select;
