import React from 'react';
import DropDown from '../../components/DropDown';
import { onClearAuthentication } from '../../redux/auth';
import { useAppDispatch } from '../../store/hooks';
import { IconStiled, HeaderDateStyled, HeaderStyled } from './styles';

const Header = () => {
  const format = (date, locale, options) => new Intl.DateTimeFormat(locale, options).format(date);
  const dispatch = useAppDispatch();
  const now = new Date();

  return (
    <HeaderStyled>
      <HeaderDateStyled>{format(now, 'es', { dateStyle: 'long' })}</HeaderDateStyled>
      <IconStiled>
        <DropDown
          options={[{ label: 'Cerrar sesión', onClick: () => dispatch(onClearAuthentication()) }]}
          disabled={true}
          disabledMessage={'Seleccione una opción'}
        />
      </IconStiled>
    </HeaderStyled>
  );
};

export default Header;
