import React, { Dispatch, SetStateAction, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import CheckBox from '../../../components/MenuTable/components/CheckBox';
import Modal from '../../../components/Modal';
import Image from '../../../components/ProductImageDetail';
import Text from '../../../components/Text';
import TextArea from '../../../components/TextArea';
import useForm from '../../../hooks/useForm';
import { getAuthenticatedToken } from '../../../redux/auth';
import { postDessertAsync } from '../../../redux/dessertSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadImage } from '../../../utils/file';
import { SwitchContainerStyled } from '../EditDessert/styles';
import { fieldSchema } from './schemas';
import { ContainerInput, StyledButtons } from './styles';

interface ICreateDessertProps {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  onClickDelete: () => void;
}

interface ICreateDessert {
  name: string;
  description: string;
  file: File | undefined;
  isActive: string;
}

const CreateDessert = ({ isOpent, setIsOpent }: ICreateDessertProps) => {
  const { fields, errors, onChangeField, onSubmit, resetForm, isValidForm } = useForm({ fieldSchema });
  const [image, setImage] = useState<{ file: File; src: string | undefined }>();
  const isValidInputs = isValidForm && !!image;

  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);

  const onSend = () => {
    if (!onSubmit()) return;
    const dessert: ICreateDessert = {
      ...fields,
      file: image?.file,
    };
    dispatch(postDessertAsync({ dessert, token }));
    reset();
  };

  const reset = () => {
    setImage(undefined);
    resetForm();
    setIsOpent(false);
  };

  const onChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const src = await loadImage(e.target.files[0]);
      setImage({ src, file: e.target.files[0] });
    }
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="sm" data-testid="create-dessert-modal">
      <Text type="body" alignment="start" color="principal">
        Nuevo postre
      </Text>
      <Image onChange={onChangeImage} src={image?.src} data-testid="image-input" />
      <ContainerInput>
        <Input
          value={fields.name}
          onChange={(value) => onChangeField({ name: 'name', value })}
          type="Name"
          label="Nombre"
          placeholder="Escribe el nombre del postre"
          border
          data-testid="nameInput"
          error={errors?.name?.error}
          messageError={errors?.name?.message}
        />
      </ContainerInput>
      <ContainerInput>
        <TextArea
          value={fields.description}
          onChange={(e) => onChangeField({ name: 'description', value: e.target.value })}
          label="Descripción"
          placeholder="Escribe una descripción"
          data-testid="descriptionInput"
        />
      </ContainerInput>
      <SwitchContainerStyled>
        <Text type="body" alignment="start">
          Visible en la web
        </Text>
        <div>
          <CheckBox
            checked={fields.isActive}
            onChange={(e) => onChangeField({ name: 'isActive', value: e.target.checked })}
          />
          <Text type="body" alignment="start">
            Si
          </Text>
        </div>
      </SwitchContainerStyled>
      <StyledButtons>
        <Button size="xs" variant="black" onClick={reset} data-testid="reset-form-btn">
          Cancelar
        </Button>
        <Button data-testid="button-test-add" size="xs" onClick={onSend} disabled={!isValidInputs}>
          Agregar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default CreateDessert;
