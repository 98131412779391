import React, { useEffect, useState } from 'react';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Logo from '../../../components/Logo';
import InputComponent from '../../../components/Input';
import Error from '../../../components/Error';
import Modal from '../../../components/Modal';
import { ButttonContainer, ModalContainerWrapper } from './styles';
import Icon from '../../../components/Icon';
import { fieldSchema } from './schemas';
import useForm from '../../../hooks/useForm';
import { useAppDispatch } from '../../../store/hooks';
import { forgotPasswordAsync } from '../../../redux/auth/thunks';

const RecoveryPassword = ({ setIsOpent, email, onChangeEmail }) => {
  const dispatch = useAppDispatch();
  const { fields, errors, onChangeField, isValidForm } = useForm({ fieldSchema, validateOnInit: true });
  const [error, setError] = useState('');

  const onSend = async () => {
    try {
      await dispatch(forgotPasswordAsync({ email: fields.email })).unwrap();
      onChangeEmail(fields.email);
      setIsOpent(false);
      setError('');
    } catch (error: any) {
      setError(error);
    }
  };

  useEffect(() => {
    onChangeField({ name: 'email', value: email });
  }, []);

  return (
    <Modal size="xs" isOpent={true} setIsOpent={setIsOpent}>
      <Logo />
      <Text type="h1">Recuperar contraseña</Text>
      <Text type="body">
        Introduce el mail con el que accedes a tu cuenta. En minutos recibirás un correo para reestablecer tu
        contraseña.
      </Text>
      <ModalContainerWrapper>
        <InputComponent
          value={fields.email}
          onChange={(value) => {
            onChangeField({ name: 'email', value });
          }}
          type="email"
          error={errors?.email?.error}
          messageError={errors?.email?.message}
          iconRight={<Icon name="mail" />}
          placeholder="Escribe tu email"
          data-testid="emailRecoveryInput"
        />
        <ButttonContainer>
          <Button data-testid="emailRecoveryButton" onClick={onSend} fullWidth disabled={!isValidForm}>
            Enviar
          </Button>
          {error && <Error message={error} data-testid="errorMessage" />}
        </ButttonContainer>
      </ModalContainerWrapper>
    </Modal>
  );
};

export default RecoveryPassword;
