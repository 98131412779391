import styled from 'styled-components';
import { PaginationItemProps } from '..';
import { theme } from '../../../theme';

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;

export const PaginationItem = styled.button<PaginationItemProps>`
  color: ${theme.text.color.texts};
  background: ${({ active }) => (active ? theme.text.color.principal : theme.text.color.card)};
  border: 1px solid ${theme.text.color.principal};
  border-radius: 5px;
  padding: 8px;
  min-width: 30px;
  max-height: 35px;
  cursor: pointer;
  &:hover {
    background: ${theme.text.color.principal};
  }
`;

export const PaginationAction = styled.button`
  color: ${theme.text.color.texts};
  background: ${theme.text.color.card};
  opacity: ${({ disabled }) => disabled && 0.3};
  border: 1px solid ${theme.text.color.principal};
  border-radius: 5px;
  width: 35px;
  height: 35px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
