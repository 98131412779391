import styled from 'styled-components';
import { SectionProps } from '..';
import { theme } from '../../../../theme';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const MenuTitleStyled = styled.div`
  display: flex;
  padding: 0 7vw 1vh 0;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  ${mediaQuery.mobileToDesktop(`
    padding: 0 0vw 1vh 0;
  `)}
`;

export const ServiceExtraContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${mediaQuery.laptopToUp(`
    flex-direction: row;
    flex-wrap: wrap;
  `)}
  ${mediaQuery.tabletOnly(`
    width: 50%;
  `)}
  ${mediaQuery.laptopToDesktop(`
    width: 60%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 75%;
  `)}
`;

export const ServiceExtraOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  ${mediaQuery.laptopToDesktop(`
    width: 100%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 30%;
  `)}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const SectionContainer = styled.div`
  background-color: ${theme.text.color.card};
  min-width: 233px;
  ${mediaQuery.tabletOnly(`
    width: 50%;
  `)}
  ${mediaQuery.laptopToDesktop(`
    width: 40%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 25%;
  `)}
`;

export const SectionItemsContainer = styled.div`
  background-color: ${theme.text.color.card};
  ${mediaQuery.tabletOnly(`
    width: 50%;
  `)}
  ${mediaQuery.laptopToDesktop(`
    width: 60%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 75%;
  `)}
`;

export const SectionNotCreatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: ${theme.text.color.card};
  ${mediaQuery.tabletOnly(`
    width: 50%;
  `)}
  ${mediaQuery.laptopToDesktop(`
    width: 60%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 75%;
  `)}
`;

export const SectionHeader = styled.div`
  display: flex;
  padding: 15px;
  border-bottom: 1px solid ${theme.text.color.principal};
`;

export const SectionContent = styled.div`
  height: 350px;
  background-color: ${theme.text.color.card};
  padding: 15px 0;
`;

export const SectionItemsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
`;

export const SectionItem = styled.div`
  padding: 5px 15px;
  width: 100%;
  ${mediaQuery.desktopToUp(`
    width: 45%;
  `)}
`;

export const SectionItemsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  min-height: 37px;
  border-bottom: 1px solid ${theme.text.color.principal};
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const SectionItemsHeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${mediaQuery.laptopToUp(`
    justify-content: flex-end;
    gap: 15px;
  `)}
`;

export const Section = styled.div<SectionProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? theme.text.color.principal : 'inherit')};
`;

export const ExtraServicesContainer = styled.div`
  margin: 15px 0;
  display: flex;
  background-color: ${theme.text.color.card};
  flex-direction: column;
  ${mediaQuery.laptopToUp(`
    flex-direction: row;
  `)}
`;

export const ExtraServicesTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  ${mediaQuery.tabletOnly(`
    width: 50%;
  `)}
  ${mediaQuery.laptopToDesktop(`
    width: 40%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 25%;
  `)}
`;

export const ValueForPersonContainer = styled.div`
  display: flex;
  padding: 25px 25px;
  flex-direction: column;
  ${mediaQuery.laptopToUp(`
    flex-direction: column;
    width: 60%;
  `)}
  ${mediaQuery.desktopToUp(`
    flex-direction: row;
    width: 75%;
    gap: 20px;
  `)}
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  ${mediaQuery.desktopToUp(`
    width: 33.3%;
  `)}
`;

export const ButtonSaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  gap: 5px;
  padding-bottom: 10px;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 500px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const SwitchContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin: 10px 0;
`;

export const TextareaStyled = styled.div`
  flex: 1;
  padding-right: 16px;
  margin-top: 10px;
  > textarea {
    max-width: inherit;
    height: 66px;
  }
`;
