import styled from 'styled-components';

export const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerInput = styled.div`
  width: 100%;
`;

export const ContainerVisibility = styled.section`
  width: 100%;
  padding: 10px 0;
  gap: 10px;
  display: flex;
`;
