import { ICreateExtraService } from '../../../../api/extraServices/models';
import { IFormSchema } from '../../../../models/ISchema';

export const fieldSchema: IFormSchema<ICreateExtraService> = {
  name: {
    initialValue: '',
    rules: {
      required: true,
      minLength: 3,
      maxLength: 48,
    },
  },
  price: {
    initialValue: 0,
    rules: {
      required: true,
      positive: true,
    },
  },
  pricingType: {
    initialValue: 'Precio único',
    rules: {
      required: true,
    },
  },
};
