import styled from 'styled-components';
import { TextProps } from '..';
import { theme } from '../../../theme';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';

export const StyledText = styled.div<TextProps>`
  font-family: Mulish;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ alignment = 'center' }) => theme.text.alignment[alignment].justifyContent};
  box-sizing: border-box;
  outline: none;
  text-align: ${({ textAlignment = 'center' }) => theme.text.textAlignment[textAlignment].textAlign};
  color: ${({ color = 'texts' }) => theme.text.color[color]};
  font-weight: ${({ type = 'h1', weight = 'regular' }) => theme.text.type[type].weight[weight].fontWeight};
  font-size: ${({ type = 'h1' }) => theme.text.type[type].screenSize['desktop'].fontSize};
  line-height: ${({ type = 'h1' }) => theme.text.type[type].lineHeight};

  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${({ type = 'h1' }) => theme.text.type[type].screenSize['mobile'].fontSize};
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    font-size: ${({ type = 'h1' }) => theme.text.type[type].screenSize['mobile'].fontSize};
  }
`;
