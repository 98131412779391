import styled from 'styled-components';
import { handleColorType } from '../../../theme';
import { mediaQuery } from '../../../utils/mediaQuery';
import { TableDataCellStyledProps } from '../body';

export const TableStyled = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  color: white;
  margin: 0;
  padding: 0;
  background-color: #222222;
`;

export const TableRowStyled = styled.tr`
  border-bottom: 1px solid #4b4b4b;
`;

export const TableHeaderCellStyled = styled.th`
  height: 3.45vh;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: start;
  padding: 8px 0.5vw 8px 1.6vw;
  :last-child {
    text-align: center;
    padding-left: 0;
  }
`;

export const TableDataCellStyled = styled.td<TableDataCellStyledProps>`
  color: ${({ value }) => handleColorType(value)};
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: start;
  padding: 8px 0.5vw 8px 1.6vw;
  ${mediaQuery.mobileToDesktop(`
    padding: 3px 0.5vw 3px 2vw;
    height: auto;
  `)}
`;

export const ButtonWrapperStyled = styled.div`
  max-width: 121px;
  width: 100%;
  height: 100%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQuery.desktopToUp(`
    width: 75%;
  `)}
  ${mediaQuery.mobileToTablet(`
    width: 75%;
  `)}
`;

export const TableTitleStyled = styled.h2`
  color: white;
  font-size: 35px;
  font-weight: normal;
  line-height: 35px;
`;

export const MenuTitleWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
