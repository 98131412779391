import { useState } from 'react';

const useForm = <T extends object>({
  fieldSchema,
  validateOnInit = false,
}: {
  fieldSchema: T;
  validateOnInit?: boolean;
}) => {
  const initialValues = schemaProcessor(fieldSchema).getInitialValues();
  const validationSchema = schemaProcessor(fieldSchema).getValidations();
  const initialErr = validateOnInit ? ({ [Object.keys(initialValues)[0]]: { error: false, message: '' } } as any) : {};
  const [fields, setFields] = useState<{ [Property in keyof Required<T>]: any }>(initialValues);
  const [errors, setErrors] = useState<{ [Property in keyof T]: Error }>(initialErr);

  const onChangeField = ({ name, value }: { name: keyof T; value: any }) => {
    setFields({ ...fields, [name]: value });
    if (!validationSchema[name]) return;

    const temp = validateField(name, value);
    const errs = { ...errors };

    if (temp) {
      setErrors({ ...errs, [name]: temp });
    } else {
      delete errs[name];
      setErrors(errs);
    }
  };

  const updateValues = (values: { [Property in keyof Partial<T>]: any }) => setFields({ ...fields, ...values });

  const onSubmit = () => {
    const flds = Object.entries(fields);
    const errs: any = {};

    flds.forEach(([key, value]) => {
      if (!validationSchema[key]) return;
      const temp = validateField(key, value);
      if (temp) errs[key] = temp;
    });

    setErrors(errs);

    return Object.keys(errs || {}).length === 0;
  };

  const validateField = (fieldName: any, fieldValue: any) => {
    const validations = Object.entries(validationSchema[fieldName]);

    const all = validations.map(([keyValidation, val]: any) => ({
      error:
        keyValidation === 'conditions'
          ? validationRules[keyValidation](fieldValue, val.regex)
          : validationRules[keyValidation].length > 1
          ? validationRules[keyValidation](fieldValue, val)
          : validationRules[keyValidation](fieldValue),
      message:
        keyValidation === 'conditions'
          ? errorMessages[keyValidation](val.errorMessage)
          : errorMessages[keyValidation].length > 0
          ? errorMessages[keyValidation](val)
          : errorMessages[keyValidation](),
    }));
    const [temp] = all.filter((validation) => validation.error);
    return temp;
  };

  const resetForm = () => setFields(initialValues);

  const isValidForm = Object.keys(errors || {}).length === 0;

  return { fields, errors, onChangeField, onSubmit, isValidForm, resetForm, updateValues };
};

const schemaProcessor = <T extends object>(fieldSchema: T) => {
  const schema: any = {};
  const reducer = (type: IProcessor) =>
    Object.keys(fieldSchema).reduce((prev, curr) => ({ ...prev, [curr]: fieldSchema[curr][type] }), {});

  schema.getValidations = () => reducer('rules');
  schema.getInitialValues = () => reducer('initialValue');

  return schema;
};

const validationRules: ValidationOptions = {
  required: (value: string) => !value,
  minLength: (value: string, min: number) => value.length < min,
  maxLength: (value: string, max: number) => value.length > max,
  max: (value: number, max: number) => value >= max,
  positive: (value: number) => value < 0,
  conditions: (value: string, regex: RegExp) => !regex.test(value),
};

export const errorMessages: ValidationOptions = {
  required: () => 'Campo requerido',
  minLength: (min: number) => `Campo debe tener al menos ${min} caracteres`,
  maxLength: (max: number) => `Campo debe tener máximo ${max} caracteres`,
  max: (max: number) => `Campo debe ser menor a ${max.toLocaleString('es-CL')}`,
  positive: () => `Campo debe ser número positivo`,
  conditions: (msg?: string) => (msg ? msg : 'Campo inválido'),
};

type IProcessor = 'rules' | 'initialValue';

interface Error {
  error: boolean;
  message: string;
}

interface ValidationSchema {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  max?: number;
  positive?: boolean;
  conditions?: string;
}

type ValidationOptions = {
  [Property in keyof Required<ValidationSchema>]: (...params: any) => void;
};

export default useForm;
