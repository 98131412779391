import styled from 'styled-components';
import { theme } from '../../../theme';

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SelectStyled = styled.select`
  appearance: none;
  height: 47px;
  width: 100%;
  background: ${theme.text.color.card};
  color: white;
  border-radius: 5px;
  border: 1px solid ${({ value }) => (value ? theme.text.color.principal : 'white')};
  padding-left: 10px;
  &:focus-visible {
    outline: none;
  }
`;

export const OptionItem = styled.option`
  height: 40px;
  white-space: normal;
`;

export const SelectLabel = styled.label`
  font-size: 18px;
  color: ${theme.text.color.principal};
  margin-left: 5px;
  margin-bottom: 4px;
  text-align: start;
  width: 100%;
`;

export const CustomArrowStyled = styled.span`
  position: absolute;
  display: block;
  background: transparent;
  height: 100%;
  width: 2rem;
  top: 0;
  right: 2%;
  transform: rotate(90deg);
  pointer-events: none;
  cursor: pointer;
`;

export const SelectError = styled.p`
  color: ${theme.text.color.alternative};
`;
