import React from 'react';
import { ButtonWrapper } from './style';
import { WithChildren } from '../../models/General';
type IButtonSize = 'xxs' | 'xs' | 'sm' | 'md';
type TVariant = 'outline' | 'default' | 'black' | 'transparent' | 'text';

export type ButtonProps = WithChildren<{
  disabled?: boolean;
  onClick?: () => void;
  size?: IButtonSize;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  variant?: TVariant;
  pill?: boolean;
  type?: 'button' | 'submit';
}>;

export const defaultProps: ButtonProps = {
  disabled: false,
  size: 'sm',
  fullWidth: false,
  style: {},
  variant: 'default',
  type: 'button',
};

const Button = ({ children, ...otherProps }: ButtonProps) => {
  return (
    <ButtonWrapper data-testid="button" {...otherProps}>
      {children}
    </ButtonWrapper>
  );
};

Button.defaultProps = defaultProps;

export default Button;
