import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { QUOTATION } from '../../utils/constants/messages';
import { IQuotationState } from './models';
import {
  createQuotationAsync,
  deleteQuotationAsync,
  fetchAllQuotationsAsync,
  fetchQuotationsAsync,
  notifyConfirmationAsync,
  updateQuotationAsync,
} from './thunks';

export const initialState: IQuotationState = {
  status: 'idle',
  quotationList: [],
  message: '',
};

export const quotationListSlice = createSlice({
  name: 'quotationList',
  initialState,
  reducers: {
    updateQuotationList: (state, action: PayloadAction<IQuotationState['quotationList']>) => {
      state.quotationList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchQuotationsAsync
      .addCase(fetchQuotationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQuotationsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.quotationList = action.payload.quotations;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchQuotationsAsync.rejected, (state) => {
        state.status = 'failed';
        state.quotationList = [];
      })
      //fetchAllQuotationsAsync
      .addCase(fetchAllQuotationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllQuotationsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.quotationList = action.payload.quotations;
        state.eventsPendingUpdate = action.payload.eventsPendingUpdate;
      })
      .addCase(fetchAllQuotationsAsync.rejected, (state) => {
        state.status = 'failed';
        state.quotationList = [];
      })
      //createQuotationAsync
      .addCase(createQuotationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createQuotationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.quotationList = action.payload.list;
      })
      .addCase(createQuotationAsync.rejected, (state) => {
        state.status = 'failed';
        state.quotationList = [];
      })
      //updateQuotationAsync
      .addCase(updateQuotationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateQuotationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.quotationList = action.payload.list;
      })
      .addCase(updateQuotationAsync.rejected, (state) => {
        state.status = 'failed';
        state.quotationList = [];
      })
      //deleteQuotationAsync
      .addCase(deleteQuotationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteQuotationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.quotationList = action.payload;
      })
      .addCase(deleteQuotationAsync.rejected, (state) => {
        state.status = 'failed';
        state.quotationList = [];
      })
      //notifyConfirmationAsync
      .addCase(notifyConfirmationAsync.fulfilled, (state, action) => {
        state.message = action.payload ?? QUOTATION.successEmail;
      })
      .addCase(notifyConfirmationAsync.rejected, (state) => {
        state.message = QUOTATION.errorEmail;
      });
  },
});

export const { updateQuotationList } = quotationListSlice.actions;

export const getQuotationList = (state: RootState) => state.quotations.quotationList;
export const getQuotationListStatus = (state: RootState) => state.quotations.status;
export const getQuotationPagination = (state: RootState) => state.quotations.pagination;
export const getQuotationMessage = (state: RootState) => state.quotations.message;
export const getEventsPendingUpdate = (state: RootState) => state.quotations.eventsPendingUpdate;

export default quotationListSlice.reducer;
