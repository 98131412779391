import { ButtonProps } from '../../../components/Button';

export interface INotificationButton extends ButtonProps {
  value: string;
  action: 'handleClick' | 'onClose';
}

type Alert = {
  icon: 'success' | 'warning';
  title?: string;
  message?: string;
  button?: INotificationButton[];
};

export type AlertType =
  | 'idle'
  | 'success'
  | 'warnPrevQuotation'
  | 'redirect'
  | 'warnDisabledDate'
  | 'confirmQuotation'
  | 'confirmSave'
  | 'warnSave';

export const alerts: Partial<Record<AlertType, Alert>> = {
  redirect: {
    icon: 'success',
    title: 'Guardado con éxito',
    message: 'La cotización se ha agregado al calendario',
    button: [
      {
        value: 'Cerrar',
        action: 'onClose',
        variant: 'outline',
      },
      {
        value: 'Ver agenda',
        action: 'handleClick',
      },
    ],
  },
  success: {
    icon: 'success',
    title: 'Guardado con éxito',
  },
  warnPrevQuotation: {
    icon: 'warning',
    message: 'Tienes más de dos eventos ese día. ¿Estás seguro de agendarlo en el calendario?',
    button: [
      {
        value: 'Modificar',
        action: 'onClose',
        variant: 'outline',
      },
      {
        value: 'Agendar',
        action: 'handleClick',
      },
    ],
  },
  warnDisabledDate: {
    icon: 'warning',
    message: 'Este día está deshabilitado para añadir eventos',
    button: [
      {
        value: 'Cerrar',
        action: 'onClose',
        variant: 'outline',
      },
    ],
  },
  confirmQuotation: {
    icon: 'warning',
    message: '¿Esta seguro de confirmar la cotización?',
    button: [
      {
        value: 'No',
        action: 'onClose',
        variant: 'outline',
      },
      {
        value: 'Si',
        action: 'handleClick',
      },
    ],
  },
  confirmSave: {
    icon: 'warning',
    message: '¿Esta seguro de guardar estos cambios?',
    button: [
      {
        value: 'No',
        action: 'onClose',
        variant: 'outline',
      },
      {
        value: 'Si',
        action: 'handleClick',
      },
    ],
  },
  warnSave: {
    icon: 'warning',
    message: 'Tiene cambios sin guardar. ¿Esta seguro que desea salir?',
    button: [
      {
        value: 'Cerrar',
        action: 'onClose',
        variant: 'outline',
      },
      {
        value: 'Salir',
        action: 'handleClick',
      },
    ],
  },
};
