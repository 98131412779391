import styled from 'styled-components';
import { ListBodyValueProps } from '..';
import { theme, handleColorType } from '../../../../theme';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const MenuTitleStyled = styled.div`
  display: flex;
  padding: 0 0.5vw 1vw 0;
  align-items: center;
  flex-wrap: wrap;
  ${mediaQuery.desktopToUp(`
    flex-wrap: nowrap;
  `)}
`;

export const TitleContainer = styled.div`
  width: 65%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.tabletToUp(`
    gap: 10px;
    width: 35%;
    justify-content: flex-end;
  `)}
  ${mediaQuery.desktopToUp(`
    order: 3;
  `)}
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mediaQuery.tabletOnly(`
    flex-direction: row;
    gap: 35px;
  `)}
  ${mediaQuery.laptopToUp(`
    flex-direction: row;
    gap: 35px;
  `)}
  ${mediaQuery.desktopToUp(`
    order: 2;
    justify-content: flex-end;
  `)}
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 25px;
  padding: 5px 0;
  ${mediaQuery.tabletOnly(`
    gap: 10px;
  `)}
  ${mediaQuery.laptopToUp(`
    gap: 10px;
  `)}
`;

export const ListContainer = styled.div`
  width: 100%;
  background: ${theme.text.color.card};
  color: ${theme.text.color.texts};
`;

export const ListHeader = styled.div`
  display: none;
  ${mediaQuery.laptopToUp(`
    display: flex;
    padding: 15px;
  `)}
`;

export const ListHeaderColumn = styled.div`
  width: 14%;
`;

export const ListBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #4b4b4b;
`;

export const ListBodyColumn = styled.div`
  width: 50%;
  ${mediaQuery.laptopToUp(`
    display: none;
  `)}
`;

export const ListBodyButtonContainer = styled.div`
  padding: 10px 0;
`;

export const ListBodyValue = styled.div<ListBodyValueProps>`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-weight: ${({ hasName }) => (hasName ? 'bold' : '')};
  color: ${({ status }) => handleColorType(status)};
  ${mediaQuery.laptopToUp(`
    width: 14%;
  `)}
`;
