import { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Table, { ColumnsProps } from '../../../components/MenuTable';
import { ButtonWrapperStyled } from '../../../components/MenuTable/styles';
import ProductItem from '../../../components/ProductItem';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { IDessert } from '../../../models/IDessert';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getDesserts, getDessertsStatus } from '../../../redux/dessertSlice';
import { fetchAllDessertsAsync } from '../../../redux/dessertSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CreateDessert from '../CreateDessert';
import EditDessert from '../EditDessert';
import RemoveDessert from '../RemoveDessert';
import { ButtonContainer, ContainerScroll, MenuTitleStyled } from './styles';

const DessertList = (): JSX.Element => {
  const [selectedDessert, setSelectedDessert] = useState<IDessert>();
  const [isOpent, setIsOpent] = useState(false);
  const [isRemoveModalOpent, setIsRemoveModalOpent] = useState(false);
  const [isCreateDessertOpent, setIsCreateDessertOpent] = useState(false);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const dessertList: IDessert[] = useAppSelector(getDesserts);
  const status = useAppSelector(getDessertsStatus);
  const token = useAppSelector(getAuthenticatedToken);

  const onClickUpdate = (value) => {
    setSelectedDessert(value);
    setIsOpent(true);
  };

  const onClickDelete = () => {
    setIsOpent(false);
    setIsRemoveModalOpent(true);
    setIsCreateDessertOpent(false);
  };

  const columnsConfig: ColumnsProps[] = [
    {
      name: 'Postre',
      dataIndex: 'name',
      component: (row) => <ProductItem product={row} />,
      width: '38%',
      minWidth: '50px',
    },
    {
      name: 'Descripción',
      dataIndex: 'description',
      width: '50%',
      display: width && width > 768 ? 'flex' : 'none',
    },
    {
      name: '',
      dataIndex: '',
      width: '15%',
      display: '',
      component: (row) => (
        <ButtonWrapperStyled>
          <Button data-testid="btn-edit-dessert" variant="outline" size="xs" onClick={() => onClickUpdate(row)}>
            Editar
          </Button>
        </ButtonWrapperStyled>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllDessertsAsync(token));
  }, []);

  return (
    <>
      <MenuTitleStyled>
        <Text fullWidth={false} type="h1">
          Postres
        </Text>
        <ButtonContainer>
          <Button data-testid="button-test" size="xs" variant="default" onClick={() => setIsCreateDessertOpent(true)}>
            Crear Nuevo
          </Button>
        </ButtonContainer>
      </MenuTitleStyled>
      <ContainerScroll>
        <ScrollBar>
          <Table
            loading={status == 'loading' ? true : false}
            data={dessertList}
            columns={columnsConfig}
            disabledColumns={width && width > 768 ? [] : [1]}
          />
        </ScrollBar>
      </ContainerScroll>
      {selectedDessert && (
        <>
          {isOpent && (
            <EditDessert
              isOpent={isOpent}
              setIsOpent={setIsOpent}
              selectedDessert={selectedDessert}
              onClickDelete={onClickDelete}
            />
          )}
          {isRemoveModalOpent && (
            <RemoveDessert
              selectedDessert={selectedDessert}
              setIsOpent={setIsRemoveModalOpent}
              isOpent={isRemoveModalOpent}
            />
          )}
        </>
      )}
      {isCreateDessertOpent && (
        <CreateDessert
          isOpent={isCreateDessertOpent}
          setIsOpent={setIsCreateDessertOpent}
          onClickDelete={onClickDelete}
        />
      )}
    </>
  );
};

export default DessertList;
