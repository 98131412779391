import { memo } from 'react';
import { IQuotation } from '../../models/IQuotation';
import { TableDataCellStyled, TableRowStyled } from './styles';
import { ColumnsProps } from './index';

export type Bodyprops = {
  list: IQuotation[] | any[];
  columns: ColumnsProps[];
  disabledColumns?: number[];
};

export type TableDataCellStyledProps = {
  value?: 'Pendiente' | 'Cancelado' | 'Finalizado';
};

const TableBody = ({ list = [], columns, disabledColumns = [] }: Bodyprops) => {
  const EmptyTable = () => (
    <tr>
      <td colSpan={columns.length} style={{ textAlign: 'center' }}>
        No hay información
      </td>
    </tr>
  );

  return (
    <>
      {list.length > 0 ? (
        list.map((row: any, index) => (
          <TableRowStyled key={index}>
            {columns.map(
              (column, i) =>
                !disabledColumns.includes(i) && (
                  <TableDataCellStyled title={`${row[column.dataIndex]}`} key={i} value={row[column.dataIndex]}>
                    {column.component ? column.component(row) : row[column.data ? column.data : column.dataIndex]}
                  </TableDataCellStyled>
                )
            )}
          </TableRowStyled>
        ))
      ) : (
        <EmptyTable />
      )}
    </>
  );
};

export default memo(TableBody);
