import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreateQuotation } from '../../../api/quotations/models';
import Button from '../../../components/Button';
import CheckBox from '../../../components/CheckBox';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import { Label } from '../../../components/Input/style';
import ScrollBar from '../../../components/Scrollbar';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { useQuoteNotification } from '../../../hooks/useQuoteNotification';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getDisabledDates } from '../../../redux/disabledDateSlice';
import { fetchDisabledDatesAsync } from '../../../redux/disabledDateSlice/thunks';
import { getEventTypes } from '../../../redux/eventTypeSlice';
import { fetchEventTypesAsync } from '../../../redux/eventTypeSlice/thunks';
import { getExtraServices } from '../../../redux/extraServiceSlice';
import { fetchExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { getMenus } from '../../../redux/menuSlice';
import { fetchMenusAsync } from '../../../redux/menuSlice/thunks';
import { getQuotationList } from '../../../redux/quotationSlice';
import { createQuotationAsync, fetchAllQuotationsAsync } from '../../../redux/quotationSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { calculatePrices } from '../../../utils/calculatePrices';
import { toGMTDate, toIsoDate } from '../../../utils/formatDateTime';
import { parseNumber } from '../../../utils/parseNumber';
import { ButtonContainerStyled, DivWrapperStyled, GridCellStyled, GridTemplateStyled } from '../EditQuotation/styles';
import AlertNotification from '../Notification';
import { AlertType } from '../Notification/constants';
import Summary from '../Summary';
import { fieldSchema } from './schema';

const CreateQuotation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const quotationsList = useAppSelector(getQuotationList);
  const menuList = useAppSelector(getMenus);
  const eventTypesList = useAppSelector(getEventTypes);
  const extraServicesList = useAppSelector(getExtraServices);
  const disabledDates = useAppSelector(getDisabledDates);
  const { notification, closeNotification, updateNotification } = useQuoteNotification();
  const { fields, errors, onChangeField, isValidForm, resetForm, updateValues } = useForm({ fieldSchema });
  const [menu, setMenu] = useState<string>('');
  const [eventType, setEventType] = useState<string>('');
  const [extraServices, setExtraServices] = useState<string[]>([]);
  const today = new Date().toLocaleDateString('en-CA');
  const prevQuotation = quotationsList.find(
    ({ dateTime, status }) => toGMTDate(dateTime).date === fields.date && status !== 'cancelled'
  );
  const menus = menuList.filter((menu) => menu.isActive).map((menu) => menu.name);
  const eventTypes = eventTypesList.map((event) => event.name);
  const menuSelected = menuList.find((item) => item.name === menu);
  const { childrenQuantity, peopleQuantity, negotiatedPrice, ...fieldsToValidate } = fields;
  const isEnabledSaveBtn =
    menu && eventType && Object.values({ peopleQuantity, ...fieldsToValidate }).every((field) => field) && isValidForm;
  const calculatedPrices =
    menuSelected &&
    calculatePrices({
      childrenQuantity,
      extraServices,
      extraServicesList,
      menuSelected,
      peopleQuantity,
    });

  useEffect(() => {
    dispatch(fetchAllQuotationsAsync({ token }));
    dispatch(fetchMenusAsync(token));
    dispatch(fetchEventTypesAsync(token));
    dispatch(fetchExtraServicesAsync(token));
    dispatch(fetchDisabledDatesAsync(token));
  }, []);

  useEffect(() => {
    updateValues({
      estimatedPrice: calculatedPrices?.total,
      childrenQuantity: menuSelected?.hasChildrenMenu ? childrenQuantity : 0,
    });
  }, [peopleQuantity, childrenQuantity, menu, extraServices]);

  const updateExtraServices = (id: string) => {
    if (extraServices.includes(id)) return setExtraServices(extraServices.filter((item) => item !== id));
    return setExtraServices([...extraServices, id]);
  };
  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.name === 'menu') return setMenu(e.target.value);
    return setEventType(e.target.value);
  };
  const onChangeDate = (value: string) => {
    const date = disabledDates.find((day) => day.date === value);
    onChangeField({ name: 'date', value });
    if (date) return updateNotification('warnDisabledDate');
  };
  const saveQuotation = () => {
    handleCreateQuotation();
    return updateNotification('success');
  };
  const resetQuotationForm = () => {
    setMenu('');
    setEventType('');
    setExtraServices([]);
    resetForm();
  };
  const getQuotation = (): ICreateQuotation => {
    const { date, time, fullName, email, phone, ...restOfFields } = fields;
    const [{ _id: menuId }] = menuList.filter((item) => item.name === menu);
    const [{ _id: eventTypeId }] = eventTypesList.filter((event) => event.name === eventType);
    return {
      dateTime: toIsoDate(date, time),
      contact: { fullName, email, phone },
      menuId,
      eventTypeId,
      extraServices,
      ...restOfFields,
    };
  };
  const handleCreateQuotation = () => {
    dispatch(createQuotationAsync({ token, quotation: getQuotation() }));
    return resetQuotationForm();
  };

  const onSend = () => {
    if (prevQuotation) return updateNotification('warnPrevQuotation');
    handleCreateQuotation();
    return updateNotification('redirect');
  };

  const actionsToNotify: Partial<Record<AlertType, () => void>> = {
    redirect: () => navigate('/agenda'),
    warnPrevQuotation: saveQuotation,
  };

  return (
    <>
      <Icon data-testid="back-button-test" onClick={() => navigate(-1)} name="arrow" />
      <ScrollBar>
        <Text alignment="start" type="h1">
          Crear Cotización
        </Text>
        <GridTemplateStyled data-testid="display-quotation-test" value={3}>
          <GridCellStyled>
            <Label>Menú</Label>
            <Select
              defaultValue={menu}
              data-testid="menu-test"
              width="full"
              onchange={onChangeSelect}
              options={menus}
              disabled={true}
              disabledMessage={'Seleccione una opción'}
              name="menu"
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              type="date"
              data-testid="date-test"
              iconRight={<Icon name="calendar" />}
              label="Fecha"
              value={fields.date}
              onChange={onChangeDate}
              error={errors?.date?.error}
              messageError={errors?.date?.message}
              min={today}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="people-test"
              label="Cantidad de personas"
              value={parseNumber.withMillerDot(fields.peopleQuantity.toString())}
              onChange={(value) => onChangeField({ name: 'peopleQuantity', value: parseNumber.clean(value) })}
              error={errors?.peopleQuantity?.error}
              messageError={errors?.peopleQuantity?.message}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="time-test"
              label="Hora"
              type="time"
              placeholder="Ingrese una Hora (hh:mm)"
              iconRight={<Icon name="watch" />}
              value={fields.time}
              onChange={(value) => onChangeField({ name: 'time', value })}
              error={errors?.time?.error}
              messageError={errors?.time?.message}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Label>Tipo de evento</Label>
            <Select
              defaultValue={eventType}
              width="full"
              onchange={onChangeSelect}
              options={eventTypes}
              disabled={true}
              disabledMessage={'Seleccione una opción'}
              name="eventType"
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="kids-test"
              label="Cantidad de niños"
              value={parseNumber.withMillerDot(fields.childrenQuantity.toString())}
              onChange={(value) => onChangeField({ name: 'childrenQuantity', value: parseNumber.clean(value) })}
              error={errors?.childrenQuantity?.error}
              messageError={errors?.childrenQuantity?.message}
              isDisabled={!menu.length || !menuSelected?.hasChildrenMenu}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="address-test"
              label="Dirección"
              iconRight={<Icon name="location" />}
              value={fields.address}
              onChange={(value) => onChangeField({ name: 'address', value })}
              error={errors?.address?.error}
              messageError={errors?.address?.message}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              readOnly={true}
              data-testid="price-test"
              label="Precio Sugerido"
              placeholder="$"
              value={parseNumber.withMillerDot(fields.estimatedPrice)}
              onChange={(value) => onChangeField({ name: 'estimatedPrice', value: parseNumber.clean(value) })}
              error={errors?.estimatedPrice?.error}
              messageError={errors?.estimatedPrice?.message}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="nprice-test"
              label="Precio Negociado"
              placeholder="$"
              value={parseNumber.withMillerDot(negotiatedPrice)}
              onChange={(value) => onChangeField({ name: 'negotiatedPrice', value: parseNumber.clean(value) })}
              error={errors?.negotiatedPrice?.error}
              messageError={errors?.negotiatedPrice?.message}
            />
          </GridCellStyled>
        </GridTemplateStyled>
        {menuSelected?.extra_services?.length ? (
          <>
            <Text alignment="start" type="h3" color="principal">
              Extras
            </Text>
            <GridTemplateStyled value={2}>
              {menuSelected?.extra_services.map((service) => {
                const srv = extraServicesList.find((srv) => srv.name === service);
                if (!srv) return;
                return (
                  <GridCellStyled key={service}>
                    <DivWrapperStyled flexType={'f'}>
                      <CheckBox
                        data-testid="create-quotation-checkbox"
                        value={extraServices.includes(srv._id)}
                        onClick={() => updateExtraServices(srv._id)}
                      />
                      <Text fullWidth={false} type="h3">
                        {service}
                      </Text>
                    </DivWrapperStyled>
                  </GridCellStyled>
                );
              })}
            </GridTemplateStyled>
          </>
        ) : null}
        {menu && (peopleQuantity || childrenQuantity) && menuSelected && calculatedPrices && (
          <Summary
            {...{ calculatedPrices, childrenQuantity, menuSelected, peopleQuantity }}
            hasExtraServices={extraServices.length > 0}
          />
        )}
        <Text alignment="start" type="h3" color="principal">
          Datos del cliente
        </Text>
        <GridTemplateStyled value={3}>
          <GridCellStyled>
            <Input
              data-testid="name-test"
              value={fields.fullName}
              label="Nombre"
              onChange={(value) => onChangeField({ name: 'fullName', value })}
              error={errors?.fullName?.error}
              messageError={errors?.fullName?.message}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="phone-test"
              value={fields.phone}
              label="Teléfono"
              placeholder="+569 8765 4321"
              onChange={(value) => onChangeField({ name: 'phone', value })}
              error={errors?.phone?.error}
              messageError={errors?.phone?.message}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="mail-test"
              value={fields.email}
              label="Mail"
              placeholder="example@gmail.com"
              onChange={(value) => onChangeField({ name: 'email', value })}
              error={errors?.email?.error}
              messageError={errors?.email?.message}
            />
          </GridCellStyled>
        </GridTemplateStyled>
        <ButtonContainerStyled>
          <Button disabled={!isEnabledSaveBtn} data-testid="button-test" size="sm" onClick={onSend}>
            Crear
          </Button>
        </ButtonContainerStyled>
      </ScrollBar>
      {notification.isOpent && (
        <AlertNotification
          isOpent={notification.isOpent}
          type={notification.type}
          onClose={closeNotification}
          handleClick={actionsToNotify[notification.type]}
        />
      )}
    </>
  );
};

export default CreateQuotation;
