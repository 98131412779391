import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IAuthState, TStatus } from './models';
import { IUser } from '../../models/IUser';
import { fetchLoginAsync } from './thunks';
import { ILoginResponse } from '../../api/auth/models';

export const emptyUser: IUser = {
  _id: '',
  name: '',
  email: '',
  createdAt: '',
  updatedAt: '',
};

const getTokenFromStorage = (): string => {
  const token: string | null = localStorage.getItem('token');
  if (token) return token;
  return '';
};

const getUserFromStorage = (): IUser => {
  const user: string | null = localStorage.getItem('userLogged');
  if (user) return JSON.parse(user);
  return emptyUser;
};

export const loadInitialState = (): IAuthState => {
  const token = getTokenFromStorage();
  const user = getUserFromStorage();

  const status = user.email && token !== '' ? 'authenticated' : 'idle';

  return {
    userLogged: user,
    status,
    emailLogged: user.email,
    token,
    error: '',
  };
};

const initialState: IAuthState = loadInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onChangeEmailLogged: (state, action: PayloadAction<string>) => {
      state.emailLogged = action.payload;
    },
    onClearAuthenticationError: (state) => {
      state.error = '';
    },
    onSetAuthenticatedStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload;
    },
    onClearAuthentication: (state, action?: PayloadAction<string | undefined>) => {
      state.userLogged = emptyUser;
      state.status = 'idle';
      state.emailLogged = '';
      state.token = '';
      state.error = action ? (action.payload as string) : '';
      localStorage.removeItem('token');
      localStorage.removeItem('userLogged');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLoginAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.token = '';
        state.error = action.payload as string;
      })
      .addCase(fetchLoginAsync.fulfilled, (state, action: PayloadAction<ILoginResponse>) => {
        state.status = 'authenticated';
        state.token = action.payload.access_token;
        state.userLogged = action.payload.user;
        state.error = '';
        localStorage.setItem('token', action.payload.access_token ? action.payload.access_token : '');
        localStorage.setItem('userLogged', JSON.stringify(action.payload.user));
      });
  },
});

export const { onChangeEmailLogged, onClearAuthenticationError, onClearAuthentication, onSetAuthenticatedStatus } =
  authSlice.actions;

export const getAuthenticatedUser = (state: RootState): IUser | undefined => state.auth.userLogged;
export const getAuthenticatedToken = (state: RootState): string => (state.auth.token ? state.auth.token : '');
export const getAuthenticationStatus = (state: RootState): string => state.auth.status;
export const getEmailLogged = (state: RootState): string => state.auth.emailLogged;
export const getAuthenticationError = (state: RootState): string => state.auth.error;

export default authSlice.reducer;
