import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ISchedule } from '../../models/IQuotation';
import { fetchScheduleAsync } from './thunks';
import { IScheduleState } from './models';
import { TFetchStatus } from '../../models/IFetch';

export const initialState: IScheduleState = {
  status: 'idle',
  scheduleList: [],
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchScheduleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchScheduleAsync.fulfilled, (state, action) => {
        state.scheduleList = action.payload.data ? action.payload.data : [];
        state.status = 'success';
      })
      .addCase(fetchScheduleAsync.rejected, (state) => {
        state.status = 'failed';
        state.scheduleList = [];
      });
  },
});

export const getSchedule = (state: RootState): ISchedule[] => state.schedule.scheduleList;
export const getScheduleStatus = (state: RootState): TFetchStatus => state.schedule.status;

export default scheduleSlice.reducer;
