import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import ScrollBar from '../../components/Scrollbar';
import Text from '../../components/Text';
import { IQuotation, QuotationStatus, TQuotationStatus } from '../../models/IQuotation';
import { getAuthenticatedToken } from '../../redux/auth';
import { getEventTypes } from '../../redux/eventTypeSlice';
import { fetchEventTypesAsync } from '../../redux/eventTypeSlice/thunks';
import { getMenus } from '../../redux/menuSlice';
import { fetchMenusAsync } from '../../redux/menuSlice/thunks';
import { getEventsPendingUpdate, getQuotationList, getQuotationListStatus } from '../../redux/quotationSlice';
import { fetchAllQuotationsAsync } from '../../redux/quotationSlice/thunks';
import { useAppSelector } from '../../store/hooks';
import { capitalize } from '../../utils/capitalize';
import { formatDate } from '../../utils/formatDate';
import { toDate, toGMTDate } from '../../utils/formatDateTime';
import { parseNumber } from '../../utils/parseNumber';
import {
  DataInfo,
  EventInfo,
  EventsMessageStyled,
  EventWrapper,
  FilterWrapper,
  HomeLayout,
  LeftSide,
  ListBody,
  ListBodyButtonContainer,
  ListBodyColumn,
  ListBodyValue,
  ListContainer,
  ListHeader,
  ListHeaderColumn,
  MenuEventInfo,
  NoEvent,
  NotificationCard,
  NotificationDisplay,
  NotificationInfo,
  RightSide,
  SideWrapper,
} from './styles';

export interface QuotationStatusProps {
  status?: string;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quotations = useAppSelector(getQuotationList);
  const status = useAppSelector(getQuotationListStatus);
  const eventsPendingUpdate = useAppSelector(getEventsPendingUpdate);
  const token = useAppSelector(getAuthenticatedToken);
  const eventTypeList = useAppSelector(getEventTypes);
  const menuList = useAppSelector(getMenus);
  const [update, setUpdate] = useState<0 | 1>(0);
  const currentMonth = formatDate(new Date(), 'es-CL', { month: 'long' });
  const eventsCurrentMonth = quotations.filter(({ dateTime }) => {
    const monthOfQuotation = formatDate(new Date(dateTime), 'es-CL', { month: 'long' });
    return currentMonth === monthOfQuotation;
  });
  const allPendingQuotations = quotations.filter(({ status }) => status === 'pending');
  const optionsForDate: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };

  const getMenu = (id: string) => menuList.find((menu) => menu._id === id);
  const getEventType = (id: string) => eventTypeList.filter((event) => event._id === id);
  const quotationsFiltered = (date: string) => quotations.filter((quotation) => quotation.dateTime === date);

  useEffect(() => {
    dispatch(fetchEventTypesAsync(token));
    dispatch(fetchMenusAsync(token));
  }, []);

  useEffect(() => {
    dispatch(fetchAllQuotationsAsync({ token, update: update.toString() }));
  }, [update]);

  const quotationsSorted = eventsCurrentMonth.slice().sort(function (a, b) {
    const fechaA = new Date(a.dateTime).getTime();
    const fechaB = new Date(b.dateTime).getTime();
    return fechaA > fechaB ? 1 : -1;
  });

  const quotationsByStatus = quotationsSorted.reduce((acc, quotation) => {
    acc[quotation.status] ??= [];
    acc[quotation.status]?.push(quotation);
    return acc;
  }, {} as Partial<Record<TQuotationStatus, IQuotation[]>>);

  const fechas = [...new Set(quotationsSorted.slice(0, 6).map(({ dateTime }) => dateTime))];

  if (status === 'loading') return <Text type="h2">Cargando...</Text>;

  return (
    <div style={{ height: '100%' }}>
      <ScrollBar>
        {eventsPendingUpdate ? (
          <EventsMessageStyled>
            <Text fullWidth={false} type="body2" weight="bold">
              Hay {eventsPendingUpdate} evento(s) aún en estado confirmado con fecha vencida. ¿Desea actualizarlos a
              realizados?
            </Text>
            <Button onClick={() => setUpdate(1)}>Actualizar a Finalizados</Button>
          </EventsMessageStyled>
        ) : null}

        <Text fullWidth={false} type="h1" weight="bold">
          Eventos
        </Text>
        <NotificationDisplay>
          <NotificationCard>
            <Icon name="bigFork" />
            <NotificationInfo>
              <Text fullWidth={false} alignment="start" type="body">
                Eventos este mes
              </Text>
              <DataInfo>{eventsCurrentMonth?.length ?? 0}</DataInfo>
            </NotificationInfo>
          </NotificationCard>
          <NotificationCard>
            <Icon name="bigCheckBox" />
            <NotificationInfo>
              <Text fullWidth={false} alignment="start" type="body">
                Confirmados
              </Text>
              <DataInfo>{quotationsByStatus.confirmed?.length ?? 0}</DataInfo>
            </NotificationInfo>
          </NotificationCard>
          <NotificationCard>
            <Icon name="bigInfo" />
            <NotificationInfo>
              <Text fullWidth={false} alignment="start" type="body">
                Pendientes
              </Text>
              <DataInfo>{quotationsByStatus.pending?.length ?? 0}</DataInfo>
            </NotificationInfo>
          </NotificationCard>
          <NotificationCard>
            <Icon name="dollarCoinActive" />
            <NotificationInfo>
              <Text fullWidth={false} alignment="start" type="body">
                Finalizados
              </Text>
              <DataInfo>{quotationsByStatus.finalized?.length ?? 0}</DataInfo>
            </NotificationInfo>
          </NotificationCard>
          <NotificationCard>
            <Icon name="bigCross" />
            <NotificationInfo>
              <Text fullWidth={false} alignment="start" type="body">
                Cancelados
              </Text>
              <DataInfo>{quotationsByStatus.cancelled?.length ?? 0}</DataInfo>
            </NotificationInfo>
          </NotificationCard>
        </NotificationDisplay>

        <HomeLayout>
          <LeftSide>
            <Text fullWidth={false} type="h1" weight="bold">
              Cotizaciones pendientes
            </Text>
            <ListContainer>
              <ListHeader>
                <ListHeaderColumn>Fecha</ListHeaderColumn>
                <ListHeaderColumn>Menú</ListHeaderColumn>
                <ListHeaderColumn>Cliente</ListHeaderColumn>
                <ListHeaderColumn>Valor</ListHeaderColumn>
                <ListHeaderColumn>Acción</ListHeaderColumn>
              </ListHeader>
              {!quotations.length ? (
                <Loading />
              ) : (
                allPendingQuotations.map((quotation) => (
                  <ListBody key={quotation._id}>
                    <ListBodyColumn>Fecha</ListBodyColumn>
                    <ListBodyValue>
                      {capitalize(formatDate(toDate(toGMTDate(quotation.dateTime).date), 'es-CL', optionsForDate))}
                    </ListBodyValue>
                    <ListBodyColumn>Menú</ListBodyColumn>
                    <ListBodyValue>{capitalize(getMenu(quotation.menuId)?.name)}</ListBodyValue>
                    <ListBodyColumn>Cliente</ListBodyColumn>
                    <ListBodyValue>{capitalize(quotation.contact.fullName)}</ListBodyValue>
                    <ListBodyColumn>Valor</ListBodyColumn>
                    <ListBodyValue>${parseNumber.withMillerDot(quotation.negotiatedPrice)}</ListBodyValue>
                    <ListBodyColumn>Acción</ListBodyColumn>
                    <ListBodyButtonContainer>
                      <Button
                        variant="outline"
                        size="xs"
                        onClick={() => navigate(`/cotizaciones/detalle/${quotation._id}`)}
                      >
                        Ver
                      </Button>
                    </ListBodyButtonContainer>
                  </ListBody>
                ))
              )}
            </ListContainer>
          </LeftSide>
          <RightSide>
            <Text fullWidth={false} type="h1" weight="bold">
              Agenda
            </Text>
            <SideWrapper>
              <ScrollBar>
                {fechas.map((fecha, f) => {
                  const { date } = toGMTDate(fecha);
                  return (
                    <div key={f}>
                      <FilterWrapper>
                        <Text alignment="start" fullWidth={false} type="body">
                          {capitalize(
                            formatDate(Date.parse(date.replace(/-/g, '/')), 'es', {
                              weekday: 'long',
                              day: 'numeric',
                              month: 'long',
                            })
                          )}
                        </Text>
                      </FilterWrapper>
                      {quotationsFiltered(fecha).length ? (
                        <div>
                          {quotationsFiltered(fecha).map((quotation, i) => {
                            const [event] = getEventType(quotation.eventTypeId);
                            const { time } = toGMTDate(quotation.dateTime);
                            const status = Object.keys(QuotationStatus).find(
                              (key) => QuotationStatus[key] === quotation.status
                            );
                            return (
                              <EventWrapper key={i}>
                                <EventInfo>
                                  <Icon name="watch" />
                                  {time}
                                </EventInfo>
                                <EventInfo>
                                  <MenuEventInfo>
                                    <Icon name="fork" />
                                    {getMenu(quotation.menuId)?.name}
                                  </MenuEventInfo>
                                </EventInfo>
                                <EventInfo>
                                  <MenuEventInfo>
                                    <Icon name="book" />
                                    {event?.name}
                                  </MenuEventInfo>
                                </EventInfo>
                                <EventInfo>
                                  <MenuEventInfo>
                                    <Icon name="users" />
                                    {quotation.peopleQuantity + quotation.childrenQuantity}
                                  </MenuEventInfo>
                                </EventInfo>
                                <EventInfo>
                                  <MenuEventInfo status={status}>{status}</MenuEventInfo>
                                </EventInfo>
                                <EventInfo
                                  onClick={() => navigate(`/cotizaciones/detalle/${quotation._id}`)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Text color="principal" fullWidth={false} alignment="start" type="body">
                                    Ver evento
                                  </Text>
                                </EventInfo>
                              </EventWrapper>
                            );
                          })}
                        </div>
                      ) : (
                        <EventWrapper>
                          <NoEvent>Sin eventos</NoEvent>
                        </EventWrapper>
                      )}
                    </div>
                  );
                })}
              </ScrollBar>
            </SideWrapper>
          </RightSide>
        </HomeLayout>
      </ScrollBar>
    </div>
  );
};
export default Home;
