/* istanbul ignore file */
import { IDessert } from '../../models/IDessert';
import { IFetchResponse } from '../../models/IFetch';
import URL from './url';

export interface IDessertCreateForm {
  name: string;
  description: string;
  file: File | undefined;
  isActive: string;
}

export interface IDessertPutForm {
  name?: string;
  description?: string;
  file?: File | undefined;
  isActive?: string;
}

export const fetchAllDesserts = async (token: string): Promise<IFetchResponse<IDessert[]>> => {
  const { url, params } = URL.getAllDesserts(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const fetchDessertById = async (dessertId: string, token: string): Promise<IFetchResponse<IDessert>> => {
  const { url, params } = URL.getDessertById(dessertId, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const postDessert = async (
  dessert: IDessertCreateForm,
  token: string
): Promise<IFetchResponse<{ created: IDessert; list: IDessert[] }>> => {
  const formData = new FormData();

  formData.append('name', dessert.name);
  dessert.description && formData.append('description', dessert.description);
  dessert?.file && formData.append('file', dessert.file);
  formData.append('isActive', dessert.isActive);

  const { url, params } = URL.postDessert(formData, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const putDessert = async (
  dessertId: string,
  dessert: IDessertPutForm,
  token: string
): Promise<IFetchResponse<{ updated: IDessert; list: IDessert[] }>> => {
  const formData = new FormData();

  dessert?.name && formData.append('name', dessert.name);
  dessert?.description && formData.append('description', dessert.description);
  dessert?.file && formData.append('file', dessert.file);
  dessert?.isActive && formData.append('isActive', dessert.isActive);

  const { url, params } = URL.putDessert(dessertId, formData, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const deleteDessert = async (dessertId: string, token: string): Promise<IFetchResponse<IDessert[]>> => {
  const { url, params } = URL.deleteDessert(dessertId, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
