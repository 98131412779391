/* istanbul ignore file */
import { IFetchOptions } from '../../../models/IFetch';
import { ICreateMenu, IUpdateMenu } from '../models';

const URL = process.env.REACT_APP_API_URL;

const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export default {
  getAllMenus: (token: string): IFetchOptions => ({
    url: `${URL}/menus/all/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  postMenu: (token: string, menu: ICreateMenu): IFetchOptions => ({
    url: `${URL}/menus/`,
    params: {
      method: 'POST',
      headers: headers(token),
      body: JSON.stringify(menu),
    },
  }),
  putMenu: (id: string, menu: IUpdateMenu, token: string): IFetchOptions => ({
    url: `${URL}/menus/${id}/`,
    params: {
      method: 'PUT',
      headers: headers(token),
      body: JSON.stringify(menu),
    },
  }),
  deleteMenu: (id: string, token: string): IFetchOptions => ({
    url: `${URL}/menus/${id}/`,
    params: {
      method: 'DELETE',
      headers: headers(token),
    },
  }),
};
