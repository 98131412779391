/* istanbul ignore file */

import { IFetchOptions } from '../../../models/IFetch';

const URL = process.env.REACT_APP_API_URL;
const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

const uploadHeader = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
});

export default {
  getAllSalads: (token: string): IFetchOptions => ({
    url: `${URL}/ensaladas/all/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  getSaladsById: (saladId: string, token: string): IFetchOptions => ({
    url: `${URL}/ensaladas/${saladId}/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  postSalad: (salad: FormData, token: string): IFetchOptions => ({
    url: `${URL}/ensaladas/`,
    params: {
      method: 'POST',
      headers: uploadHeader(token),
      body: salad,
    },
  }),
  putSalad: (saladId: string, salad: FormData, token: string): IFetchOptions => ({
    url: `${URL}/ensaladas/${saladId}/`,
    params: {
      method: 'PUT',
      headers: uploadHeader(token),
      body: salad,
    },
  }),
  deleteSalad: (saladId: string, token: string): IFetchOptions => ({
    url: `${URL}/ensaladas/${saladId}/`,
    params: {
      method: 'DELETE',
      headers: headers(token),
    },
  }),
};
