import { ISalad } from '../../../../models/ISalad';
import { IFormSchema } from '../../../../models/ISchema';

export const fieldSchema: IFormSchema<Pick<ISalad, 'name' | 'description' | 'isActive'>> = {
  name: {
    initialValue: '',
    rules: {
      required: true,
      minLength: 3,
      maxLength: 48,
    },
  },
  description: {
    initialValue: '',
  },
  isActive: {
    initialValue: true,
  },
};
