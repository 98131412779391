import Icon from '../Icon';
import { Pagination as PaginationStyled, PaginationAction, PaginationItem } from './styles';

const Pagination: React.FC<PaginationProps> = ({ pages, currentPage, prevPage, nextPage, onChange }) => {
  return (
    <PaginationStyled>
      <PaginationAction disabled={!prevPage} onClick={() => onChange(Number(prevPage))} data-testid="prevBtn">
        <Icon name="caretLeft" />
      </PaginationAction>
      {[...Array(pages)].map((item, i) => (
        <PaginationItem key={i} active={currentPage === i + 1} onClick={() => onChange(i + 1)} data-testid="pageBtn">
          {i + 1}
        </PaginationItem>
      ))}
      <PaginationAction disabled={!nextPage} onClick={() => onChange(Number(nextPage))} data-testid="nextBtn">
        <Icon name="caret" />
      </PaginationAction>
    </PaginationStyled>
  );
};

interface PaginationProps {
  pages: number;
  currentPage: number;
  prevPage?: number | null;
  nextPage?: number | null;
  onChange: (page: number) => void;
}

export interface PaginationItemProps {
  active?: boolean;
}

export default Pagination;
