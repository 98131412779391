/* istanbul ignore file */
import { IExtraService } from '../../models/IExtraService';
import { IFetchResponse } from '../../models/IFetch';
import {
  ICreatedExtraServiceResponse,
  ICreateExtraService,
  IUpdatedExtraServiceResponse,
  IUpdateExtraService,
} from './models';
import URL from './url';

export const fetchAllExtraServices = async (token: string): Promise<IFetchResponse<IExtraService[]>> => {
  const { url, params } = URL.getAllExtraServices(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const createExtraService = async (
  token: string,
  body: ICreateExtraService
): Promise<IFetchResponse<ICreatedExtraServiceResponse>> => {
  const { url, params } = URL.postExtraService(token, body);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const updateExtraService = async (
  token: string,
  id: string,
  body: IUpdateExtraService
): Promise<IFetchResponse<IUpdatedExtraServiceResponse>> => {
  const { url, params } = URL.putExtraService(token, id, body);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const deleteExtraService = async (token: string, id: string): Promise<IFetchResponse<IExtraService[]>> => {
  const { url, params } = URL.deleteExtraService(token, id);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
