import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Logo from '../../../components/Logo';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { FormContainer, InputContainer, PageContainer } from './styles';
import { schema } from './schemas';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Error from '../../../components/Error';
import { useAppDispatch } from '../../../store/hooks';
import { resetPasswordAsync } from '../../../redux/auth/thunks';

const ResetPassword = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fields, errors, onChangeField, isValidForm } = useForm({ fieldSchema: schema, validateOnInit: true });
  const [formError, setFormError] = useState('');

  useEffect(() => {
    onChangeField({ name: 'code', value: code });
    fields.newPassword === fields.newPasswordConfirmation
      ? setFormError('')
      : setFormError('Las contraseñas no coinciden');
  }, [fields.newPassword, fields.newPasswordConfirmation]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      await dispatch(resetPasswordAsync({ code: fields.code, newPassword: fields.newPassword })).unwrap();
      navigate('/login');
    } catch (error: any) {
      setFormError(error);
    }
  };

  return (
    <PageContainer>
      <Logo />
      <FormContainer onSubmit={handleSubmit}>
        <Text>Restablecer contraseña</Text>
        <InputContainer>
          <Input
            value={fields.newPassword}
            onChange={(value) => onChangeField({ name: 'newPassword', value })}
            label="Nueva contraseña"
            type="password"
            error={errors.newPassword?.error}
            messageError={errors.newPassword?.message}
            data-testid="new-password-input"
          />
        </InputContainer>
        <InputContainer>
          <Input
            value={fields.newPasswordConfirmation}
            onChange={(value) => onChangeField({ name: 'newPasswordConfirmation', value })}
            label="Repetir contraseña"
            type="password"
            error={errors.newPasswordConfirmation?.error}
            messageError={errors.newPasswordConfirmation?.message}
            data-testid="new-password-confirm-input"
          />
        </InputContainer>

        <Button fullWidth type="submit" disabled={!isValidForm || !!formError} data-testid="submit-btn">
          Restablecer
        </Button>
        {formError && <Error message={formError} />}
      </FormContainer>
    </PageContainer>
  );
};

export default ResetPassword;
