import { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { IGallery } from '../../../models/IGallery';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getGallery } from '../../../redux/gallerySlice';
import { fetchGalleryAsync } from '../../../redux/gallerySlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CreateGalleryItem from '../CreateGalleryItem';
import RemoveGalleryItem from '../RemoveGalleryItem';
import { GalleryContainer, GalleryContent, GalleryTitleContainer, Image, ImageContainer, RemoveBtn } from './styles';

const Gallery = () => {
  const token = useAppSelector(getAuthenticatedToken);
  const gallery = useAppSelector(getGallery);
  const dispatch = useAppDispatch();
  const [galleryItemSelected, setGalleryItemSelected] = useState<IGallery>();
  const [isCreateModalOpent, setIsCreateModalOpent] = useState(false);
  const [isRemoveModalOpent, setIsRemoveModalOpent] = useState(false);

  useEffect(() => {
    dispatch(fetchGalleryAsync(token));
  }, [dispatch]);

  const showRemoveModal = (item: IGallery) => {
    setGalleryItemSelected(item);
    setIsRemoveModalOpent(true);
  };

  return (
    <ScrollBar>
      <GalleryContainer>
        <GalleryTitleContainer>
          <Text fullWidth={false} type="h1">
            Galería
          </Text>
          <Button size="xs" variant="default" onClick={() => setIsCreateModalOpent(true)}>
            Crear Nuevo
          </Button>
        </GalleryTitleContainer>

        <GalleryContent>
          {gallery.map((image) => (
            <ImageContainer key={image._id}>
              <Image src={image.img_url} />
              <RemoveBtn onClick={() => showRemoveModal(image)} data-testid="delete-gallery">
                <Icon name="union" />
              </RemoveBtn>
            </ImageContainer>
          ))}
        </GalleryContent>
      </GalleryContainer>
      <CreateGalleryItem isOpent={isCreateModalOpent} setIsOpent={setIsCreateModalOpent} />
      <RemoveGalleryItem
        id={galleryItemSelected?._id}
        isOpent={isRemoveModalOpent}
        setIsOpent={setIsRemoveModalOpent}
      />
    </ScrollBar>
  );
};

export default Gallery;
