/* istanbul ignore file */
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';
import { mediaQuery } from '../../../utils/mediaQuery';
import Myimg from '../img/logo 3.png';

//LAYOUT

export const LogoWrapperStyled = styled.div`
  padding: 11px 10px 10px 19px;
  align-items: center;
  display: flex;
  column-gap: 10px;
  position: absolute;
  z-index: 2;
  mix-blend-mode: screen;
`;

export const IconWrapper = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MyIcon = styled.img`
  mix-blend-mode: screen;
  width: 134px;
  height: 39px;
  //top right bottom left
`;

MyIcon.defaultProps = {
  src: Myimg,
};

//HEADER
export const HeaderStyled = styled.div`
  flex-shrink: 0;
  display: flex;
  width: 100%;
  height: 61px;
  background: #2d2d2d;
  align-items: center;
`;

export const HeaderDateStyled = styled.div`
  font-family: 'Mulish';
  position: absolute;
  left: 245px;
  font-size: 16px;
  line-height: 18px;
  padding-right: 3px;
  color: #ffff;
  flex-shrink: 0;
  //aqui
  ${mediaQuery.tabletOnly(`
   display: none;
  `)}
  ${mediaQuery.mobileOnly(`
   display: none;
  `)}
`;

export const IconStiled = styled.div`
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
  width: 122px;
  ${mediaQuery.tabletOnly(`
   width: auto;
   padding-right: 5px;
  `)}
  ${mediaQuery.mobileOnly(`
   width: auto;
   padding-right: 5px;
  `)}
`;

export const HeaderIconStyled = styled.div`
  margin-right: 70px;
  margin-left: 50px;
  cursor: pointer;
`;

//SIDEBAR
export const SideBarStyled = styled.div<{ visible }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 230px;
  background: #222222;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  transition: width 0.25s ease;
  z-index: 1;
  width: ${({ visible }) => (visible ? '230px' : '0')};
  box-shadow: ${({ visible }) => (visible ? 'rgba(0, 0, 0, 0.35) 0 5px 15px' : 'none')};

  @media (max-width: ${BREAKPOINTS.tablet}px) {
    position: fixed;
    width: ${({ visible }) => (!visible ? '230px' : '0')};
    box-shadow: ${({ visible }) => (!visible ? 'rgba(0, 0, 0, 0.35) 0 5px 15px' : 'none')};
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 30px;
  margin-top: 60px;
`;

export const OptionWrapperStyled = styled.div`
  padding: 20px 20px 20px 5px;
  align-items: center;
  display: flex;
  column-gap: 10px;
`;

export const SideBarButton = styled.div<{ isActive: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 90% */
  align-items: center;
  letter-spacing: 0.2px;

  /* principal 2 */

  color: ${({ isActive }) => (isActive ? '#eb9f1a' : '#ffff')};
  text-underline-offset: ${({ isActive }) => (isActive ? '7px' : '0')};
  text-decoration: ${({ isActive }) => (isActive ? 'underline #eb9f1a' : 'none')};
`;

//CONTENT
export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const LayoutTemplate = styled.div`
  display: flex;
  background: #131313;
`;

export const LayoutContent = styled.div`
  margin: 28px 48px;
  padding: 5px;
  overflow: hidden;
  ${mediaQuery.mobileToDesktop(`
   margin: 0;
  `)}/*overflow-y: scroll;
  
  padding-right: 0.5vw;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #222222;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #eb9f1a;
  }*/
`;
