import { ContainerImage, StyledImage } from './styles';

interface ImageProps {
  src: string;
  alt?: string;
  width?: string;
  style?: React.CSSProperties;
}

const Image = ({ src, width, ...otherProps }: ImageProps) => {
  return (
    <ContainerImage>
      <StyledImage src={src} width={width} {...otherProps} />
    </ContainerImage>
  );
};

export default Image;
