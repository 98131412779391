import React from 'react';
import { WithChildren } from '../../models/General';
import { StyledText } from './styles';
type TTextType = 'h1' | 'h2' | 'h3' | 'body' | 'body2' | 'description';
type AlignmentType = 'start' | 'center';
type TColorType = 'principal' | 'texts' | 'background' | 'alternative' | 'card' | 'active';
type TTextAlignType = 'left' | 'center';

export type TextProps = WithChildren<{
  type?: TTextType;
  alignment?: AlignmentType;
  fullWidth?: boolean;
  color?: TColorType;
  textAlignment?: TTextAlignType;
  weight?: 'bold' | 'regular';
}>;

const defaultProps: TextProps = {
  type: 'h1',
  alignment: 'center',
  fullWidth: true,
  color: 'texts',
  textAlignment: 'center',
  weight: 'regular',
};

const Text = ({ children, ...otherProps }: TextProps) => {
  return <StyledText {...otherProps}>{children}</StyledText>;
};

Text.defaultProps = defaultProps;
export default Text;
