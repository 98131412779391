import styled from 'styled-components';
import { SummaryWrapperProps } from '..';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const SummaryWrapperStyled = styled.div<SummaryWrapperProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #222222;
  margin-bottom: 20px;
  > section {
    display: grid;
    grid-template-columns: ${({ hasChildrenMenu }) => (hasChildrenMenu ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')};
    grid-column: 1 / -1;
    padding: 20px 20px;
    gap: 40px;
    border: 1px solid #4b4b4b;
    > div {
      ${mediaQuery.mobileToTablet(`
        flex-wrap: wrap;
      `)}
    }
    ${mediaQuery.mobileToTablet(`
      grid-template-columns: repeat(2, 1fr);
    `)}
  }
  > div {
    display: flex;
    padding: 20px 20px;
    border: 1px solid #4b4b4b;
    ${mediaQuery.mobileToTablet(`
      display: block;
    `)}
    &:first-child {
      grid-column: ${({ hasChildrenMenu }) => (hasChildrenMenu ? 'auto' : '1 / -1')};
    }
  }
`;
