/* istanbul ignore file */
import { IFetchOptions } from '../../../models/IFetch';

const URL = process.env.REACT_APP_API_URL;

const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export default {
  getEventTypes: (token: string): IFetchOptions => ({
    url: `${URL}/event-types/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
};
