import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import Check from '../../components/MenuTable/components/CheckBox';
import ScrollBar from '../../components/Scrollbar';
import Select from '../../components/Select';
import Text from '../../components/Text';
import { ISchedule } from '../../models/IQuotation';
import { getAuthenticatedToken } from '../../redux/auth';
import { getDisabledDates, getDisabledDatesStatus } from '../../redux/disabledDateSlice';
import { deleteDisabledDatesAsync, postDisabledDatesAsync } from '../../redux/disabledDateSlice/thunks';
import { getEventTypes } from '../../redux/eventTypeSlice';
import { fetchEventTypesAsync } from '../../redux/eventTypeSlice/thunks';
import { getMenus } from '../../redux/menuSlice';
import { fetchMenusAsync } from '../../redux/menuSlice/thunks';
import { getSchedule, getScheduleStatus } from '../../redux/scheduleSlice';
import { fetchScheduleAsync } from '../../redux/scheduleSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { capitalize } from '../../utils/capitalize';
import { formatDate } from '../../utils/formatDate';
import { toDate, toGMTDate } from '../../utils/formatDateTime';
import { getPeopleQuantity } from '../../utils/getPeopleQuantity';
import {
  EnabledText,
  EventInfo,
  EventsWrapper,
  EventWrapper,
  FilterContainer,
  Filters,
  FilterWrapper,
  NoEvent,
  RightSideLayout,
  ScheduleLayout,
} from './styles';

export type TScheduleLayoutProps = {
  isLoading?: boolean;
};

const Agenda = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const schedule = useAppSelector(getSchedule);
  const scheduleStatus = useAppSelector(getScheduleStatus);
  const eventTypeList = useAppSelector(getEventTypes);
  const menusList = useAppSelector(getMenus);
  const disabledDates = useAppSelector(getDisabledDates);
  const disabledDatesStatus = useAppSelector(getDisabledDatesStatus);
  const token = useAppSelector(getAuthenticatedToken);
  const currentMonth = capitalize(formatDate(new Date(), 'es-CL', { month: 'long' }));
  const [month, setMonth] = useState<string>(currentMonth);
  const [event, setEvent] = useState<string>('All');
  const [scheduleOfCurrentMonth, setScheduleOfCurrentMonth] = useState<ISchedule[]>([]);

  const getEventType = (id) => eventTypeList.find((event) => event._id === id);
  const getMenu = (id: string) => menusList.find((menu) => menu._id === id);

  const months = [
    currentMonth,
    capitalize(formatDate(new Date().setMonth(new Date().getMonth() + 1), 'es-CL', { month: 'long' })),
  ];

  useEffect(() => {
    dispatch(fetchMenusAsync(token));
    dispatch(fetchEventTypesAsync(token));
  }, []);

  useEffect(() => {
    setScheduleOfCurrentMonth(
      schedule.filter((el) => capitalize(formatDate(toDate(el.date), 'es-Cl', { month: 'long' })) === month)
    );
  }, [schedule, month]);

  useEffect(() => {
    dispatch(fetchScheduleAsync(token));
  }, [disabledDates]);

  const onChangeMonth = (e: any) => setMonth(e.target.value);
  const onChangeEvent = (e: any) => setEvent(e.target.value);
  const handleDisabledDates = (date: string, isActive: boolean) =>
    isActive ? dispatch(postDisabledDatesAsync({ token, date })) : dispatch(deleteDisabledDatesAsync({ token, date }));

  const scheduleFilteredByDay = (day) =>
    scheduleOfCurrentMonth
      .find((schedule) => schedule.date === day)
      ?.events.filter((quotation) =>
        ['pending', 'accepted'].includes(quotation.status) && event === 'All'
          ? true
          : getEventType(quotation.eventTypeId)?.name === event
      );

  return (
    <>
      <Text fullWidth={false} type="h1">
        Agenda
      </Text>
      <ScheduleLayout isLoading={[disabledDatesStatus, scheduleStatus].includes('loading')}>
        <RightSideLayout>
          <Filters>
            <FilterWrapper>
              <Text fullWidth={false} type="h3">
                Mes seleccionado
              </Text>
              <Select onchange={onChangeMonth} options={months} />
            </FilterWrapper>
            <FilterContainer>
              <Text fullWidth={false} type="h3">
                Evento seleccionado
              </Text>
              <Select
                allOption={0}
                onchange={onChangeEvent}
                options={['Todos', ...eventTypeList.map((event) => event.name)]}
              />
            </FilterContainer>
          </Filters>

          {!schedule.length ? (
            <Loading />
          ) : (
            <EventsWrapper>
              <div style={{ height: '92%' }}>
                <ScrollBar>
                  {scheduleOfCurrentMonth.map(({ date, isActive }, i) => {
                    return (
                      <div key={i}>
                        <FilterWrapper>
                          <p>
                            {capitalize(
                              formatDate(toDate(date), 'es-CL', { weekday: 'long', day: 'numeric', month: 'long' })
                            )}
                          </p>
                          <div style={{ marginLeft: 'auto', columnGap: '5px' }}>
                            <Text fullWidth={false} type="h3">
                              <EnabledText data-testid="schedule-day" value={isActive}>
                                {isActive ? 'Dia Habilitado' : 'Dia Deshabilitado'}
                              </EnabledText>
                            </Text>
                            <Check checked={isActive} onChange={() => handleDisabledDates(date, isActive)} />
                          </div>
                        </FilterWrapper>
                        {scheduleFilteredByDay(date)?.length ? (
                          <div>
                            {scheduleFilteredByDay(date)?.map((quotation, a) => {
                              const { time } = toGMTDate(quotation.dateTime);
                              return (
                                <EventWrapper value={scheduleFilteredByDay(date)?.length} key={a}>
                                  <EventInfo>
                                    <Icon name="watch" />
                                    {time}
                                  </EventInfo>
                                  <EventInfo>
                                    <Icon name="user" />
                                    {capitalize(quotation.contact.fullName)}
                                  </EventInfo>
                                  <EventInfo>
                                    <Icon name="book" />
                                    {getMenu(quotation.menuId)?.name}
                                  </EventInfo>
                                  <EventInfo>
                                    <Icon name="users" />
                                    {getPeopleQuantity(quotation.peopleQuantity, quotation.childrenQuantity)}
                                  </EventInfo>
                                  <EventInfo>
                                    <Icon name="fork" />
                                    {getEventType(quotation.eventTypeId)?.name}
                                  </EventInfo>
                                  <EventInfo>
                                    <Button
                                      data-testid="schedule-button-edit"
                                      variant="outline"
                                      size="xs"
                                      onClick={() => navigate(`/cotizaciones/detalle/${quotation._id}/editar`)}
                                    >
                                      Editar
                                    </Button>
                                  </EventInfo>
                                </EventWrapper>
                              );
                            })}
                          </div>
                        ) : (
                          <EventWrapper value={0}>
                            <NoEvent>Sin eventos</NoEvent>
                          </EventWrapper>
                        )}
                      </div>
                    );
                  })}
                </ScrollBar>
              </div>
            </EventsWrapper>
          )}
        </RightSideLayout>
      </ScheduleLayout>
    </>
  );
};

export default Agenda;
