import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../../../components/Modal';
import { ISalad } from '../../../api/salads/models';
import Text from '../../../components/Text';
import Image from '../../../components/ProductImageDetail';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import { ContainerInput, StyledButtons, ContainerVisibility } from './styles';
import { putSaladAsync } from '../../../redux/saladSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAuthenticatedToken } from '../../../redux/auth';
import { loadImage } from '../../../utils/file';
import CheckBox from '../../../components/MenuTable/components/CheckBox';
import useForm from '../../../hooks/useForm';
import { fieldSchema } from '../EditSalad/schemas';
import { getFieldSchema } from '../../../utils/getFieldSchema';

interface IEditSaladProps {
  selectedSalad: ISalad;
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  onClickDelete: () => void;
}

interface IUpdateSalad {
  name: string;
  description: string;
  file: File | undefined;
  isActive: string;
}

const EditSalad = ({ selectedSalad, isOpent, setIsOpent, onClickDelete }: IEditSaladProps) => {
  const { name, description, isActive, _id, img_url } = selectedSalad;
  const schema = getFieldSchema({
    fieldSchema,
    fieldValues: { name, description, isActive },
  });
  const { fields, onChangeField, errors, isValidForm } = useForm({
    fieldSchema: schema,
  });
  const [image, setImage] = useState<{ file: File; src: string | undefined }>();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);

  const onSend = () => {
    const salad: IUpdateSalad = {
      name: fields.name,
      description: fields.description,
      file: image?.file,
      isActive: String(fields.isActive),
    };
    dispatch(putSaladAsync({ saladId: _id, salad, token }));
    setIsOpent(false);
  };

  const onChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const src = await loadImage(e.target.files[0]);
      setImage({ src, file: e.target.files[0] });
    }
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="sm" data-testid="edit-salad-modal">
      <Text type="body" alignment="start" color="principal">
        Editar ensalada
      </Text>
      <Image onChange={onChangeImage} src={image?.src ?? img_url} data-testid="image-input" />
      <ContainerInput>
        <Input
          data-testid="name-test"
          value={fields.name}
          onChange={(value) => onChangeField({ name: 'name', value })}
          type="Name"
          label="Nombre"
          border
          messageError={errors.name?.message}
          error={errors.name?.error}
        />
      </ContainerInput>
      <TextArea
        data-testid="desc-test"
        value={fields.description}
        onChange={(e) => onChangeField({ name: 'description', value: e.target.value })}
        label="Descripción"
      />
      <ContainerInput>
        <Text type="body" alignment="start">
          Visible en la web
        </Text>
        <ContainerVisibility>
          <CheckBox
            checked={fields.isActive}
            onChange={(e) => onChangeField({ name: 'isActive', value: e.target.checked })}
            data-testid="active-test"
          />
          <div>
            <Text type="body" alignment="start">
              Si
            </Text>
          </div>
        </ContainerVisibility>
      </ContainerInput>
      <StyledButtons>
        <Button data-testid="close-test" size="xs" variant="black" onClick={() => onClickDelete()}>
          Eliminar
        </Button>
        <Button size="xs" data-testid="save-test" onClick={onSend} disabled={!isValidForm}>
          Guardar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default EditSalad;
