/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './store';
import AppRouter from './routers';

import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

import './fonts/Mulish-Black.ttf';
import './fonts/Mulish-Bold.ttf';
import './fonts/Mulish-Regular.ttf';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
