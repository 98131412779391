import { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import {
  ArrowLeftContainer,
  ArrowRightContainer,
  ButtonContainer,
  FilesCounter,
  ImageContainer,
  StyledBg,
  StyledIcon,
  StyledIconCamera,
  StyledImage,
} from './styles';

export interface FileUploaderProps {
  onChange: (event: any) => void;
  acceptedFormats?: string;
  value?: File | FileList;
  multiple?: boolean;
}

const FileUploader = ({
  value,
  onChange,
  acceptedFormats = '.jpg, .jpeg, .png',
  multiple = false,
}: FileUploaderProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFiles] = useState<File | FileList>();
  const [current, setCurrent] = useState(0);
  const filesNumber = fileInput.current?.files?.length || 0;

  useEffect(() => {
    onChange(file);
  }, [file]);
  useEffect(() => {
    if (!value && fileInput.current) {
      fileInput.current.value = '';
    }
  }, [value]);

  const handleClick = () => {
    fileInput.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const src = getUrl(event.target.files[0]);
    if (fileInput.current) {
      fileInput.current.src = src;
    }
    setFiles(multiple ? event.target.files : event.target.files[0]);
  };

  const prev = () => {
    if (fileInput.current && filesNumber > 1 && current > 0) {
      if (fileInput.current.files) {
        fileInput.current.src = getUrl(fileInput.current.files[current - 1]);
      }
      setCurrent(current - 1);
    }
  };

  const next = () => {
    if (fileInput.current && filesNumber > 1 && current + 1 < filesNumber) {
      if (fileInput.current.files) {
        fileInput.current.src = getUrl(fileInput.current.files[current + 1]);
      }
      setCurrent(current + 1);
    }
  };

  const getUrl = (file: File) => URL.createObjectURL(file);

  return (
    <ImageContainer>
      {value ? (
        <StyledImage src={fileInput.current?.src} alt="imagen del producto" />
      ) : (
        <StyledBg>
          <StyledIconCamera>
            <Icon name="camera" />
          </StyledIconCamera>
        </StyledBg>
      )}
      <ButtonContainer>
        <Button variant="transparent" pill size="xxs" data-testid="fileuploader-action" onClick={handleClick}>
          {value ? 'Cambiar' : 'Agregar'} foto{multiple && 's'}
          <StyledIcon>
            <Icon name="camera" />
          </StyledIcon>
        </Button>
      </ButtonContainer>
      {multiple && filesNumber > 1 && (
        <>
          <FilesCounter data-testid="counterText">
            {current + 1}/{filesNumber}
          </FilesCounter>
          {current + 1 < filesNumber && (
            <ArrowRightContainer onClick={next} data-testid="prevBtn">
              <Icon name="caret" />
            </ArrowRightContainer>
          )}
          {current > 0 && (
            <ArrowLeftContainer onClick={prev} data-testid="nextBtn">
              <Icon name="caretLeft" />
            </ArrowLeftContainer>
          )}
        </>
      )}
      <input
        type="file"
        accept={acceptedFormats}
        onChange={handleChange}
        ref={fileInput}
        multiple={multiple}
        data-testid="image-input"
      />
    </ImageContainer>
  );
};

export default FileUploader;
