import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUpdateQuotation } from '../../../api/quotations/models';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Loading from '../../../components/Loading';
import { TableRowStyled, TableStyled } from '../../../components/MenuTable/styles';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { IContact, IQuotation, QuotationStatus, TQuotationStatus } from '../../../models/IQuotation';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getEventTypes } from '../../../redux/eventTypeSlice';
import { fetchEventTypesAsync } from '../../../redux/eventTypeSlice/thunks';
import { getExtraServices } from '../../../redux/extraServiceSlice';
import { fetchExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { getMenus } from '../../../redux/menuSlice';
import { fetchMenusAsync } from '../../../redux/menuSlice/thunks';
import { getQuotationList } from '../../../redux/quotationSlice';
import { updateQuotationAsync } from '../../../redux/quotationSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { capitalize } from '../../../utils/capitalize';
import { transformDate } from '../../../utils/formatDate';
import { toGMTDate } from '../../../utils/formatDateTime';
import { parseNumber } from '../../../utils/parseNumber';
import { removeKeys } from '../../../utils/removeKeys';
import RemoveQuotation from '../RemoveQuotation';
import { quotationTableHeader } from './constants';
import { CellStyled, StatusWrapperStyled, TableWrapperStyled, TitleWrapperStyled } from './styles';

interface IQuotationDetail extends Omit<IQuotation, 'contact'>, IContact {}

const QuotationDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const quotationList = useAppSelector(getQuotationList);
  const eventTypeList = useAppSelector(getEventTypes);
  const extraServicesList = useAppSelector(getExtraServices);
  const menuList = useAppSelector(getMenus);
  const token = useAppSelector(getAuthenticatedToken);
  const { id } = useParams();
  const [quotation] = quotationList.filter(({ _id }) => _id === id);
  const [isDeleteQuotationOpent, setIsDeleteQuotationOpent] = useState(false);

  useEffect(() => {
    dispatch(fetchEventTypesAsync(token));
    dispatch(fetchMenusAsync(token));
    dispatch(fetchExtraServicesAsync(token));
  }, []);

  const getMenuName = (id: string) => menuList.find((menu) => menu._id === id)?.name;
  const getEventType = (id: string) => eventTypeList.find((event) => event._id === id);
  const { contact, ...rest } = quotation || {};
  const quotationMapping: IQuotationDetail = { ...contact, ...rest };
  const statusOfQuotation = Object.keys(QuotationStatus).find((key) => QuotationStatus[key] === quotation?.status);
  const { date } = toGMTDate(quotation?.dateTime);
  const servicesOfUser = quotation?.extraServices?.map((srv) => extraServicesList.find((el) => el._id === srv)?.name);
  const allowedStatus: TQuotationStatus[] = [QuotationStatus.Pendiente, QuotationStatus.Confirmado];
  const isEnableButton = allowedStatus.includes(quotation?.status);

  const handleUpdateQuotation = () => {
    const keysNotAllowed = ['createdAt', 'updatedAt', '__v', '_id', 'status'];
    const temp = removeKeys<IUpdateQuotation>(keysNotAllowed, quotation);
    const data: IUpdateQuotation = { ...temp, status: 'finalized' };
    dispatch(updateQuotationAsync({ token, quotation: data, id: quotation._id }));
  };

  return (
    <>
      <Icon data-testid="back-button-test" onClick={() => navigate(-1)} name="arrow" />
      {quotation ? (
        <ScrollBar>
          <TitleWrapperStyled>
            <Text fullWidth={false} alignment="start" type="h1">
              Detalle de cotización
            </Text>
            <StatusWrapperStyled status={statusOfQuotation}>{statusOfQuotation}</StatusWrapperStyled>
            <Button
              size="xs"
              variant="outline"
              onClick={() => setIsDeleteQuotationOpent(true)}
              style={{ marginLeft: 'auto' }}
            >
              Eliminar
            </Button>
            <Button
              data-testid="status-button-test"
              onClick={() => navigate('editar')}
              disabled={!isEnableButton}
              style={{ marginLeft: '20px' }}
              size="xs"
            >
              Editar
            </Button>
          </TitleWrapperStyled>
          <TableWrapperStyled>
            <TableStyled>
              <tbody>
                {quotationTableHeader.map((detail, i) => {
                  const { value } = detail;
                  const quotationValue = quotationMapping[value];
                  const detailValues = {
                    dateTime: transformDate(date),
                    eventTypeId: getEventType(quotationValue)?.name ?? (
                      <Icon name="warning" style={{ height: '24px' }} />
                    ),
                    menuId: getMenuName(quotationValue) ?? <Icon name="warning" style={{ height: '24px' }} />,
                    estimatedPrice: '$' + parseNumber.withMillerDot(quotationValue),
                    negotiatedPrice: '$' + parseNumber.withMillerDot(quotationValue),
                    fullName: capitalize(quotationValue ?? ''),
                    extraServices: servicesOfUser.length ? servicesOfUser.join(', ') : 'No incluye',
                  };
                  return (
                    <TableRowStyled key={i}>
                      <CellStyled>
                        <Icon style={{ verticalAlign: 'middle' }} name={detail.icon.name} />
                      </CellStyled>
                      <CellStyled>{detail.label}</CellStyled>
                      <CellStyled>{detailValues[value] || quotationValue}</CellStyled>
                    </TableRowStyled>
                  );
                })}
              </tbody>
            </TableStyled>
            <section>
              {quotation.status === 'confirmed' && (
                <Button size="xs" onClick={handleUpdateQuotation}>
                  Marcar como Finalizado
                </Button>
              )}
            </section>
          </TableWrapperStyled>
        </ScrollBar>
      ) : (
        <TableStyled>
          <tbody>
            <tr>
              <td>
                <Loading />
              </td>
            </tr>
          </tbody>
        </TableStyled>
      )}
      {isDeleteQuotationOpent && id && (
        <RemoveQuotation id={id} isOpent={isDeleteQuotationOpent} setIsOpent={setIsDeleteQuotationOpent} />
      )}
    </>
  );
};

export default QuotationDetail;
