import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PrivateLayout from '../Layout/AdminLayout';

interface RouterProps {
  isAuthenticated: boolean;
  redirectPath?: string;
}

const PrivateRoute = ({ isAuthenticated, redirectPath = '/login' }: RouterProps): ReactElement => {
  return isAuthenticated ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to={redirectPath} />
  );
};

export default PrivateRoute;
