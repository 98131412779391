import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { fieldSchema } from './schemas';
import { StyledButtons } from './styles';

interface ICreateMenuProps {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
}

const CreateMenu = ({ isOpent, setIsOpent }: ICreateMenuProps) => {
  const navigate = useNavigate();
  const { fields, errors, onChangeField, onSubmit, isValidForm } = useForm({ fieldSchema, validateOnInit: true });

  const onSend = () => onSubmit() && navigate(`create/${fields?.name}`);

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="xs" isStatic>
      <Text type="h2">Nuevo menú</Text>
      <Input
        value={fields.name}
        onChange={(val) => onChangeField({ name: 'name', value: val })}
        type="Name"
        label="Nombre del menú"
        placeholder="Escribe el nombre del menú"
        data-testid="name-input"
        labelColor="selected"
        error={errors?.name?.error}
        messageError={errors?.name?.message}
        border
        autoFocus
      />
      <StyledButtons>
        <Button data-testid="add-button" size="xs" onClick={onSend} disabled={!isValidForm}>
          Agregar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default CreateMenu;
