import { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Table from '../../../components/MenuTable';
import { ISalad } from '../../../api/salads/models';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getSalads, getSaladListStatus } from '../../../redux/saladSlice/index';
import { ColumnsProps } from '../../../components/MenuTable/index';
import CreateSalad from '../CreateSalad';
import RemoveSalad from '../../Salad/RemoveSalad';
import EditSalad from '../EditSalad';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { ButtonWrapperStyled } from '../../../components/MenuTable/styles';
import { fetchAllSaladsAsync } from '../../../redux/saladSlice/thunks';
import ProductItem from '../../../components/ProductItem';
import { getAuthenticatedToken } from '../../../redux/auth';
import { ButtonContainer, ContainerScroll, MenuTitleStyled } from './styles';
import { useWindowSize } from '../../../hooks/useWindowSize';

const SaladList = (): JSX.Element => {
  const [selectedSalad, setSelectedSalad] = useState<ISalad>();
  const [isOpent, setIsOpent] = useState(false);
  const [isRemoveModalOpent, setIsRemoveModalOpent] = useState(false);
  const [isCreateSaladOpent, setIsCreateSaladOpent] = useState(false);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const saladList: ISalad[] = useAppSelector(getSalads);
  const status = useAppSelector(getSaladListStatus);
  const token = useAppSelector(getAuthenticatedToken);

  const onClickUpdate = (value) => {
    setSelectedSalad(value);
    setIsOpent(true);
  };

  const onClickDelete = () => {
    setIsOpent(false);
    setIsRemoveModalOpent(true);
    setIsCreateSaladOpent(false);
  };

  const columnsConfig: ColumnsProps[] = [
    {
      name: 'Ensalada',
      dataIndex: 'name',
      component: (row) => <ProductItem product={row} />,
      width: '30%',
      minWidth: '40%',
    },
    {
      name: 'Descripción',
      dataIndex: 'description',
      width: '50%',
      display: width && width > 768 ? 'flex' : 'none',
    },
    {
      name: '',
      dataIndex: '',
      width: '15%',
      minWidth: '10%',
      component: (row) => (
        <ButtonWrapperStyled>
          <Button variant="outline" size="xs" onClick={() => onClickUpdate(row)}>
            Editar
          </Button>
        </ButtonWrapperStyled>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllSaladsAsync(token));
  }, []);

  return (
    <>
      <MenuTitleStyled>
        <Text fullWidth={false} type="h1">
          Ensaladas
        </Text>
        <ButtonContainer>
          <Button data-testid="button-test" size="xs" variant="default" onClick={() => setIsCreateSaladOpent(true)}>
            Crear Nuevo
          </Button>
        </ButtonContainer>
      </MenuTitleStyled>
      <ContainerScroll>
        <ScrollBar>
          <Table
            loading={status == 'loading' ? true : false}
            data={saladList}
            columns={columnsConfig}
            disabledColumns={width && width > 768 ? [] : [1]}
          />
        </ScrollBar>
      </ContainerScroll>
      {selectedSalad && (
        <>
          <EditSalad
            isOpent={isOpent}
            setIsOpent={setIsOpent}
            selectedSalad={selectedSalad}
            onClickDelete={onClickDelete}
          />
          <RemoveSalad selectedSalad={selectedSalad} setIsOpent={setIsRemoveModalOpent} isOpent={isRemoveModalOpent} />
        </>
      )}

      {isCreateSaladOpent && (
        <CreateSalad isOpent={isCreateSaladOpent} setIsOpent={setIsCreateSaladOpent} onClickDelete={onClickDelete} />
      )}
    </>
  );
};

export default SaladList;
