import { IResetPasswordProps } from '../../../../models/IAuth';
import { IFormSchema } from '../../../../models/ISchema';

export const schema: IFormSchema<IResetPasswordProps> = {
  newPassword: {
    initialValue: '',
    rules: {
      required: true,
      minLength: 6,
      maxLength: 20,
    },
  },
  newPasswordConfirmation: {
    initialValue: '',
    rules: {
      required: true,
      minLength: 6,
      maxLength: 20,
    },
  },
  code: {
    initialValue: '',
  },
};
