import Icon from '../Icon';
import { CheckedBox, CheckMark } from './styles';

type TBorderColor = 'principal' | 'texts' | 'background' | 'alternative' | 'card' | 'active';

export type CheckBoxProps = {
  onClick?: (e: any) => void;
  value?: boolean;
  size?: 'normal' | 'small';
  borderColor?: TBorderColor;
};

export const defaultProps: CheckBoxProps = {
  value: false,
  size: 'normal',
};

const CheckBox = ({ ...otherProps }: CheckBoxProps) => {
  return (
    <CheckedBox {...otherProps}>
      <CheckMark value={otherProps.value}>
        <Icon name="check" />
      </CheckMark>
    </CheckedBox>
  );
};

CheckBox.defaultProps = defaultProps;
export default CheckBox;
