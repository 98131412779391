import styled from 'styled-components';
import Modal from '../../../../components/Modal';

export const AlertNotificationStyled = styled(Modal)`
  > div {
    height: auto;

    img + div {
      padding: 12px 0;
    }

    section {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      gap: 12px;
      padding-top: 12px;
    }
  }
`;

export const CheckboxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
`;
