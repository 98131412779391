/* istanbul ignore file */

import { IFetchOptions } from '../../../models/IFetch';

const URL = process.env.REACT_APP_API_URL;
const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

const uploadHeader = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
});

export default {
  getAllDesserts: (token: string): IFetchOptions => ({
    url: `${URL}/postres/all/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  getDessertById: (dessertId: string, token: string): IFetchOptions => ({
    url: `${URL}/postres/${dessertId}/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  postDessert: (dessert: FormData, token: string): IFetchOptions => ({
    url: `${URL}/postres/`,
    params: {
      method: 'POST',
      headers: uploadHeader(token),
      body: dessert,
    },
  }),
  putDessert: (dessertId: string, dessert: FormData, token: string): IFetchOptions => ({
    url: `${URL}/postres/${dessertId}/`,
    params: {
      method: 'PUT',
      headers: uploadHeader(token),
      body: dessert,
    },
  }),
  deleteDessert: (dessertId: string, token: string): IFetchOptions => ({
    url: `${URL}/postres/${dessertId}/`,
    params: {
      method: 'DELETE',
      headers: headers(token),
    },
  }),
};
