import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IEventTypesState } from './models';
import { fetchEventTypesAsync } from './thunks';

export const initialState: IEventTypesState = {
  status: 'idle',
  eventTypes: [],
};

export const eventTypesSlice = createSlice({
  name: 'eventTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchEventTypesAsync
      .addCase(fetchEventTypesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventTypesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.eventTypes = action.payload;
      })
      .addCase(fetchEventTypesAsync.rejected, (state) => {
        state.status = 'failed';
        state.eventTypes = [];
      });
  },
});

export const getEventTypes = (state: RootState) => state.eventTypes.eventTypes;
export const getEventTypesStatus = (state: RootState) => state.eventTypes.status;

export default eventTypesSlice.reducer;
