import styled from 'styled-components';

export const ScrollContainerStyled = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  padding-right: 0.5vw;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #222222;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #eb9f1a;
  }
`;
