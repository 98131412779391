import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import DessertReducer from '../redux/dessertSlice';
import DisabledDateReducer from '../redux/disabledDateSlice';
import EventTypesReducer from '../redux/eventTypeSlice';
import ExtraServiceReducer from '../redux/extraServiceSlice';
import GalleryReducer from '../redux/gallerySlice';
import MenuReducer from '../redux/menuSlice';
import QuotationReducer from '../redux/quotationSlice';
import SaladReducer from '../redux/saladSlice';
import ScheduleReducer from '../redux/scheduleSlice';

import AuthReducer from '../redux/auth';
import { RecursivePartial } from '../utils/recursivePartialType';

export const rootReducer = combineReducers({
  auth: AuthReducer,
  menus: MenuReducer,
  quotations: QuotationReducer,
  desserts: DessertReducer,
  salads: SaladReducer,
  extraServices: ExtraServiceReducer,
  gallery: GalleryReducer,
  eventTypes: EventTypesReducer,
  schedule: ScheduleReducer,
  disabledDates: DisabledDateReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type InitialRootState = RecursivePartial<RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
