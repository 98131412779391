import React from 'react';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import { StyledButtonsContainer } from './styles';
import { ISalad } from '../../../api/salads/models';
import { getAuthenticatedToken } from '../../../redux/auth/index';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteSaladAsync } from '../../../redux/saladSlice/thunks';

interface IRemoveSaladProps {
  selectedSalad?: ISalad;
  isOpent: boolean;
  setIsOpent: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveSalad = ({ isOpent, setIsOpent, selectedSalad }: IRemoveSaladProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);

  const onDelete = () => {
    /* Fetch Send to Api */
    selectedSalad?._id && dispatch(deleteSaladAsync({ saladId: selectedSalad._id, token }));
    setIsOpent(false);
  };

  const onCancel = () => setIsOpent(false);

  return (
    <Modal size="md" isOpent={isOpent} setIsOpent={setIsOpent} data-testid="remove-salad-modal">
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar este postre de la lista?</Text>
      <StyledButtonsContainer>
        <Button data-testid="close-test" size="xs" variant="outline" onClick={() => onCancel()}>
          Cancelar
        </Button>
        <Button data-testid="button-test-delete" size="xs" onClick={() => onDelete()}>
          Eliminar
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default RemoveSalad;
