import styled from 'styled-components';
import { theme } from '../../../../theme';

export const PageContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 30px;
  min-width: 300px;
  background: ${theme.text.color.card};
  border-radius: 15px;
`;

export const InputContainer = styled.div``;
