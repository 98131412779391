import { IDessert } from '../../models/IDessert';

export const dessert: IDessert[] = [
  {
    _id: '1',
    name: 'Maracuyá',
    description: 'Viscoso yogurt, leche condensada, crema y maracuyá',
    img_url: 'image',
    isActive: true,
  },
  {
    _id: '2',
    name: 'Mouse de limón',
    description: 'Galletitas, leche batida, jugo de limón natural y leche condensada',
    img_url: 'image',
    isActive: true,
  },
  {
    _id: '3',
    name: 'Cheesecake de oreo',
    description: 'Muchos tocitos de oreo, mezcla de crema y queso',
    img_url: 'image',
    isActive: false,
  },
  {
    _id: '4',
    name: 'Cheesecake de frutos rojos',
    description: '2 capas de galletitas, crema, leche, queso crema y salsa de frutos rojo',
    img_url: 'image',
    isActive: true,
  },
];
