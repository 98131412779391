import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMenuState } from './models';
import { RootState } from '../../store';
import { createMenuAsync, deleteMenuAsync, fetchMenusAsync, updateMenuAsync } from './thunks';

export const initialState: IMenuState = {
  status: 'idle',
  menus: [],
};

export const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    updateMenu: (state, action: PayloadAction<IMenuState['menus']>) => {
      state.menus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenusAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMenusAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.menus = action.payload;
      })
      .addCase(fetchMenusAsync.rejected, (state) => {
        state.status = 'failed';
        state.menus = [];
      })
      .addCase(createMenuAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createMenuAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.menus = action.payload.list;
      })
      .addCase(createMenuAsync.rejected, (state) => {
        state.status = 'failed';
        state.menus = [];
      })
      .addCase(updateMenuAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMenuAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.menus = action.payload.list;
      })
      .addCase(updateMenuAsync.rejected, (state) => {
        state.status = 'failed';
        state.menus = [];
      })
      .addCase(deleteMenuAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMenuAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.menus = action.payload;
      })
      .addCase(deleteMenuAsync.rejected, (state) => {
        state.status = 'failed';
        state.menus = [];
      });
  },
});

export const { updateMenu } = menuSlice.actions;

export const getMenus = (state: RootState) => state.menus.menus;
export const getMenusStatus = (state: RootState) => state.menus.status;
export const getMenuById = (state: RootState, id?: string) => state.menus.menus.find((menu) => menu._id === id);

export default menuSlice.reducer;
