import styled from 'styled-components';
import { theme, handleColorType } from '../../../../theme';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const MenuTitleStyled = styled.div`
  display: flex;
  padding: 0 1vw 1vh 0;
  align-items: center;
  justify-content: space-between;
  ${mediaQuery.mobileToDesktop(`
    padding: 0 0vw 1vh 0;
  `)}
`;

export const ListContainer = styled.div`
  width: 100%;
  align-items: center;
  background: ${theme.text.color.card};
  color: ${theme.text.color.texts};
`;

export const ListHeader = styled.div`
  display: none;
  ${mediaQuery.tabletToUp(`
    display: flex;
    padding: 15px;
  `)}
  ${mediaQuery.laptopOnly(`
    display: flex;
    padding: 15px;
  `)}
  ${mediaQuery.desktopToUp(`
    display: flex;
    padding: 15px;
  `)}
`;

export const ListHeaderColumn = styled.div`
  width: 20%;
`;

export const ListBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #4b4b4b;
`;

export const ListBodyColumn = styled.div`
  width: 50%;
  ${mediaQuery.tabletToUp(`
    display: none;
  `)}
  ${mediaQuery.laptopOnly(`
    display: none;
  `)}
  ${mediaQuery.desktopToUp(`
    display: none;
  `)}
`;

export const ListBodyValue = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  color: ${({ status }: any) => handleColorType(status)};
  ${mediaQuery.tabletToUp(`
    width: 20%;
  `)}
  ${mediaQuery.laptopOnly(`
    width: 20%;
  `)}
  ${mediaQuery.desktopToUp(`
    width: 20%;
  `)}
`;

export const ListBodyButtonContainer = styled.div`
  display: flex;
  width: 50%;
  margin-top: 10px;
  ${mediaQuery.tabletToUp(`
    justify-content: flex-end;
    width: 20%;
    margin-top: 0;
  `)}
  ${mediaQuery.laptopOnly(`
    justify-content: flex-end;
    width: 20%;
    margin-top: 0;
  `)}
  ${mediaQuery.desktopToUp(`
    justify-content: flex-end;
    width: 20%;
    margin-top: 0;
  `)}
`;
