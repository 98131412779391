import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createExtraService,
  deleteExtraService,
  fetchAllExtraServices,
  updateExtraService,
} from '../../../api/extraServices';
import { ICreateExtraService } from '../../../api/extraServices/models';

export interface ICreateExtraServiceParams {
  token: string;
  extraService: ICreateExtraService;
}

export interface IUpdateExtraServiceParams extends ICreateExtraServiceParams {
  id: string;
}

export type IDeleteExtraServiceParams = {
  token: string;
  id: string;
};

export const fetchExtraServicesAsync = createAsyncThunk(
  'extraServices/fetch',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await fetchAllExtraServices(token);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createExtraServicesAsync = createAsyncThunk(
  'extraServices/create',
  async ({ token, extraService }: ICreateExtraServiceParams, { rejectWithValue }) => {
    try {
      const response = await createExtraService(token, extraService);
      if (response.statusCode !== 201) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateExtraServicesAsync = createAsyncThunk(
  'extraServices/update',
  async ({ token, id, extraService }: IUpdateExtraServiceParams, { rejectWithValue }) => {
    try {
      const response = await updateExtraService(token, id, extraService);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteExtraServicesAsync = createAsyncThunk(
  'extraServices/delete',
  async ({ token, id }: IDeleteExtraServiceParams, { rejectWithValue }) => {
    try {
      const response = await deleteExtraService(token, id);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
