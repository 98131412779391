/* istanbul ignore file */
import { IFetchOptions } from '../../../models/IFetch';
import { IFetchPaginatedQuotations } from '../../../redux/quotationSlice/thunks';
import { ICreateQuotation, IUpdateQuotation } from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

const uploadHeader = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
});

export default {
  getSchedule: (token: string): IFetchOptions => ({
    url: `${API_URL}/cotizaciones/agenda/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  getPaginatedQuotations: ({ token, ...filters }: IFetchPaginatedQuotations): IFetchOptions => {
    const url = new URL(`${API_URL}/cotizaciones/`);
    url.search = new URLSearchParams({ ...filters, limit: '50' }).toString();
    return {
      url: url.toString(),
      params: {
        method: 'GET',
        headers: headers(token),
      },
    };
  },
  getAllQuotations: (token: string, update: string): IFetchOptions => {
    const url = new URL(`${API_URL}/cotizaciones/all/`);
    url.search = new URLSearchParams({ update }).toString();
    return {
      url: url.toString(),
      params: {
        method: 'GET',
        headers: headers(token),
      },
    };
  },
  postQuotation: (token: string, quotation: ICreateQuotation): IFetchOptions => ({
    url: `${API_URL}/cotizaciones/`,
    params: {
      method: 'POST',
      headers: headers(token),
      body: JSON.stringify(quotation),
    },
  }),
  putQuotation: (token: string, quotation: IUpdateQuotation, quotationId: string): IFetchOptions => ({
    url: `${API_URL}/cotizaciones/${quotationId}/`,
    params: {
      method: 'PUT',
      headers: headers(token),
      body: JSON.stringify(quotation),
    },
  }),
  deleteQuotation: (quotationId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/cotizaciones/${quotationId}/`,
    params: {
      method: 'DELETE',
      headers: uploadHeader(token),
    },
  }),
  nofityConfirmationQuotation: (quotationId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/cotizaciones/${quotationId}/confirmar`,
    params: {
      method: 'GET',
      headers: uploadHeader(token),
    },
  }),
  getDisabledDates: (token: string): IFetchOptions => ({
    url: `${API_URL}/disabled-dates/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  postDisabledDate: (date: string, token: string): IFetchOptions => ({
    url: `${API_URL}/disabled-dates/`,
    params: {
      method: 'POST',
      headers: headers(token),
      body: JSON.stringify({ date }),
    },
  }),
  deleteDisabledDate: (date: string, token: string): IFetchOptions => ({
    url: `${API_URL}/disabled-dates/${date}/`,
    params: {
      method: 'DELETE',
      headers: headers(token),
    },
  }),
};
