import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreatedSaladsResponse,
  IFetchedSaladResponse,
  IFetchedSaladsResponse,
  IUpdatedSaladResponse,
  ISalad,
} from '../../../api/salads/models';
import { fetchAllSalads, fetchSaladById, postSalad, putSalad, deleteSalad } from '../../../api/salads';
import { IFetchResponse } from '../../../models/IFetch';

interface TokenParam {
  token: string;
}
interface SaladIdParams extends TokenParam {
  saladId: string;
}

interface SaladPostParams extends TokenParam {
  salad: any;
}

interface SaladPutParams extends TokenParam {
  saladId: string;
  salad: any;
}

export const fetchAllSaladsAsync = createAsyncThunk('salads/fetch', async (token: string, { rejectWithValue }) => {
  try {
    const data: IFetchedSaladsResponse = await fetchAllSalads(token);
    if (data.statusCode !== 200) throw new Error(data.message);
    return data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

export const fetchSaladByIdAsync = createAsyncThunk(
  'salad/fetch',
  async ({ saladId, token }: SaladIdParams, { rejectWithValue }) => {
    try {
      const data: IFetchResponse<ISalad> = await fetchSaladById(saladId, token);
      if (data.statusCode !== 200) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const postSaladAsync = createAsyncThunk(
  'salad/create',
  async ({ salad, token }: SaladPostParams, { rejectWithValue }) => {
    try {
      const data: ICreatedSaladsResponse = await postSalad(salad, token);
      if (data.statusCode !== 201) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const putSaladAsync = createAsyncThunk(
  'salad/put',
  async ({ saladId, salad, token }: SaladPutParams, { rejectWithValue }) => {
    try {
      const data: IUpdatedSaladResponse = await putSalad(saladId, salad, token);
      if (data.statusCode !== 200) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteSaladAsync = createAsyncThunk(
  'salad/delete',
  async ({ saladId, token }: SaladIdParams, { rejectWithValue }) => {
    try {
      const data: IFetchedSaladResponse = await deleteSalad(saladId, token);
      if (data.statusCode !== 200) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
