import { Link, useLocation } from 'react-router-dom';
import Icon, { IconProps } from '../../components/Icon';
import { IconWrapper, OptionContainer, OptionWrapperStyled, SideBarButton, SideBarStyled } from './styles';

export interface ISideBarOption {
  label: string;
  path: string;
  iconActive: IconProps;
  iconInactive: IconProps;
}
interface ISideBarProps {
  showSidebar: boolean;
  onClose: () => void;
}

const SideBar = ({ showSidebar, onClose }: ISideBarProps) => {
  const options: ISideBarOption[] = [
    {
      label: 'Inicio',
      path: '/',
      iconActive: { name: 'home' },
      iconInactive: { name: 'homeInactive' },
    },
    {
      label: 'Cotizaciones',
      path: '/cotizaciones',
      iconActive: { name: 'businessActive' },
      iconInactive: { name: 'business' },
    },
    {
      label: 'Agenda',
      path: '/agenda',
      iconActive: { name: 'calendar' },
      iconInactive: { name: 'calendarInactive' },
    },
    {
      label: 'Gestor de menús',
      path: '/menus',
      iconActive: { name: 'fork' },
      iconInactive: { name: 'forkInactive' },
    },
    {
      label: 'Postres',
      path: '/postres',
      iconActive: { name: 'iceCreamActive' },
      iconInactive: { name: 'iceCream' },
    },
    {
      label: 'Ensaladas',
      path: '/ensaladas',
      iconActive: { name: 'saladActive' },
      iconInactive: { name: 'salad' },
    },
    {
      label: 'Galería',
      path: '/galeria',
      iconActive: { name: 'imagesActive' },
      iconInactive: { name: 'images' },
    },
    {
      label: 'Servicios extra',
      path: '/servicios',
      iconActive: { name: 'wheelActive' },
      iconInactive: { name: 'wheel' },
    },
  ];
  const location = useLocation();
  return (
    <SideBarStyled visible={showSidebar} data-testid="sideBar">
      <OptionContainer>
        {options.map(({ label, path, iconActive, iconInactive }, i) => (
          <Link key={i} to={path} style={{ textDecoration: 'none' }}>
            <OptionWrapperStyled data-testid="sideBar-option" onClick={onClose}>
              <IconWrapper>
                <Icon
                  name={
                    path.length === 1
                      ? location.pathname === path
                        ? iconActive.name
                        : iconInactive.name
                      : location.pathname.includes(path)
                      ? iconActive.name
                      : iconInactive.name
                  }
                />
              </IconWrapper>
              <SideBarButton
                isActive={path.length === 1 ? location.pathname === path : location.pathname.includes(path)}
              >
                {label}
              </SideBarButton>
            </OptionWrapperStyled>
          </Link>
        ))}
      </OptionContainer>
    </SideBarStyled>
  );
};

export default SideBar;
