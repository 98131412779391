/* istanbul ignore file */
import URL from './url';
import { ISalad } from './models';
import { IFetchResponse } from '../../models/IFetch';

interface ISaladCreateForm {
  name: string;
  description?: string;
  file: File;
  isActive: string;
}

interface ISaladPutForm {
  name?: string;
  description?: string;
  file?: File | undefined;
  isActive?: string;
}

export const fetchAllSalads = async (token: string): Promise<IFetchResponse<ISalad[]>> => {
  const { url, params } = URL.getAllSalads(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const fetchSaladById = async (saladId: string, token: string): Promise<IFetchResponse<ISalad>> => {
  const { url, params } = URL.getSaladsById(saladId, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const postSalad = async (
  salad: ISaladCreateForm,
  token: string
): Promise<IFetchResponse<{ created: ISalad; list: ISalad[] }>> => {
  const formData = new FormData();

  formData.append('name', salad.name);
  salad.description && formData.append('description', salad.description);
  formData.append('file', salad.file);
  formData.append('isActive', salad.isActive);
  const { url, params } = URL.postSalad(formData, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const putSalad = async (
  saladId: string,
  salad: ISaladPutForm,
  token: string
): Promise<IFetchResponse<{ updated: ISalad; list: ISalad[] }>> => {
  const formData = new FormData();

  salad?.name && formData.append('name', salad.name);
  salad?.description && formData.append('description', salad.description);
  salad?.file && formData.append('file', salad.file);
  salad?.isActive && formData.append('isActive', salad.isActive);
  const { url, params } = URL.putSalad(saladId, formData, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const deleteSalad = async (saladId: string, token: string): Promise<IFetchResponse<ISalad[]>> => {
  const { url, params } = URL.deleteSalad(saladId, token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
