import React, { InputHTMLAttributes } from 'react';

import { IconContainer, Input as StyledInput, InputContainer, Label, MessageError } from '../Input/style';

export type TState = { campo: string; valido: boolean };

export interface InputProps extends Omit<InputHTMLAttributes<unknown>, 'size' | 'onChange'> {
  onClick?: () => void;
  onChange?: (value: string) => void;
  outline?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  type?: string;
  label?: string;
  messageError?: string;
  expresionRegular?: RegExp;
  error?: boolean;
  validateInput?: () => void;
  iconRight?: React.ReactChild;
  border?: boolean;
  labelColor?: 'default' | 'selected' | 'error';
  isDisabled?: boolean;
}

export type MessageErrorProps = Pick<InputProps, 'error'>;

export const defaultProps: Omit<InputProps, 'onChange' | 'setError'> = {
  outline: false,
  value: '',
  fullWidth: false,
  style: {},
  type: '',
  label: '',
  messageError: '',
  expresionRegular: /[a-zA-Z0-9_.+-]/,
  error: false,
  placeholder: '',
  isDisabled: false,
};

const Input = ({
  onChange,
  type,
  label,
  messageError,
  error,
  iconRight,
  validateInput,
  value,
  labelColor,
  isDisabled,
  ...otherProps
}: InputProps) => {
  const onValidateInput = () => {
    validateInput && validateInput();
  };

  const onChangeInput = (value: string) => {
    onChange && onChange(value);
    if (error) onValidateInput();
  };

  return (
    <>
      <Label color={labelColor} isDisabled={isDisabled}>
        {label}
      </Label>
      <InputContainer error={error} value={value} isDisabled={isDisabled}>
        <StyledInput
          type={type}
          onChange={(e) => onChangeInput(e.target.value)}
          onBlur={() => onValidateInput()}
          value={value}
          {...otherProps}
        />
        {iconRight && <IconContainer>{iconRight}</IconContainer>}
      </InputContainer>

      {error && (
        <MessageError error={error} data-testid="messageError">
          {messageError}
        </MessageError>
      )}
    </>
  );
};

Input.defaultProps = defaultProps;

export default Input;
