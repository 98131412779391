export const getFieldSchema = <T, U>({ fieldSchema, fieldValues }: { fieldSchema: T; fieldValues: U }) => {
  const schema = Object.entries(fieldSchema).reduce(
    (acc, [field, value]) => ({
      ...acc,
      [field]: { ...value, initialValue: fieldValues[field] ?? value.initialValue },
    }),
    Object.create({}) as T
  );

  return schema;
};
