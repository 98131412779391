import React, { TextareaHTMLAttributes } from 'react';
import { StyledTextArea, Label } from './styles';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  type?: string;
  placeholder?: string;
}

const defaultProps: TextAreaProps = {
  label: '',
  type: '',
  placeholder: '',
};

const TextArea = ({ ...props }: TextAreaProps) => {
  return (
    <>
      <Label>{props.label}</Label>
      <StyledTextArea {...props} />
    </>
  );
};

TextArea.defaultProps = defaultProps;
export default TextArea;
