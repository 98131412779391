import styled from 'styled-components';
import { theme } from '../../../../theme';

export const GalleryContainer = styled.div``;

export const GalleryTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GalleryContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 250px;
  height: 250px;
  padding: 20px;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.02);
  }
`;

export const RemoveBtn = styled.button`
  position: absolute;
  top: 10%;
  right: 10%;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.text.color.principal};
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
`;
