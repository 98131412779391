import { IContact, IQuotation } from '../../../../models/IQuotation';
import { IFormSchema } from '../../../../models/ISchema';
import { expresions } from '../../../../utils/expresions/index';
interface ISchemaQuotation
  extends Omit<IQuotation, '_id' | 'contact' | 'extraServices' | 'status' | 'eventTypeId' | 'menuId' | 'dateTime'>,
    IContact {
  date: string;
  time: string;
}
export const fieldSchema: IFormSchema<ISchemaQuotation> = {
  peopleQuantity: {
    initialValue: 0,
    rules: {
      required: true,
      max: 1000000,
      positive: true,
    },
  },
  childrenQuantity: {
    initialValue: 0,
    rules: {
      max: 1000000,
      positive: true,
    },
  },
  address: {
    initialValue: '',
    rules: {
      required: true,
    },
  },
  estimatedPrice: {
    initialValue: 0,
    rules: {
      required: true,
      positive: true,
    },
  },
  negotiatedPrice: {
    initialValue: 0,
    rules: {
      positive: true,
    },
  },
  date: {
    initialValue: '',
    rules: {
      required: true,
      conditions: {
        regex: expresions.date,
        errorMessage: 'Ingrese una fecha válida',
      },
    },
  },
  time: {
    initialValue: '',
    rules: {
      required: true,
      conditions: {
        regex: expresions.time,
        errorMessage: 'Ingrese una Hora válida',
      },
    },
  },
  fullName: {
    initialValue: '',
    rules: {
      required: true,
      maxLength: 48,
    },
  },
  phone: {
    initialValue: '',
    rules: {
      conditions: {
        regex: expresions.phoneNumber,
        errorMessage: 'Ingrese un número de teléfono correctamente',
      },
    },
  },
  email: {
    initialValue: '',
    rules: {
      required: true,
      conditions: {
        regex: expresions.email,
        errorMessage: 'Ingrese un correo válido',
      },
    },
  },
};
