import { ICreateMenu } from '../../../../api/menu/models';
import { IFormSchema } from '../../../../models/ISchema';

export const fieldSchema: IFormSchema<Pick<ICreateMenu, 'name'>> = {
  name: {
    initialValue: '',
    rules: {
      required: true,
      minLength: 3,
      maxLength: 48,
    },
  },
};
