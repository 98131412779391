import styled from 'styled-components';

export const ContainerInput = styled.div`
  width: 100%;
`;

export const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;
