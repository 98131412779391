import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteDessert,
  fetchAllDesserts,
  fetchDessertById,
  IDessertCreateForm,
  IDessertPutForm,
  postDessert,
  putDessert,
} from '../../../api/desserts/index';
import {
  ICreatedDessertsResponse,
  IFetchedDessertResponse,
  IFetchedDessertsResponse,
  IUpdatedDessertResponse,
} from '../../../api/desserts/models/index';

interface TokenParam {
  token: string;
}
interface DessertIdParams extends TokenParam {
  dessertId: string;
}

interface DessertPostParams extends TokenParam {
  dessert: IDessertCreateForm;
}

interface DessertPutParams extends TokenParam {
  dessertId: string;
  dessert: IDessertPutForm;
}

export const fetchAllDessertsAsync = createAsyncThunk('desserts/fetch', async (token: string, { rejectWithValue }) => {
  try {
    const data: IFetchedDessertsResponse = await fetchAllDesserts(token);
    if (data.statusCode !== 200) throw new Error(data.message);
    return data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

export const fetchDessertByIdAsync = createAsyncThunk(
  'dessert/fetch',
  async ({ dessertId, token }: DessertIdParams, { rejectWithValue }) => {
    try {
      const data: IFetchedDessertResponse = await fetchDessertById(dessertId, token);
      if (data.statusCode !== 200) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const postDessertAsync = createAsyncThunk(
  'dessert/create',
  async ({ dessert, token }: DessertPostParams, { rejectWithValue }) => {
    try {
      const data: ICreatedDessertsResponse = await postDessert(dessert, token);
      if (data.statusCode !== 201) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const putDessertAsync = createAsyncThunk(
  'dessert/put',
  async ({ dessertId, dessert, token }: DessertPutParams, { rejectWithValue }) => {
    try {
      const data: IUpdatedDessertResponse = await putDessert(dessertId, dessert, token);
      if (data.statusCode !== 200) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteDessertAsync = createAsyncThunk(
  'dessert/delete',
  async ({ dessertId, token }: DessertIdParams, { rejectWithValue }) => {
    try {
      const data: IFetchedDessertsResponse = await deleteDessert(dessertId, token);
      if (data.statusCode !== 200) throw new Error(data.message);
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
