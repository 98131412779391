import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEventTypes } from '../../../api/eventTypes';

export const fetchEventTypesAsync = createAsyncThunk('eventTypes/fetch', async (token: string, { rejectWithValue }) => {
  try {
    const response = await fetchEventTypes(token);
    if (response.statusCode !== 200) throw new Error(response.message);
    return response.data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});
