/* istanbul ignore file */
import { IFetchOptions } from '../../../models/IFetch';

const URL = process.env.REACT_APP_API_URL;

const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

const uploadHeader = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
});

export default {
  getGallery: (token: string): IFetchOptions => ({
    url: `${URL}/galeria/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  postGallery: (token: string, gallery: FormData): IFetchOptions => ({
    url: `${URL}/galeria/`,
    params: {
      method: 'POST',
      headers: uploadHeader(token),
      body: gallery,
    },
  }),
  removeGallery: (token: string, id: string): IFetchOptions => ({
    url: `${URL}/galeria/${id}/`,
    params: {
      method: 'DELETE',
      headers: headers(token),
    },
  }),
};
