import React from 'react';
import { ContainerText, ProductContainerStyled, StateText } from './styles';
import Image from '../../components/MenuTable/components/Image';
import Text from '../Text';
import { IDessert } from '../../models/IDessert';
import { ISalad } from '../../api/salads/models';
import { dessert } from '../../__mock__/dessert';

export type ProductItemProps = {
  value?: boolean;
  product: IDessert | ISalad;
};

export const defaulProps: ProductItemProps = {
  value: false,
  product: dessert[0],
};

const ProductItem = ({ product, ...otherProps }) => {
  return (
    <ProductContainerStyled {...otherProps}>
      <Image src={product.img_url} />
      <ContainerText>
        <Text type="body" alignment="start" textAlignment="left">
          {product.name}
        </Text>
        <StateText isActive={product.isActive}>{product.isActive ? 'Activo' : 'Desactivado'}</StateText>
      </ContainerText>
    </ProductContainerStyled>
  );
};

ProductItem.defaultProps = defaulProps;
export default ProductItem;
