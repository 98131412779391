import { TableHeaderCellStyled } from './styles';
import { ColumnsProps } from './index';

export type Headerprops = {
  columns: ColumnsProps[];
};

const TableHeader = ({ columns }: Headerprops) => (
  <>
    {columns.map((h, i) => (
      <TableHeaderCellStyled style={{ width: h.width, minWidth: h.minWidth, display: h.display }} key={i}>
        {h.name}
      </TableHeaderCellStyled>
    ))}
  </>
);
export default TableHeader;
