import styled from 'styled-components';
import { IconContainer, Input } from '../../../../components/Input/style';
import { BREAKPOINTS } from '../../../../utils/constants/breakpoints';

export const EditQuotationStyled = styled.div`
  height: 100%;
`;

export const GridTemplateStyled = styled.div<{ value }>`
  color: white;
  background: #222222;
  display: grid;
  grid-template-columns: repeat(${({ value = 0 }) => value}, 1fr);
  margin-bottom: 2vh;

  @media (max-width: ${BREAKPOINTS.tablet}px) {
    grid-template-columns: repeat(${({ value = 0 }) => value - 1}, 1fr);
  }
  @media (max-width: ${BREAKPOINTS.laptop}px) {
    grid-template-columns: repeat(${({ value = 0 }) => value - 1}, 1fr);
  }
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    grid-template-columns: repeat(${1}, 1fr);
  }
`;

export const GridCellStyled = styled.div`
  padding: 10px;
  position: relative;
  text-align: center;
  width: 90%;
  margin: 0 auto;

  ${Input}[type='date'] {
    position: relative;
    font-family: inherit;
    z-index: 9;

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 12px;
      right: 12px;
      opacity: 0;
      font-size: 20px;
      cursor: pointer;
    }
  }

  ${Input}[type='date'] + ${IconContainer} {
    position: absolute;
    right: 12px;
  }

  ${Input}[type='time'] {
    position: relative;
    font-family: inherit;
    z-index: 9;

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 12px;
      right: 12px;
      opacity: 0;
      font-size: 20px;
      cursor: pointer;
    }
  }

  ${Input}[type='time'] + ${IconContainer} {
    position: absolute;
    right: 12px;
  }
`;

export const DivWrapperStyled = styled.div<{ flexType }>`
  display: ${({ flexType }) => (flexType === 'if' ? 'inline-flex' : 'flex')};
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
  padding: 15px 0;
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  padding-bottom: 48px;
`;
