import { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { IExtraService } from '../../../models/IExtraService';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getExtraServices, getExtraServicesStatus } from '../../../redux/extraServiceSlice';
import { fetchExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { transformDate } from '../../../utils/formatDate';
import { toGMTDate } from '../../../utils/formatDateTime';
import CreateExtraService from '../CreateExtraService';
import EditExtraService from '../EditExtraService';
import RemoveExtraService from '../RemoveExtraService';
import {
  ListBody,
  ListBodyButtonContainer,
  ListBodyColumn,
  ListBodyValue,
  ListContainer,
  ListHeader,
  ListHeaderColumn,
  MenuTitleStyled,
} from './styles';

const ExtraServicesManager = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const status = useAppSelector(getExtraServicesStatus);
  const services = useAppSelector(getExtraServices);
  const [serviceSelected, setServiceSelected] = useState<IExtraService>();
  const [isCreateServiceOpent, setIsCreateServiceOpent] = useState(false);
  const [isUpdateServiceOpent, setIsUpdateServiceOpent] = useState(false);
  const [isDeleteServiceOpent, setisDeleteServiceOpent] = useState(false);

  useEffect(() => {
    dispatch(fetchExtraServicesAsync(token));
  }, []);

  const handleUpdateService = (service: IExtraService) => {
    setServiceSelected(service);
    setIsUpdateServiceOpent(true);
  };

  const handleDeleteService = () => {
    setisDeleteServiceOpent(true);
  };

  return (
    <ScrollBar>
      <MenuTitleStyled>
        <Text fullWidth={false} type="h1">
          Servicios extra
        </Text>
        <Button data-testid="button-test" size="xs" variant="default" onClick={() => setIsCreateServiceOpent(true)}>
          Crear Nuevo
        </Button>
      </MenuTitleStyled>
      <ListContainer>
        <ListHeader>
          <ListHeaderColumn>Nombre</ListHeaderColumn>
          <ListHeaderColumn>Valor</ListHeaderColumn>
          <ListHeaderColumn>Tipo Valor</ListHeaderColumn>
          <ListHeaderColumn>Última modificación</ListHeaderColumn>
        </ListHeader>
        {status == 'loading' ? (
          <Loading />
        ) : (
          services.map((service, idx) => (
            <ListBody key={idx}>
              <ListBodyColumn>Nombre</ListBodyColumn>
              <ListBodyValue>{service.name}</ListBodyValue>
              <ListBodyColumn>Valor</ListBodyColumn>
              <ListBodyValue>${service.price.toLocaleString('es-CL')}</ListBodyValue>
              <ListBodyColumn>Tipo Valor</ListBodyColumn>
              <ListBodyValue>{service.pricingType}</ListBodyValue>
              <ListBodyColumn>Última modificación</ListBodyColumn>
              <ListBodyValue>{transformDate(toGMTDate(service.updatedAt).date)}</ListBodyValue>
              <ListBodyColumn />
              <ListBodyButtonContainer>
                <Button variant="outline" size="xs" onClick={() => handleUpdateService(service)}>
                  Modificar
                </Button>
              </ListBodyButtonContainer>
            </ListBody>
          ))
        )}
      </ListContainer>
      <CreateExtraService isOpent={isCreateServiceOpent} setIsOpent={setIsCreateServiceOpent} />
      {serviceSelected && (
        <>
          <EditExtraService
            extraServiceSelected={serviceSelected}
            isOpent={isUpdateServiceOpent}
            setIsOpent={setIsUpdateServiceOpent}
            onDelete={() => handleDeleteService()}
          />
          <RemoveExtraService
            extraServiceSelected={serviceSelected}
            isOpent={isDeleteServiceOpent}
            setIsOpent={setisDeleteServiceOpent}
            onDeleted={() => setIsUpdateServiceOpent(false)}
          />
        </>
      )}
    </ScrollBar>
  );
};

export default ExtraServicesManager;
