import { useState } from 'react';
import InputSelect from '../../../components/InputSelect';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { QuotationStatus } from '../../../models/IQuotation';
import { getMenus } from '../../../redux/menuSlice';
import { ActionContainer, InputContainer } from './styles';
import { useAppSelector } from '../../../store/hooks';
import Button from '../../../components/Button';
import { removeFalsyProperties } from '../../../utils/removeFalsyProperties';

const initialState = { menuId: '', status: '', month: '', year: '' };

const QuotationFilters: React.FC<QuotationFiltersProps> = ({ open, onClose, onSubmit }) => {
  const menuList = useAppSelector(getMenus);
  const [filters, setFilters] = useState<Filters>(initialState);
  const menus = menuList.filter((menu) => menu.isActive).map((menu) => menu.name);
  const status = Object.keys(QuotationStatus);
  const months = Array.from({ length: 12 }, (e, i) => ({
    name: new Date(0, i + 1, 0).toLocaleDateString('es', { month: 'long' }),
    value: i + 1,
  }));
  const monthOptions = months.map((month) => month.name);
  const years = Array.from({ length: 10 }, (e, i) => new Date().getFullYear() - i);

  const handleChange = (filter: keyof Filters, value: any) => setFilters({ ...filters, [filter]: value });

  const handleSubmit = () => {
    const monthNumber = months.find((item) => item.name === filters.month)?.value || '';
    const status = Object.entries(QuotationStatus).find((val) => val[0] === filters.status)?.[1];
    const menuId = menuList.find((menu) => menu.name === filters.menuId)?._id;
    const data = removeFalsyProperties({
      ...filters,
      month: monthNumber.toString(),
      year: filters.year?.toString(),
      status,
      menuId,
    });
    onSubmit(data);
    onClose();
  };

  return (
    <Modal isOpent={open} setIsOpent={onClose} size="md" isStatic data-testid="filtersModal">
      <Text color="principal">Filtros</Text>
      <InputContainer>
        <InputSelect
          label="Menú"
          value={filters?.menuId}
          onChange={(value) => handleChange('menuId', value)}
          options={menus}
          withDefaultOption
        />
      </InputContainer>
      <InputContainer>
        <InputSelect
          label="Estado"
          value={filters?.status}
          onChange={(value) => handleChange('status', value)}
          options={status}
          withDefaultOption
        />
      </InputContainer>
      <InputContainer>
        <InputSelect
          label="Año"
          value={filters?.year}
          onChange={(value) => handleChange('year', value)}
          options={years}
          withDefaultOption
        />
      </InputContainer>
      <InputContainer>
        <InputSelect
          label="Mes"
          value={filters?.month}
          onChange={(value) => handleChange('month', value)}
          options={monthOptions}
          withDefaultOption
        />
      </InputContainer>
      <ActionContainer>
        <Button variant="black" size="xs" onClick={() => setFilters(initialState)}>
          Limpiar
        </Button>
        <Button size="xs" onClick={handleSubmit}>
          Filtrar
        </Button>
      </ActionContainer>
    </Modal>
  );
};

interface QuotationFiltersProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (filters: Filters) => void;
}

export interface Filters {
  menuId?: string;
  year?: string;
  month?: string;
  status?: string;
}

export default QuotationFilters;
