import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createQuotation,
  deleteQuotation,
  fetchAllQuotations,
  fetchPaginatedQuotations,
  notifyConfirmationQuotation,
  updateQuotation,
} from '../../../api/quotations';
import { ICreateQuotation, IUpdateQuotation } from '../../../api/quotations/models';

interface ITokenParam {
  token: string;
}

export interface IAllQuotationsParams {
  token: string;
  update?: string;
}

interface ICreateQuotationParams extends ITokenParam {
  quotation: ICreateQuotation;
}

interface IUpdateQuotationParams extends ITokenParam {
  quotation: IUpdateQuotation;
  id: string;
}

interface IDeleteQuotationParams extends ITokenParam {
  id: string;
}

export interface IFetchPaginatedQuotations {
  page?: string;
  limit?: string;
  menuId?: string;
  status?: string;
  month?: string;
  year?: string;
  token: string;
}

export const fetchQuotationsAsync = createAsyncThunk(
  'quotation/fetch',
  async (data: IFetchPaginatedQuotations, { rejectWithValue }) => {
    try {
      const response = await fetchPaginatedQuotations(data);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchAllQuotationsAsync = createAsyncThunk(
  'quotation/fetchAll',
  async ({ token, update = '0' }: IAllQuotationsParams, { rejectWithValue }) => {
    try {
      const response = await fetchAllQuotations(token, update);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createQuotationAsync = createAsyncThunk(
  'quotation/create',
  async ({ token, quotation }: ICreateQuotationParams, { rejectWithValue }) => {
    try {
      const response = await createQuotation(token, quotation);
      if (response.statusCode !== 201) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateQuotationAsync = createAsyncThunk(
  'quotation/update',
  async ({ token, quotation, id }: IUpdateQuotationParams, { rejectWithValue }) => {
    try {
      const response = await updateQuotation(token, quotation, id);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteQuotationAsync = createAsyncThunk(
  'quotation/delete',
  async ({ token, id }: IDeleteQuotationParams, { rejectWithValue }) => {
    try {
      const response = await deleteQuotation(id, token);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const notifyConfirmationAsync = createAsyncThunk(
  'quotation/confirm',
  async ({ token, id }: IDeleteQuotationParams, { rejectWithValue }) => {
    try {
      const response = await notifyConfirmationQuotation(id, token);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.message;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
