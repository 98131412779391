/* istanbul ignore file */
import { IFetchOptions } from '../../../models/IFetch';
import { ICreateExtraService, IUpdateExtraService } from '../models';

const URL = process.env.REACT_APP_API_URL;

const headers = (token: string): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export default {
  getAllExtraServices: (token: string): IFetchOptions => ({
    url: `${URL}/servicios-extra/`,
    params: {
      method: 'GET',
      headers: headers(token),
    },
  }),
  postExtraService: (token: string, body: ICreateExtraService): IFetchOptions => ({
    url: `${URL}/servicios-extra/`,
    params: {
      method: 'POST',
      headers: headers(token),
      body: JSON.stringify(body),
    },
  }),
  putExtraService: (token: string, id: string, body: IUpdateExtraService): IFetchOptions => ({
    url: `${URL}/servicios-extra/${id}/`,
    params: {
      method: 'PUT',
      headers: headers(token),
      body: JSON.stringify(body),
    },
  }),
  deleteExtraService: (token: string, id: string): IFetchOptions => ({
    url: `${URL}/servicios-extra/${id}/`,
    params: {
      method: 'DELETE',
      headers: headers(token),
    },
  }),
};
