import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IGalleryState } from './models';
import { createGalleryAsync, deleteGalleryAsync, fetchGalleryAsync } from './thunks';

const initialState: IGalleryState = {
  status: 'idle',
  list: [],
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGalleryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGalleryAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(fetchGalleryAsync.rejected, (state) => {
        state.status = 'failed';
        state.list = [];
      })
      .addCase(createGalleryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createGalleryAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload.list;
      })
      .addCase(createGalleryAsync.rejected, (state) => {
        state.status = 'failed';
        state.list = [];
      })
      .addCase(deleteGalleryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteGalleryAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(deleteGalleryAsync.rejected, (state) => {
        state.status = 'failed';
        state.list = [];
      });
  },
});

export const getGallery = (state: RootState) => state.gallery.list;
export const getGalleryStatus = (state: RootState) => state.gallery.status;

export default gallerySlice.reducer;
