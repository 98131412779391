import styled from 'styled-components';
import { CheckBoxProps } from '..';

export const CheckedBox = styled.div<CheckBoxProps>`
  min-width: ${({ size }) => (size === 'normal' ? '30px' : '18px')};
  height: ${({ size }) => (size === 'normal' ? '30px' : '18px')};
  outline: 3px solid ${({ value, borderColor }) => (borderColor || value ? '#eb9f1a' : '#ffffff')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CheckMark = styled.div<{ value?: boolean }>`
  visibility: ${({ value }) => (value ? 'visible' : 'hidden')};
`;
