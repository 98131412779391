/* istanbul ignore file */
import { IEventType } from '../../models/IEventType';
import { IFetchResponse } from '../../models/IFetch';
import URL from './url';

export const fetchEventTypes = async (token: string): Promise<IFetchResponse<IEventType[]>> => {
  const { url, params } = URL.getEventTypes(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
