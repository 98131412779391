import { useState } from 'react';
import Button from '../../../components/Button';
import Error from '../../../components/Error';
import Icon from '../../../components/Icon';
import InputComponent from '../../../components/Input';
import Logo from '../../../components/Logo';
import Spinner from '../../../components/Spinner';
import Text from '../../../components/Text';
import { getAuthenticationStatus } from '../../../redux/auth';
import { getAuthenticationError } from '../../../redux/auth/index';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import RecoveryPassword from '../RecoveryPassword';
import { fieldSchema } from './schemas';
import useForm from '../../../hooks/useForm';

import {
  ContainerImage,
  InnerContainer,
  InputContainerWrapper,
  Link,
  LoginContainer,
  LoginContainerWrapper,
  InputsContainer,
} from './styles';
import { fetchLoginAsync } from '../../../redux/auth/thunks';

const Login = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isOpent, setIsOpent] = useState(false);
  const { fields, errors, onChangeField, isValidForm } = useForm({ fieldSchema, validateOnInit: true });

  const authStatus = useAppSelector(getAuthenticationStatus);
  const authError = useAppSelector(getAuthenticationError);
  const isAuthenticating = authStatus === 'loading';

  const onLogin = () => {
    dispatch(fetchLoginAsync({ email: fields.email, password: fields.password }));
  };

  const paramsRecoveryPassword = {
    isOpent,
    setIsOpent,
    email: fields.email,
    onChangeEmail: (email) => onChangeField({ name: 'email', value: email }),
  };

  return (
    <LoginContainer>
      <LoginContainerWrapper>
        <Logo />
        <InputContainerWrapper>
          <InnerContainer>
            <Text type="h1">¡Bienvenidos!</Text>
            <Text type="h2">Inicio de sesión</Text>
            <InputsContainer>
              <InputComponent
                value={fields.email}
                onChange={(value) => {
                  onChangeField({ name: 'email', value });
                }}
                type="email"
                label="Mail"
                error={errors?.email?.error}
                messageError={errors?.email?.message}
                iconRight={<Icon name="mail" />}
                disabled={isAuthenticating}
                data-testid="emailLoginInput"
              />

              <InputComponent
                value={fields.password}
                onChange={(value) => {
                  onChangeField({ name: 'password', value });
                }}
                type={showPassword ? 'text' : 'password'}
                label="Contraseña"
                error={errors?.password?.error}
                messageError={errors?.password?.message}
                iconRight={
                  !fields.password ? (
                    <Icon name="key" />
                  ) : (
                    <Icon
                      name={!showPassword ? 'eyeClosed' : 'eye'}
                      onClick={() => setShowPassword(!showPassword)}
                      data-testid="showPasswordBtn"
                    />
                  )
                }
                disabled={isAuthenticating}
                data-testid="passLoginInput"
              />
            </InputsContainer>

            <Button onClick={() => onLogin()} fullWidth disabled={!isValidForm} data-testid="btnSubmitLogin">
              Iniciar {isAuthenticating && <Spinner />}
            </Button>

            <Link data-testid="LinkRecovery" onClick={() => setIsOpent(true)}>
              ¿Olvidaste tu contraseña?
            </Link>
            {authError && <Error message={authError} data-testid="errorMessage" />}
          </InnerContainer>
        </InputContainerWrapper>
      </LoginContainerWrapper>
      {isOpent && <RecoveryPassword {...paramsRecoveryPassword} />}
      <ContainerImage />
    </LoginContainer>
  );
};

export default Login;
