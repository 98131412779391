import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IExtraServiceState } from './models';
import {
  createExtraServicesAsync,
  deleteExtraServicesAsync,
  fetchExtraServicesAsync,
  updateExtraServicesAsync,
} from './thunks';

const initialState: IExtraServiceState = {
  status: 'idle',
  extraServices: [],
};

export const extraServiceSlice = createSlice({
  name: 'extraServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchExtraServicesAsync
      .addCase(fetchExtraServicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExtraServicesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.extraServices = action.payload;
      })
      .addCase(fetchExtraServicesAsync.rejected, (state) => {
        state.status = 'failed';
        state.extraServices = [];
      })
      //createExtraServicesAsync
      .addCase(createExtraServicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createExtraServicesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.extraServices = action.payload.list;
      })
      .addCase(createExtraServicesAsync.rejected, (state) => {
        state.status = 'failed';
        state.extraServices = [];
      })
      //updateExtraServicesAsync
      .addCase(updateExtraServicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateExtraServicesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.extraServices = action.payload.list;
      })
      .addCase(updateExtraServicesAsync.rejected, (state) => {
        state.status = 'failed';
        state.extraServices = [];
      })
      //deleteExtraServicesAsync
      .addCase(deleteExtraServicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteExtraServicesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.extraServices = action.payload;
      })
      .addCase(deleteExtraServicesAsync.rejected, (state) => {
        state.status = 'failed';
        state.extraServices = [];
      });
  },
});

export const getExtraServices = (state: RootState) => state.extraServices.extraServices;
export const getExtraServicesStatus = (state: RootState) => state.extraServices.status;

export default extraServiceSlice.reducer;
