import { ReactElement } from 'react';
import { MessageError } from './style';

interface Props {
  message: string;
}

const Error = ({ message }: Props): ReactElement => {
  return <MessageError data-testid="errorMessage">{message}</MessageError>;
};

export default Error;
