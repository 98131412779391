import { ChangeEvent } from 'react';
import { Input, Label, Span } from './styles';

export interface CheckboxProps {
  onClick?: () => void;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  checked?: boolean;
}

export const defaultProps: Omit<CheckboxProps, 'onChange' | 'onClick'> = {
  fullWidth: false,
  style: {},
  checked: false,
};

const CheckBox = ({ onChange, checked, ...otherProps }: CheckboxProps) => {
  const onChangeCheckbox = (value) => {
    onChange && onChange(value);
  };

  return (
    <Label data-testid="switch-check">
      <Input type="checkbox" checked={checked} onChange={(e) => onChangeCheckbox(e)} {...otherProps} />
      <Span></Span>
    </Label>
  );
};

CheckBox.defaultProps = defaultProps;

export default CheckBox;
