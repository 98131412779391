import styled from 'styled-components';

export const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const ContainerInput = styled.div`
  margin-top: 10px;
  width: 100%;
`;
