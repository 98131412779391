import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { ContainerInput, StyledButtons } from './styles';

interface ICreateSectionItemProps {
  isOpent: boolean;
  title: string;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  onSend: (name: string[]) => void;
}

const CreateSectionItem = ({ isOpent, setIsOpent, onSend, title }: ICreateSectionItemProps) => {
  const [items, setItems] = useState<string[]>(['']);
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    validateItems();
  }, [items]);

  const validateItems = () => {
    const errorItem = items.every((item) => item && item.length >= 3);
    !errorItem ? setHasError(true) : setHasError(false);
  };

  const onChangeItem = (val: string, idx: number) => {
    const data = [...items];
    data[idx] = val;
    setItems(data);
  };

  const onAddItem = () => setItems([...items, '']);

  const onRemoveItem = (idx: number) => {
    if (items.length > 1) {
      const restItems = items.filter((_, index) => index !== idx);
      setItems(restItems);
    }
  };

  const isLastItem = (idx: number) => {
    const last = items.length - 1;

    return last === idx;
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="default" isStatic>
      <Text type="h2" alignment="start" color="principal">
        {title}
      </Text>
      <ContainerInput>
        {items.map((item, idx) => (
          <div key={idx}>
            <Input
              value={item}
              type="text"
              onChange={(val) => onChangeItem(val, idx)}
              placeholder="Añadir nombre"
              data-testid="name-input"
              border
              autoFocus
              iconRight={
                !isLastItem(idx) ? (
                  <Icon name="union" onClick={() => onRemoveItem(idx)} data-testid="remove-item-btn" />
                ) : undefined
              }
            />
          </div>
        ))}
      </ContainerInput>
      <StyledButtons>
        <Button data-testid="add-other-item-btn" size="xs" variant="black" onClick={onAddItem}>
          <Icon name="plus" />
          <Text color="principal" type="body">
            Añadir otro
          </Text>
        </Button>
        <Button data-testid="add-item-btn" size="xs" disabled={hasError} onClick={() => onSend(items)}>
          Agregar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default CreateSectionItem;
