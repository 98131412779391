import styled from 'styled-components';
import { InputProps, MessageErrorProps } from '..';
import { theme } from '../../../theme';

const colors = {
  default: theme.text.color.texts,
  selected: theme.text.color.principal,
  error: theme.text.color.alternative,
  disabled: theme.button.type.disabled.backgroundColor,
};

const Label = styled.label<InputProps>`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: ${({ color, isDisabled }) => (isDisabled ? colors.disabled : color ? colors[color] : colors.default)};
  display: flex;
  align-self: baseline;
  margin-left: 5px;
  margin-bottom: 4px;
`;

const Input = styled.input`
  display: flex;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  border: 0px;
  margin-left: 0.5rem;
  &:focus {
    outline: none;
  }
`;

const InputContainer = styled.div<InputProps>`
  display: flex;
  height: 45px;
  width: 99.7%;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  outline: none;
  border: 1px solid transparent;
  border-color: ${({ error, value, isDisabled }) =>
    isDisabled ? colors.disabled : error ? colors.error : value ? colors.selected : colors.default};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  > input {
    color: ${({ isDisabled }) => (isDisabled ? colors.disabled : colors.default)};
  }
`;

const MessageError = styled.p<MessageErrorProps>`
  font-size: 14px;
  padding: 7px;
  margin: 0;
  margin-top: 0;
  color: ${colors.error};
  opacity: ${({ error }) => (!error ? 0 : 1)};
  text-align: start;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`;

export { IconContainer, InputContainer, Label, Input, MessageError };
