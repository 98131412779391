export const bodyDisplayType = {
  type: {
    menu: {
      index: ['name', 'date', 'price', ''],
      buttonText: 'Modificar',
      pathRedirect: '/menus/detalle',
    },
    quotation: {
      index: ['date', 'status', 'name', 'menu', 'people', ''],
      buttonText: 'Ver detalle',
      pathRedirect: 'detalle',
    },
    dessert: {
      index: ['name', 'description', 'image', ''],
      buttonText: 'Modificar',
      pathRedirect: 'detalle',
    },
  },
};
