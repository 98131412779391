import styled from 'styled-components';
import { theme } from '../../../theme';
import { mediaQuery } from '../../../utils/mediaQuery';

export const StyledImage = styled.img`
  max-width: 100%;
  width: 349px;
  height: 209px;
  padding-bottom: 5px;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  width: 349px;
  height: 209px;
  position: relative;
  z-index: 1;
  input {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 7px;
  right: 8px;

  ${mediaQuery.mobileToTablet(`
    position: absolute;
    z-index: 2;
    bottom: 7px;
    right: 8px;  
  `)}

  ${mediaQuery.mobileOnly(`
    position: absolute;
    z-index: 2;
    bottom: 7px;
    right: 8px;
  `)}
`;

export const StyledIcon = styled.div`
  margin-left: 5px;
  display: flex;
`;

export const StyledBg = styled.div`
  background: #131313;
  width: 349px;
  height: 209px;
`;

export const StyledIconCamera = styled.div`
  position: absolute;
  top: 47%;
  left: 47%;
`;

export const FilesCounter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${theme.text.color.principal};
  background: rgba(0, 0, 0, 0.5);
  padding: 2px;
  border-radius: 0 0 0 3px;
`;

export const ArrowRightContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 47%;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px 0 0 5px;
`;

export const ArrowLeftContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 47%;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 5px 5px 0;
`;
