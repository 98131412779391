type Format = {
  (date: Date | number, locale: string, options?: Intl.DateTimeFormatOptions): string;
};

export const formatDate: Format = (date, locale, options = {}) => new Intl.DateTimeFormat(locale, options).format(date);

export const transformDate = (date: string): string => {
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};
