import styled from 'styled-components';

export const ContainerImage = styled.div`
  width: 44px;
  min-width: 44px;
  height: 44px;
  display: flex;
  margin-right: 10px;
`;

export const StyledImage = styled.img`
  max-width: 95%;
  object-fit: cover;
  object-position: center;
`;
