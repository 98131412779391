import { Dispatch, SetStateAction, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { ContainerInput, StyledButtons } from './styles';

interface IEditSectionProps {
  isOpent: boolean;
  sectionName: string;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  onSend: (name: string) => void;
}

const EditSection = ({ isOpent, setIsOpent, onSend, sectionName }: IEditSectionProps) => {
  const [section, setSection] = useState<string>(sectionName);

  const onChangeItem = (val: string) => {
    const data = val;
    setSection(data);
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="default" isStatic>
      <Text type="h2" alignment="start" color="principal">
        Editar sección
      </Text>
      <ContainerInput>
        <Input
          value={section}
          type="text"
          onChange={(val) => onChangeItem(val)}
          data-testid="name-input"
          border
          autoFocus
        />
      </ContainerInput>
      <StyledButtons>
        <Button data-testid="update-section-btn" size="xs" onClick={() => onSend(section)}>
          Agregar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default EditSection;
