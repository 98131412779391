import { Dispatch, SetStateAction } from 'react';
import { IExtraService } from '../../../models/IExtraService';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { getAuthenticatedToken } from '../../../redux/auth';
import { deleteExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ButtonContainer } from '../EditExtraService/styles';

type IRemoveExtraServiceProps = {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  extraServiceSelected: IExtraService;
  onDeleted: () => void;
};

const RemoveExtraService = ({ isOpent, setIsOpent, extraServiceSelected, onDeleted }: IRemoveExtraServiceProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const { _id: id } = extraServiceSelected;

  const onDelete = () => {
    dispatch(deleteExtraServicesAsync({ token, id }));
    setIsOpent(false);
    onDeleted();
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="md" isStatic>
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar este servicio?</Text>
      <ButtonContainer>
        <Button data-testid="remove-extra-service-cancel" size="xs" variant="outline" onClick={() => setIsOpent(false)}>
          Cancelar
        </Button>
        <Button data-testid="remove-extra-service-delete" size="xs" onClick={onDelete}>
          Eliminar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default RemoveExtraService;
