import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import CheckBox from '../../../components/MenuTable/components/CheckBox';
import Text from '../../../components/Text';
import { alerts, INotificationButton } from './constants';
import { AlertNotificationStyled, CheckboxStyled } from './styles';

export type AlertNotificationProps = {
  isOpent: boolean;
  type: string;
  onClose: () => void;
  handleClick?: () => void;
  hasCheckbox?: boolean;
  isChecked?: boolean;
  handleNotify?: (value: boolean) => void;
};

const AlertNotification = ({
  handleNotify,
  hasCheckbox,
  isChecked,
  isOpent,
  onClose,
  handleClick,
  type,
}: AlertNotificationProps) => (
  <AlertNotificationStyled isOpent={isOpent} setIsOpent={onClose} size="md">
    <Icon name={alerts[type]?.icon} />
    {alerts[type]?.title && (
      <Text type="h2" weight="bold">
        {alerts[type].title}
      </Text>
    )}
    {alerts[type]?.message && (
      <Text data-testid="notification-message" type="body2">
        {alerts[type]?.message}
      </Text>
    )}
    {hasCheckbox && (
      <CheckboxStyled>
        <CheckBox checked={isChecked} onChange={(e) => handleNotify && handleNotify(e.target.checked)} />
        <Text type="body2">Notificar al cliente por email</Text>
      </CheckboxStyled>
    )}
    {alerts[type]?.button && (
      <section>
        {alerts[type].button.map((button: INotificationButton) => (
          <Button
            key={button.value}
            {...button}
            onClick={button.action === 'handleClick' ? handleClick : onClose}
            size="xs"
          >
            {button.value}
          </Button>
        ))}
      </section>
    )}
  </AlertNotificationStyled>
);

export default AlertNotification;
