import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Button from '../../../components/Button';
import FileUploader from '../../../components/FileUploader';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { getAuthenticatedToken } from '../../../redux/auth';
import { createGalleryAsync } from '../../../redux/gallerySlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { StyledButtons } from './styles';

interface ICreateGalleryProps {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
}

const CreateGalleryItem = ({ isOpent, setIsOpent }: ICreateGalleryProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const [images, setImages] = useState<FileList>();
  const isValidInputs = !!images;

  useEffect(() => {
    !isOpent && reset();
  }, [isOpent]);

  const onSend = async () => {
    if (!images) return;
    const promises = [...images].map(async (file) => await dispatch(createGalleryAsync({ file: file, token })));
    await Promise.all(promises);
    reset();
  };

  const reset = () => {
    setImages(undefined);
    setIsOpent(false);
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="sm" isStatic data-testid="create-gallery-modal">
      <Text type="body" alignment="start" color="principal">
        Nueva imagen
      </Text>
      <FileUploader value={images} onChange={(file) => setImages(file)} multiple />
      <StyledButtons>
        <Button size="xs" variant="black" onClick={reset} data-testid="reset-form-btn">
          Cancelar
        </Button>
        <Button data-testid="button-test-add" size="xs" onClick={onSend} disabled={!isValidInputs}>
          Guardar
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default CreateGalleryItem;
