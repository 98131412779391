import styled, { keyframes } from 'styled-components';


const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div`
  border: 4px solid #FFFFFF;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-left-color: transparent;
  margin: 1rem;
  animation: ${rotate} 1s linear infinite;
`;
