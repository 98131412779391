// import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUpdateQuotation } from '../../../api/quotations/models';
import Button from '../../../components/Button';
import CheckBox from '../../../components/CheckBox';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import { Label } from '../../../components/Input/style';
import Loading from '../../../components/Loading';
import ScrollBar from '../../../components/Scrollbar';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { useQuoteNotification } from '../../../hooks/useQuoteNotification';
import { QuotationStatus, TQuotationStatus, TQuotationStatusToShow } from '../../../models/IQuotation';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getDisabledDates } from '../../../redux/disabledDateSlice';
import { fetchDisabledDatesAsync } from '../../../redux/disabledDateSlice/thunks';
import { getEventTypes } from '../../../redux/eventTypeSlice';
import { fetchEventTypesAsync } from '../../../redux/eventTypeSlice/thunks';
import { getExtraServices } from '../../../redux/extraServiceSlice';
import { fetchExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { getMenus } from '../../../redux/menuSlice';
import { fetchMenusAsync } from '../../../redux/menuSlice/thunks';
import { getQuotationList } from '../../../redux/quotationSlice';
import {
  fetchAllQuotationsAsync,
  notifyConfirmationAsync,
  updateQuotationAsync,
} from '../../../redux/quotationSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { calculatePrices } from '../../../utils/calculatePrices';
import { toGMTDate, toIsoDate } from '../../../utils/formatDateTime';
import { getFieldSchema } from '../../../utils/getFieldSchema';
import { isEqual } from '../../../utils/isEqual';
import { parseNumber } from '../../../utils/parseNumber';
import { removeKeys } from '../../../utils/removeKeys';
import { fieldSchema } from '../CreateQuotation/schema';
import AlertNotification from '../Notification';
import { AlertType } from '../Notification/constants';
import Summary from '../Summary';
import {
  ButtonContainerStyled,
  DivWrapperStyled,
  EditQuotationStyled,
  GridCellStyled,
  GridTemplateStyled,
} from './styles';

const EditQuotation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const menuList = useAppSelector(getMenus);
  const quotationList = useAppSelector(getQuotationList);
  const eventTypesList = useAppSelector(getEventTypes);
  const extraServicesList = useAppSelector(getExtraServices);
  const disabledDates = useAppSelector(getDisabledDates);
  const { id } = useParams();
  const { notification, closeNotification, updateNotification } = useQuoteNotification();
  const quotation = quotationList.find((quotation) => quotation._id === id);
  const { date, time } = toGMTDate(quotation?.dateTime ?? '');
  const schema = getFieldSchema({
    fieldSchema,
    fieldValues: { ...quotation, ...quotation?.contact, date, time },
  });
  const [menu, setMenu] = useState<string>('');
  const [status, setStatus] = useState<TQuotationStatusToShow | ''>('');
  const [eventType, setEventType] = useState<string>('');
  const [extraServices, setExtraServices] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [canNotifyConfirmation, setCanNotify] = useState(false);
  const isActiveUser = useRef(false);
  const menus = menuList.map((item) => item.name);
  const eventTypes = eventTypesList.map((event) => event.name);
  const menuSelected = menuList.find((item) => item.name === menu);
  const statusOptions = Object.keys(QuotationStatus) as TQuotationStatusToShow[];
  const today = new Date().toLocaleDateString('en-CA');
  const { fields, errors, onChangeField, isValidForm, updateValues } = useForm({ fieldSchema: schema });
  const { childrenQuantity, peopleQuantity } = fields;
  const calculatedPrices =
    menuSelected &&
    calculatePrices({
      childrenQuantity,
      extraServices,
      extraServicesList,
      menuSelected,
      peopleQuantity,
    });

  useEffect(() => {
    dispatch(fetchAllQuotationsAsync({ token }));
    dispatch(fetchMenusAsync(token));
    dispatch(fetchEventTypesAsync(token));
    dispatch(fetchExtraServicesAsync(token));
    dispatch(fetchDisabledDatesAsync(token));
    if (quotation) {
      const status = statusOptions.find((key) => QuotationStatus[key] === quotation.status);
      status && setStatus(status);
      setEventType(getEventTypeName(quotation.eventTypeId) ?? 'Otro');
      setMenu(getMenuName(quotation.menuId) ?? 'Menú no encontrado');
      setExtraServices(quotation.extraServices);
    }
  }, []);

  useEffect(() => {
    const negotiatedPrice =
      quotation?.negotiatedPrice === quotation?.estimatedPrice ? calculatedPrices?.total : quotation?.negotiatedPrice;
    menuSelected &&
      updateValues({
        estimatedPrice: calculatedPrices?.total,
        negotiatedPrice,
        childrenQuantity: menuSelected.hasChildrenMenu ? childrenQuantity : 0,
      });
  }, [peopleQuantity, childrenQuantity, menu, extraServices]);

  useEffect(() => {
    const keysNotAllowed = ['_id', 'createdAt', 'updatedAt'];
    const temp = removeKeys<IUpdateQuotation>(keysNotAllowed, quotation ?? {});
    const currentData = getQuotation();
    if (!currentData || !temp) return;
    isActiveUser.current && setHasChanges(!isEqual(temp, currentData));
  }, [fields, menu, status, eventType, extraServices]);

  if (!quotation) {
    return (
      <GridTemplateStyled data-testid="loading-test" value={1}>
        <Loading />
      </GridTemplateStyled>
    );
  }
  const updateExtraServices = (id: string) => {
    if (extraServices.includes(id)) return setExtraServices(extraServices.filter((item) => item !== id));
    return setExtraServices([...extraServices, id]);
  };
  const getEventTypeName = (id: string) => eventTypesList.find((event) => event._id === id)?.name;
  const getMenuName = (id: string) => menuList.find((menu) => menu._id === id)?.name;
  const onChangeMenu = (e: any) => {
    setMenu(e.target.value);
    const [menu] = menuList.filter((el) => el.name === e.target.value);
    const servicesOfMenu = extraServicesList.filter((el) => menu.extra_services.includes(el.name)).map((el) => el._id);
    const services = servicesOfMenu.filter((srv) => extraServices.includes(srv));
    setExtraServices(services);
  };
  const onChangeEventType = (e: any) => setEventType(e.target.value);
  const onChangeStatus = (e: any) => {
    setStatus(e.target.value);
    isActiveUser.current = true;
  };
  const onChangeDate = (value: string) => {
    const date = disabledDates.find((day) => day.date === value);
    onChangeField({ name: 'date', value });
    if (date) return updateNotification('warnDisabledDate');
  };
  function getQuotation(): IUpdateQuotation | undefined {
    const { date, time, fullName, email, phone, ...restOfFields } = fields;
    const menuId = menuList.find((item) => item.name === menu)?._id;
    const eventTypeId = eventTypesList.find((item) => item.name === eventType)?._id;
    if (!(menuId && eventTypeId)) return undefined;
    return {
      dateTime: toIsoDate(date, time),
      status: QuotationStatus[status],
      contact: { fullName, email, phone },
      menuId,
      eventTypeId,
      extraServices,
      ...restOfFields,
    };
  }
  const filterQuotation = (dateTime: string, status: TQuotationStatus) => {
    const allowedStatus: TQuotationStatus[] = [QuotationStatus.Pendiente, QuotationStatus.Confirmado];
    return toGMTDate(dateTime).date === fields.date && allowedStatus.includes(status);
  };
  const updateStatus = () => {
    setStatus('Confirmado');
    return closeNotification();
  };
  const handleNotify = (value: boolean) => setCanNotify(value);
  const handleUpadateQuotation = async (hasNotification = true) => {
    const data = getQuotation();
    id && data && (await dispatch(updateQuotationAsync({ token, quotation: data, id })));
    return hasNotification && updateNotification('redirect');
  };
  const backToPrev = () => {
    if (hasChanges) return updateNotification('warnSave');
    return navigate(-1);
  };

  const onSend = async () => {
    const prevQuotation = quotationList.find(({ dateTime, status }) => filterQuotation(dateTime, status));
    if (prevQuotation && quotation._id !== prevQuotation._id) return updateNotification('warnPrevQuotation');
    await handleUpadateQuotation();
    canNotifyConfirmation && dispatch(notifyConfirmationAsync({ token, id: quotation._id }));
    setHasChanges(false);
  };

  const actionsToNotify: Partial<Record<AlertType, () => void>> = {
    confirmSave: onSend,
    warnPrevQuotation: () => handleUpadateQuotation(),
    confirmQuotation: updateStatus,
    redirect: () => navigate('/agenda'),
    warnSave: () => navigate(-1),
  };

  return (
    <EditQuotationStyled onClick={() => (isActiveUser.current = true)}>
      <Icon data-testid="back-button-test" onClick={backToPrev} name="arrow" />
      <ScrollBar>
        <Text alignment="start" type="h1">
          Editar Cotización
        </Text>

        <DivWrapperStyled flexType={'if'}>
          <Text alignment="start" type="h3" color="principal">
            Cotización
          </Text>
          <Text alignment="start" type="h3">
            Estado:
          </Text>
          <Select defaultValue={status} onchange={onChangeStatus} options={statusOptions} />
        </DivWrapperStyled>
        <GridTemplateStyled data-testid="display-quotation-test" value={3}>
          <GridCellStyled>
            <Label>Menú</Label>
            <Select data-testid="menu-test" width="full" defaultValue={menu} onchange={onChangeMenu} options={menus} />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              type="date"
              data-testid="date-test"
              iconRight={<Icon name="calendar" />}
              value={fields.date}
              label="Fecha"
              onChange={onChangeDate}
              messageError={errors?.date?.message}
              error={errors?.date?.error}
              min={today}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="people-test"
              value={parseNumber.withMillerDot(fields.peopleQuantity.toString())}
              label="Cantidad de personas"
              onChange={(value) => onChangeField({ name: 'peopleQuantity', value: parseNumber.clean(value) })}
              messageError={errors?.peopleQuantity?.message}
              error={errors?.peopleQuantity?.error}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="time-test"
              label="Hora"
              type="time"
              iconRight={<Icon name="watch" />}
              onChange={(value) => onChangeField({ name: 'time', value })}
              value={fields.time}
              messageError={errors?.time?.message}
              error={errors?.time?.error}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Label>Tipo de evento</Label>
            <Select
              width="full"
              defaultValue={eventType ? eventType : 'Otro'}
              onchange={onChangeEventType}
              options={eventTypes}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="kids-test"
              value={parseNumber.withMillerDot(fields.childrenQuantity.toString())}
              label="Cantidad de niños"
              messageError={errors?.childrenQuantity?.message}
              error={errors?.childrenQuantity?.error}
              onChange={(value) => onChangeField({ name: 'childrenQuantity', value: parseNumber.clean(value) })}
              isDisabled={!menuSelected?.hasChildrenMenu}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="address-test"
              iconRight={<Icon name="location" />}
              value={fields.address}
              label="Dirección"
              error={errors?.address?.error}
              messageError={errors?.address?.message}
              onChange={(value) => onChangeField({ name: 'address', value })}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="edit-quotation-price"
              value={parseNumber.withMillerDot(fields.estimatedPrice)}
              label="Precio Sugerido"
              placeholder="$"
              error={errors?.estimatedPrice?.error}
              messageError={errors?.estimatedPrice?.message}
              onChange={(value) => onChangeField({ name: 'estimatedPrice', value: parseNumber.clean(value) })}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="nprice-test"
              value={
                fields.estimatedPrice === fields.negotiatedPrice
                  ? ''
                  : parseNumber.withMillerDot(fields.negotiatedPrice.toString())
              }
              label="Precio Negociado"
              placeholder={
                fields.estimatedPrice === fields.negotiatedPrice
                  ? parseNumber.withMillerDot(fields.negotiatedPrice.toString())
                  : '$'
              }
              error={errors?.negotiatedPrice?.error}
              messageError={errors?.negotiatedPrice?.message}
              onChange={(value) => onChangeField({ name: 'negotiatedPrice', value: parseNumber.clean(value) })}
            />
          </GridCellStyled>
        </GridTemplateStyled>
        {menuSelected?.extra_services?.length ? (
          <>
            <Text alignment="start" type="h3" color="principal">
              Extras
            </Text>
            <GridTemplateStyled value={2}>
              {menuSelected.extra_services.map((service) => {
                const srv = extraServicesList.find((srv) => srv.name === service);
                if (!srv) return;
                return (
                  <GridCellStyled key={service}>
                    <DivWrapperStyled flexType={'f'}>
                      <CheckBox
                        data-testid="edit-quotation-checkbox"
                        value={extraServices.includes(srv._id)}
                        onClick={() => updateExtraServices(srv._id)}
                      />
                      <Text fullWidth={false} type="h3">
                        {service}
                      </Text>
                    </DivWrapperStyled>
                  </GridCellStyled>
                );
              })}
            </GridTemplateStyled>
          </>
        ) : null}
        {menu && (peopleQuantity || childrenQuantity) && menuSelected && calculatedPrices && (
          <Summary
            {...{ calculatedPrices, childrenQuantity, menuSelected, peopleQuantity }}
            hasExtraServices={extraServices.length > 0}
          />
        )}
        <Text alignment="start" type="h3" color="principal">
          Datos del cliente
        </Text>
        <GridTemplateStyled value={3}>
          <GridCellStyled>
            <Input
              data-testid="name-test"
              value={fields.fullName}
              label="Nombre"
              placeholder="Example1234."
              error={errors?.fullName?.error}
              messageError={errors?.fullName?.message}
              onChange={(value) => onChangeField({ name: 'fullName', value })}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="phone-test"
              value={fields.phone}
              label="Teléfono"
              placeholder="+569 8765 4321"
              error={errors?.phone?.error}
              messageError={errors?.phone?.message}
              onChange={(value) => onChangeField({ name: 'phone', value })}
            />
          </GridCellStyled>
          <GridCellStyled>
            <Input
              data-testid="mail-test"
              value={fields.email}
              label="Mail"
              error={errors?.email?.error}
              messageError={errors?.email?.message}
              onChange={(value) => onChangeField({ name: 'email', value })}
            />
          </GridCellStyled>
        </GridTemplateStyled>
        <ButtonContainerStyled>
          {status === 'Pendiente' && (
            <Button variant="outline" onClick={() => updateNotification('confirmQuotation', true)}>
              Confirmar
            </Button>
          )}
          <Button
            disabled={!isActiveUser.current || !hasChanges || !isValidForm}
            onClick={() => updateNotification('confirmSave', status === 'Confirmado')}
          >
            Guardar cambios
          </Button>
        </ButtonContainerStyled>
      </ScrollBar>
      {notification.isOpent && (
        <AlertNotification
          isOpent={notification.isOpent}
          type={notification.type}
          onClose={closeNotification}
          handleClick={actionsToNotify[notification.type]}
          hasCheckbox={notification.hasCheckbox}
          isChecked={canNotifyConfirmation}
          handleNotify={handleNotify}
        />
      )}
    </EditQuotationStyled>
  );
};

export default EditQuotation;
