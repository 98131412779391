import styled from 'styled-components';

export const CustomSelectStyled = styled.div`
  position: relative;
`;

export const SelectStyled = styled.select<{ width? }>`
  min-width: 100px;
  width: ${({ width }) => (width === 'fit' ? 'fit-content' : '100%')};
  height: ${({ width }) => (width === 'fit' ? 'auto' : '45px')};
  background: transparent;
  color: white;
  font-size: 16px;
  font-family: 'Mulish';
  border-radius: 4px;
  border: 1px solid #eb9f1a;
  padding: 1.5px 27px 1.5px 5px;
  appearance: none;
  -webkit-appearance: none;
  :focus {
    outline: none;
  }
`;

export const CustomArrowStyled = styled.span`
  position: absolute;
  display: block;
  background: transparent;
  height: 100%;
  width: 2rem;
  top: 0;
  right: 1%;
  transform: rotate(90deg);
  pointer-events: none;
  cursor: pointer;
`;

export const OptionStyled = styled.option`
  background: #222222;
  text-overflow: ellipsis;
  :checked,
  :hover {
    background: #eb9f1a;
  }
`;
