import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMenu, IMenuSection } from '../../../api/menu/models';
import Button from '../../../components/Button';
import CheckBox from '../../../components/CheckBox';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import Switch from '../../../components/MenuTable/components/CheckBox';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import TextArea from '../../../components/TextArea';
import useForm from '../../../hooks/useForm';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getExtraServices } from '../../../redux/extraServiceSlice';
import { fetchExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { getMenuById } from '../../../redux/menuSlice';
import { createMenuAsync, deleteMenuAsync, updateMenuAsync } from '../../../redux/menuSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getFieldSchema } from '../../../utils/getFieldSchema';
import { parseNumber } from '../../../utils/parseNumber';
import CreateSection from '../CreateSection';
import CreateSectionItem from '../CreateSectionItem';
import EditMenuName from '../EditMenuName';
import EditSection from '../EditSection';
import RemoveMenu from '../RemoveMenu';
import RemoveMenuSection from '../RemoveMenuSection';
import RemoveSectionItem from '../RemoveSectionItem';
import { fieldSchema } from './schema';
import {
  ActionsContainer,
  ButtonSaveContainer,
  ExtraServicesContainer,
  ExtraServicesTitle,
  InputContainer,
  MainContainer,
  MenuTitleStyled,
  Section,
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionItem,
  SectionItemsContainer,
  SectionItemsContent,
  SectionItemsHeader,
  SectionItemsHeaderContent,
  SectionNotCreatedContainer,
  ServiceExtraContent,
  ServiceExtraOption,
  SwitchContainerStyled,
  TextareaStyled,
  ValueForPersonContainer,
} from './styles';

interface MenuSectionSelected extends IMenuSection {
  index?: number;
}

interface ItemSelected {
  index?: number;
  name: string;
}
export interface SectionProps {
  selected: boolean;
}

const initialSectionState = { name: '', items: [] };

const CreateMenuSection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id: menuId, name = '' } = useParams();
  const menuInitialValues = useAppSelector((state) => getMenuById(state, menuId));
  const extraServices = useAppSelector(getExtraServices);
  const schema = menuInitialValues
    ? getFieldSchema({ fieldSchema, fieldValues: { ...menuInitialValues, ...menuInitialValues.childrenMenu } })
    : getFieldSchema({ fieldSchema, fieldValues: { name } });
  const token = useAppSelector(getAuthenticatedToken);
  const { fields, errors, onChangeField, isValidForm, onSubmit } = useForm({ fieldSchema: schema });
  const [sectionSelected, setSectionSelected] = useState<MenuSectionSelected>(initialSectionState);
  const [itemSelected, setItemSelected] = useState<ItemSelected>({ name: '' });
  const [isCreateSectionOpent, setIsCreateSectionOpent] = useState(false);
  const [isCreateSectionItemOpent, setIsCreateSectionItemOpent] = useState(false);
  const [isEditSectionOpent, setIsEditSectionOpent] = useState(false);
  const [isRemoveSectionOpent, setIsRemoveSectionOpent] = useState(false);
  const [isRemoveItemSectionOpent, setIsRemoveItemSectionOpent] = useState(false);
  const [isRemoveMenuOpent, setIsRemoveMenuOpent] = useState(false);
  const [isEditMenuNameOpent, setIsEditMenuNameOpent] = useState(false);

  useEffect(() => {
    dispatch(fetchExtraServicesAsync(token));
  }, []);

  useEffect(() => {
    if (fields.name === menuInitialValues?.name) return;
    (async () => {
      menuId && (await dispatch(updateMenuAsync({ id: menuId, data: getMenuData(), token })));
      navigate(-1);
    })();
  }, [fields.name]);

  const isValidChidrenMenu = fields.hasChildrenMenu ? fields.basic_children_price > 0 : true;
  const isReadyToSave = fields.sections.length > 0 && isValidForm && isValidChidrenMenu;

  const selectSection = (section: MenuSectionSelected) => {
    setSectionSelected(section);
  };

  const addSection = (sectionName: string) => {
    onChangeField({ name: 'sections', value: [...fields.sections, { name: sectionName, items: [] }] });
    setIsCreateSectionOpent(false);
  };

  const showRemoveSectionModal = () => {
    if (typeof sectionSelected.index === 'number') {
      const section = { ...fields.sections[sectionSelected.index], index: sectionSelected.index };
      selectSection(section);
      setIsCreateSectionItemOpent(false);
      setIsEditSectionOpent(false);
      setIsRemoveSectionOpent(true);
    }
  };

  const addSectionItem = (items: string[]) => {
    if (typeof sectionSelected.index === 'number') {
      const sectionIdx = sectionSelected.index;
      const temp = JSON.parse(JSON.stringify(fields.sections));
      temp[sectionIdx].items = [...temp[sectionIdx].items, ...items];
      onChangeField({ name: 'sections', value: temp });
      setIsCreateSectionItemOpent(false);
    }
  };

  const editSection = (items: string) => {
    if (typeof sectionSelected.index === 'number') {
      const temp = JSON.parse(JSON.stringify(fields.sections));
      temp[sectionSelected.index].name = items;
      sectionSelected.name = items;
      onChangeField({ name: 'sections', value: temp });
      setIsEditSectionOpent(false);
    }
  };

  const showRemoveSectionItemModal = (idx: number) => {
    if (typeof sectionSelected.index === 'number') {
      const item = fields.sections[sectionSelected.index].items[idx];
      setItemSelected({ name: item, index: idx });
      setIsRemoveItemSectionOpent(true);
    }
  };

  const removeSectionItem = () => {
    if (typeof sectionSelected.index === 'number' && typeof itemSelected.index === 'number') {
      const temp = JSON.parse(JSON.stringify(fields.sections));
      temp[sectionSelected.index].items = temp[sectionSelected.index].items.filter(
        (_, index) => index !== itemSelected.index
      );
      onChangeField({ name: 'sections', value: temp });
      setItemSelected({ name: '' });
      setIsRemoveItemSectionOpent(false);
      setIsRemoveSectionOpent(false);
    }
  };

  const removeSection = () => {
    const sections = fields.sections.filter((_, index) => index !== sectionSelected.index);
    onChangeField({ name: 'sections', value: sections });
    setSectionSelected(initialSectionState);
    setIsRemoveSectionOpent(false);
  };

  const onChangeServices = (service: string) => {
    const svr = fields.extra_services.find((srv) => srv === service);
    if (svr) {
      const rest = fields.extra_services.filter((srv) => srv !== service);
      onChangeField({ name: 'extra_services', value: rest });
    } else {
      onChangeField({ name: 'extra_services', value: [...fields.extra_services, service] });
    }
  };

  const saveMenu = async () => {
    const isValid = onSubmit();
    if (isValid) {
      const data = getMenuData();
      menuId
        ? await dispatch(updateMenuAsync({ id: menuId, data, token }))
        : await dispatch(createMenuAsync({ token, data }));
      navigate(-1);
    }
  };

  const deleteMenu = async () => {
    menuId && (await dispatch(deleteMenuAsync({ id: menuId, token })));
    navigate(-1);
  };

  const updateMenuName = (value: string) => {
    onChangeField({ name: 'name', value });
    setIsEditMenuNameOpent(false);
  };
  const getMenuData = () => {
    const { basic_children_price, description, ...restOfFields } = fields;
    const menu = {
      ...restOfFields,
      sections: fields.sections.map((section) => ({ name: section.name, items: section.items })),
    };
    const data = fields.hasChildrenMenu ? { ...menu, childrenMenu: { basic_children_price, description } } : menu;
    return data as IMenu;
  };

  return (
    <div style={{ height: '100%' }}>
      <ScrollBar>
        <Icon data-testid="back-btn" onClick={() => navigate(-1)} name="arrow" />
        <MenuTitleStyled>
          <Text alignment="start" type="h1" weight="bold" fullWidth={false} data-testid="menu-name-text">
            {fields.name}
          </Text>
          <Icon name="pencil" onClick={() => setIsEditMenuNameOpent(true)} data-testid="edit-menu-name" />
        </MenuTitleStyled>
        <MainContainer>
          <SectionContainer>
            <SectionHeader>
              <Text alignment="start" type="h2" weight="bold">
                Secciones
              </Text>
              <Button
                data-testid="create-section-modal-btn"
                size="xs"
                variant="default"
                onClick={() => setIsCreateSectionOpent(true)}
              >
                Crear Nueva
              </Button>
            </SectionHeader>
            <SectionContent>
              <ScrollBar>
                {fields.sections.map((section, idx) => (
                  <Section
                    key={idx}
                    selected={sectionSelected.index === idx}
                    onClick={() => selectSection({ index: idx, ...section })}
                    data-testid="select-section-btn"
                  >
                    <Text type="h3" textAlignment="left" alignment="start" weight="bold">
                      {section.name}
                    </Text>
                  </Section>
                ))}
              </ScrollBar>
            </SectionContent>
          </SectionContainer>
          {typeof sectionSelected.index === 'number' ? (
            <SectionItemsContainer data-testid="section-items-container">
              <SectionItemsHeader>
                <Text type="h3" alignment="start" color="principal" weight="bold" data-testid="section-name-text">
                  {sectionSelected.name}
                </Text>
                <SectionItemsHeaderContent>
                  <Button size="xs" variant="black" onClick={showRemoveSectionModal} data-testid="remove-section-btn">
                    <Icon name="trash" style={{ marginRight: 5 }} />
                    Eliminar sección
                  </Button>
                  <Button data-testid="edit-section-btn" onClick={() => setIsEditSectionOpent(true)} size="xs">
                    Editar
                  </Button>
                  <Button
                    data-testid="add-item-to-section-btn"
                    size="xs"
                    variant="outline"
                    onClick={() => setIsCreateSectionItemOpent(true)}
                  >
                    Agregar
                  </Button>
                </SectionItemsHeaderContent>
              </SectionItemsHeader>
              <SectionItemsContent>
                {typeof sectionSelected.index === 'number' &&
                  fields.sections[sectionSelected.index].items.map((item, idx) => (
                    <SectionItem key={idx}>
                      <Input
                        value={item}
                        type="text"
                        placeholder="Añadir nombre"
                        data-testid="name-input-item"
                        border
                        fullWidth
                        iconRight={
                          <Icon
                            name="union"
                            onClick={() => showRemoveSectionItemModal(idx)}
                            data-testid="remove-section-item-btn"
                          />
                        }
                        disabled
                      />
                    </SectionItem>
                  ))}
              </SectionItemsContent>
            </SectionItemsContainer>
          ) : (
            <SectionNotCreatedContainer>
              <Icon name="book" width={80} />
              <Text>No tienes secciones creadas</Text>
            </SectionNotCreatedContainer>
          )}
        </MainContainer>
        <ExtraServicesContainer>
          <ExtraServicesTitle>
            <Text type="h3" color="principal" alignment="start" weight="bold">
              Servicios extras
            </Text>
          </ExtraServicesTitle>
          <ServiceExtraContent>
            {extraServices.map((service) => (
              <ServiceExtraOption key={service._id}>
                <CheckBox
                  value={fields.extra_services.includes(service.name)}
                  onClick={() => onChangeServices(service.name)}
                  borderColor="principal"
                  data-testid="extra-service-checkbox"
                />
                <Text fullWidth={false} type="body">
                  {service.name}
                </Text>
              </ServiceExtraOption>
            ))}
          </ServiceExtraContent>
        </ExtraServicesContainer>
        <ExtraServicesContainer>
          <ExtraServicesTitle>
            <Text type="h3" color="principal" alignment="start" weight="bold">
              Precio
            </Text>
          </ExtraServicesTitle>
          <ValueForPersonContainer>
            <InputContainer>
              <Input
                label="Precio normal"
                placeholder="$"
                min={0}
                value={parseNumber.withMillerDot(fields.basic_person_price)}
                data-testid="value-input"
                onChange={(value) => onChangeField({ name: 'basic_person_price', value: parseNumber.clean(value) })}
                error={errors?.basic_person_price?.error}
                messageError={errors?.basic_person_price?.message}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Precio con descuento"
                placeholder="$"
                data-testid="discount-input"
                value={parseNumber.withMillerDot(fields.discount_price)}
                onChange={(value) => onChangeField({ name: 'discount_price', value: parseNumber.clean(value) })}
                error={errors?.discount_price?.error}
                messageError={errors?.discount_price?.message}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Requisito descuento"
                placeholder="$"
                data-testid="people-input"
                value={fields.minimal_people}
                onChange={(value) => onChangeField({ name: 'minimal_people', value: Number(value) })}
                error={errors?.minimal_people?.error}
                messageError={errors?.minimal_people?.message}
              />
            </InputContainer>
          </ValueForPersonContainer>
        </ExtraServicesContainer>
        {fields.hasChildrenMenu && (
          <ExtraServicesContainer>
            <ExtraServicesTitle>
              <Text type="h3" color="principal" alignment="start" weight="bold">
                Menú niños
              </Text>
            </ExtraServicesTitle>
            <ValueForPersonContainer>
              <InputContainer>
                <Input
                  label="Precio"
                  placeholder="$"
                  type="number"
                  min={0}
                  value={parseNumber.withMillerDot(fields.basic_children_price)}
                  onChange={(value) => onChangeField({ name: 'basic_children_price', value: parseNumber.clean(value) })}
                  error={errors?.basic_children_price?.error}
                  messageError={errors?.basic_children_price?.message}
                  data-testid="children-menu-price"
                />
              </InputContainer>
              <TextareaStyled>
                <TextArea
                  label="Descripción"
                  value={fields.description}
                  onChange={(e) => onChangeField({ name: 'description', value: e.target.value })}
                  data-testid="children-menu-description"
                />
              </TextareaStyled>
            </ValueForPersonContainer>
          </ExtraServicesContainer>
        )}
        <ActionsContainer>
          <SwitchContainerStyled>
            <Text type="body" alignment="start" fullWidth={false}>
              Visible en la web
            </Text>
            <Switch
              checked={fields.isActive}
              onChange={() => onChangeField({ name: 'isActive', value: !fields.isActive })}
            />
          </SwitchContainerStyled>
          <SwitchContainerStyled>
            <Text type="body" alignment="start" fullWidth={false}>
              Incluye menú para niños
            </Text>
            <Switch
              checked={fields.hasChildrenMenu}
              onChange={() => onChangeField({ name: 'hasChildrenMenu', value: !fields.hasChildrenMenu })}
            />
          </SwitchContainerStyled>
          <ButtonSaveContainer>
            {menuId && (
              <Button
                data-testid="delete-menu-button"
                variant="text"
                onClick={() => setIsRemoveMenuOpent(true)}
                size="xs"
              >
                Eliminar
              </Button>
            )}
            <Button data-testid="create-menu-button" variant="default" onClick={saveMenu} disabled={!isReadyToSave}>
              Guardar cambios
            </Button>
          </ButtonSaveContainer>
        </ActionsContainer>

        {isCreateSectionOpent && (
          <CreateSection isOpent={isCreateSectionOpent} setIsOpent={setIsCreateSectionOpent} onSend={addSection} />
        )}
        {isCreateSectionItemOpent && (
          <CreateSectionItem
            isOpent={isCreateSectionItemOpent}
            setIsOpent={setIsCreateSectionItemOpent}
            onSend={addSectionItem}
            title={sectionSelected.name}
          />
        )}
        {isEditSectionOpent && (
          <EditSection
            isOpent={isEditSectionOpent}
            setIsOpent={setIsEditSectionOpent}
            onSend={editSection}
            sectionName={sectionSelected.name}
          />
        )}
        {isRemoveSectionOpent && (
          <RemoveMenuSection
            isOpent={isRemoveSectionOpent}
            setIsOpent={setIsRemoveSectionOpent}
            onSend={removeSection}
          />
        )}
        {isRemoveItemSectionOpent && (
          <RemoveSectionItem
            isOpent={isRemoveItemSectionOpent}
            setIsOpent={setIsRemoveItemSectionOpent}
            onSend={removeSectionItem}
            name={itemSelected.name}
          />
        )}
        {isRemoveMenuOpent && (
          <RemoveMenu isOpent={isRemoveMenuOpent} setIsOpent={setIsRemoveMenuOpent} onDelete={deleteMenu} />
        )}
        {isEditMenuNameOpent && (
          <EditMenuName
            isOpent={isEditMenuNameOpent}
            setIsOpent={setIsEditMenuNameOpent}
            name={fields.name}
            onSend={updateMenuName}
          />
        )}
      </ScrollBar>
    </div>
  );
};

export default CreateMenuSection;
