/* istanbul ignore file */
import styled from 'styled-components';
import { mediaQuery } from '../../../../utils/mediaQuery';

export const MenuTitleStyled = styled.div`
  display: flex;
  padding: 0 1vw 1vh 0;
  align-items: center;
  justify-content: space-between;
  ${mediaQuery.mobileToLaptop(`
    padding: 0 0vw 1vh 0;
  `)}
`;
export const ContainerScroll = styled.div`
  height: 94%;
`;
export const ButtonContainer = styled.div`
  ${mediaQuery.mobileToDesktop(`
  `)}
`;
