/* istanbul ignore file */
import URL from './url';
import { IForgotPasswordResponse, ILoginResponse } from './models';
import { IBaseFetch } from '../../models/IFetch';

export const fetchLogin = async (email: string, password: string): Promise<ILoginResponse> => {
  const { url, params } = URL.postLogin(email, password);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const forgotPassword = async (email: string): Promise<IForgotPasswordResponse> => {
  const { url, params } = URL.forgotPassword(email);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const resetPassword = async (code: string, newPassword: string): Promise<IBaseFetch> => {
  const { url, params } = URL.resetPassword(code, newPassword);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
