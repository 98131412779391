import styled from 'styled-components';
import { TScheduleLayoutProps } from '..';
import { mediaQuery } from '../../../utils/mediaQuery';

export const ScheduleLayout = styled.div<TScheduleLayoutProps>`
  display: flex;
  width: 100%;
  color: white;
  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'default')};
  ${mediaQuery.mobileToTablet(`
      display: block;
  `)};
`;

export const RightSideLayout = styled.div`
  width: 100%;
  flex: 5;
  margin-bottom: 2vh;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EventsWrapper = styled.div`
  height: 86vh;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mediaQuery.tabletOnly(`
    flex-direction: row;
    gap: 35px;
  `)}
  ${mediaQuery.laptopToUp(`
    flex-direction: row;
    gap: 35px;
  `)}
  ${mediaQuery.desktopToUp(`
    order: 2;
    justify-content: flex-end;
  `)}
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 25px;
  padding: 5px 0;
  ${mediaQuery.tabletOnly(`
    gap: 10px;
  `)}
  ${mediaQuery.laptopToUp(`
    gap: 10px;
  `)}
`;

export const EventWrapper = styled.div<{ value }>`
  background: #222222;
  min-width: 25%;
  align-items: center;
  color: #808080;
  padding: 20.5px 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 130px repeat(5, 1fr);

  :first-child {
    border-radius: ${({ value }) => (value > 1 ? '10px 10px 0 0' : '10px')};
    border-bottom: ${({ value }) => (value > 1 ? '1px solid #808080' : 'none')};
  }
  :last-child {
    border-radius: ${({ value }) => (value > 1 ? '0 0 10px 10px' : '10px')};
  }
  ${mediaQuery.laptopToDesktop(`
    grid-template-columns: repeat(3, 1fr);
  `)}
  ${mediaQuery.mobileToLaptop(`
    grid-template-columns: repeat(2, 1fr);
  `)}
`;

export const EventInfo = styled.div`
  display: flex;
  color: white;
  font-size: 16px;
  align-items: center;
  height: 100%;
  column-gap: 5px;
  padding: 4px 20px;
  :first-child {
    border-right: 1px solid #808080;
  }
  ${mediaQuery.mobileToDesktop(`
    padding: 8px 16px;
  `)}
  ${mediaQuery.mobileOnly(`
    :first-child {
      border-right: none;
    }
    :nth-child(2) {
      grid-column: 1 / -1;
    }
  `)}
`;

export const NoEvent = styled.div`
  font-size: 16px;
  align-items: center;
  height: 37px;
  white-space: nowrap;

  padding-left: 20%;
  align-items: center;
  line-height: 37px;
`;

export const EnabledText = styled.div<{ value }>`
  color: ${({ value }) => (value ? 'white' : '#A5A5A5')};
  padding-right: 10px;
`;
