import styled from 'styled-components';
import { mediaQuery } from '../../../utils/mediaQuery';

export const StyledImage = styled.img`
  max-width: 100%;
  width: 349px;
  height: 209px;
  padding-bottom: 5px;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  width: 349px;
  height: 209px;
  position: relative;
  z-index: 1;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 7px;
  right: 8px;

  ${mediaQuery.desktopToUp(`
    position: absolute;
    z-index: 2;
    bottom: 7px;
    right: 8px;
  `)}

  ${mediaQuery.mobileOnly(`
    position: absolute;
    z-index: 2;
    bottom: 7px;
    right: 8px;
  `)}
`;

export const StyledIcon = styled.div`
  margin-left: 5px;
  display: flex;
`;

export const StyledBg = styled.div`
  background: #131313;
  width: 349px;
  height: 209px;
`;

export const StyledIconCamera = styled.div`
  position: absolute;
  top: 47%;
  left: 47%;
`;
