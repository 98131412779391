import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMenu } from '../../../api/menu/models';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { ButtonWrapperStyled } from '../../../components/MenuTable/styles';
import { StateText } from '../../../components/ProductItem/styles';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getMenus, getMenusStatus } from '../../../redux/menuSlice';
import { fetchMenusAsync } from '../../../redux/menuSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { transformDate } from '../../../utils/formatDate';
import { toGMTDate } from '../../../utils/formatDateTime';
import { parseNumber } from '../../../utils/parseNumber';
import CreateMenu from '../CreateMenu';
import {
  ListBody,
  ListBodyButtonContainer,
  ListBodyColumn,
  ListBodyValue,
  ListContainer,
  ListHeader,
  ListHeaderColumn,
  MenuTitleStyled,
} from './styles';

export interface ListBodyValueProps {
  status?: string;
}
const MenuList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const menus: IMenu[] = useAppSelector(getMenus);
  const status = useAppSelector(getMenusStatus);
  const token = useAppSelector(getAuthenticatedToken);
  const [isCreateMenuOpent, setIsCreateMenuOpent] = useState(false);

  useEffect(() => {
    dispatch(fetchMenusAsync(token));
  }, []);

  return (
    <ScrollBar>
      <MenuTitleStyled>
        <Text fullWidth={false} alignment="start" type="h1">
          Gestor de menús
        </Text>
        <ButtonWrapperStyled>
          <Button data-testid="add-menu-btn" size="xs" onClick={() => setIsCreateMenuOpent(true)}>
            Crear Nuevo
          </Button>
        </ButtonWrapperStyled>
      </MenuTitleStyled>

      <ListContainer>
        <ListHeader>
          <ListHeaderColumn>Menú</ListHeaderColumn>
          <ListHeaderColumn>Valor por persona</ListHeaderColumn>
          <ListHeaderColumn>Última modificación</ListHeaderColumn>
        </ListHeader>
        {status == 'loading' ? (
          <Loading />
        ) : (
          menus.map((menu, idx) => (
            <ListBody key={idx}>
              <ListBodyColumn>Menú</ListBodyColumn>
              <ListBodyValue>
                {menu.name}
                <StateText isActive={menu.isActive}>{menu.isActive ? 'Activo' : 'Desactivado'}</StateText>
              </ListBodyValue>
              <ListBodyColumn>Valor por persona</ListBodyColumn>
              <ListBodyValue>
                ${parseNumber.withMillerDot(menu.basic_person_price)} / $
                {parseNumber.withMillerDot(menu.discount_price)}
              </ListBodyValue>
              <ListBodyColumn>Última modificación</ListBodyColumn>
              <ListBodyValue>{transformDate(toGMTDate(menu.updatedAt).date)}</ListBodyValue>
              <ListBodyColumn></ListBodyColumn>
              <ListBodyButtonContainer>
                <Button variant="outline" size="xs" onClick={() => navigate(`/menus/editar/${menu._id}`)}>
                  Modificar
                </Button>
              </ListBodyButtonContainer>
            </ListBody>
          ))
        )}
      </ListContainer>
      {isCreateMenuOpent && <CreateMenu isOpent={isCreateMenuOpent} setIsOpent={setIsCreateMenuOpent} />}
    </ScrollBar>
  );
};

export default MenuList;
