import React, { useState } from 'react';
import Icon from '../Icon';
import { CustomArrowStyled } from '../InputSelect/styles';
import { DropDownContainer, DropdownContent, OptionStyled } from './styles';

export interface IDropDownOption {
  label: string;
  onClick?: () => void;
}

export type DropDownProps = {
  onClick?: () => void;
  disabled?: boolean;
  disabledMessage?: string;
  defaultValue?: string;
  options: IDropDownOption[];
  allOption?: number;
};

const defaultProps: DropDownProps = { disabled: false, disabledMessage: '', options: [] };

const DropDown = ({ disabled, disabledMessage, options, allOption }: DropDownProps) => {
  const [open, setOpen] = useState(false);

  return (
    <DropDownContainer onClick={() => setOpen(!open)}>
      <Icon name="user" />
      {open && (
        <DropdownContent>
          {disabled && (
            <OptionStyled hidden value={undefined}>
              {disabledMessage}
            </OptionStyled>
          )}

          {options.map((opt, i) => {
            return (
              <OptionStyled onClick={opt.onClick} key={i} value={i === allOption ? 'All' : opt.label}>
                {opt.label}
              </OptionStyled>
            );
          })}
        </DropdownContent>
      )}
      <CustomArrowStyled>
        <Icon name="caret" />
      </CustomArrowStyled>
    </DropDownContainer>
  );
};

DropDown.defaultProps = defaultProps;
export default DropDown;
