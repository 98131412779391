import { createAsyncThunk } from '@reduxjs/toolkit';
import { createGallery, fetchGallery, removeGallery } from '../../../api/gallery';

interface ICreateGalleryParams {
  token: string;
  file: File;
}

interface IDeleteGalleryParams {
  token: string;
  id: string;
}

export const fetchGalleryAsync = createAsyncThunk('gallery/fetch', async (token: string, { rejectWithValue }) => {
  try {
    const response = await fetchGallery(token);
    if (response.statusCode !== 200) throw new Error(response.message);
    return response.data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

export const createGalleryAsync = createAsyncThunk(
  'gallery/create',
  async ({ token, file }: ICreateGalleryParams, { rejectWithValue }) => {
    try {
      const response = await createGallery(file, token);
      if (response.statusCode !== 201) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteGalleryAsync = createAsyncThunk(
  'gallery/delete',
  async ({ token, id }: IDeleteGalleryParams, { rejectWithValue }) => {
    try {
      const response = await removeGallery(id, token);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
