import { Dispatch, SetStateAction, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { StyledButtons } from './styles';

interface ICreateSectionProps {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  onSend: (name: string) => void;
}

const CreateSection = ({ isOpent, setIsOpent, onSend }: ICreateSectionProps) => {
  const [name, setName] = useState('');

  const isValidInputs = !!(name && name.length >= 3);

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="default" data-testid="create-section-modal" isStatic>
      <Text type="h2" alignment="start" color="principal">
        Crear sección
      </Text>
      <Input
        value={name}
        onChange={(val) => setName(val)}
        type="Name"
        placeholder="Escribe el titulo de la sección"
        data-testid="name-input"
        border
        autoFocus
      />
      <StyledButtons>
        <Button data-testid="create-section-btn" size="xs" onClick={() => onSend(name)} disabled={!isValidInputs}>
          Crear
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export default CreateSection;
