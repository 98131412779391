import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { bodyDisplayType } from '../../../components/MenuTable/type';
import Pagination from '../../../components/Pagination';
import ScrollBar from '../../../components/Scrollbar';
import Text from '../../../components/Text';
import { IQuotation, QuotationStatus } from '../../../models/IQuotation';
import { getAuthenticatedToken } from '../../../redux/auth';
import { getMenus } from '../../../redux/menuSlice';
import { fetchMenusAsync } from '../../../redux/menuSlice/thunks';
import { getQuotationList, getQuotationListStatus, getQuotationPagination } from '../../../redux/quotationSlice';
import { fetchQuotationsAsync } from '../../../redux/quotationSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { capitalize } from '../../../utils/capitalize';
import { transformDate } from '../../../utils/formatDate';
import { toGMTDate } from '../../../utils/formatDateTime';
import { getEventPrice } from '../../../utils/getEventPrice';
import { getPeopleQuantity } from '../../../utils/getPeopleQuantity';
import { parseNumber } from '../../../utils/parseNumber';
import QuotationFilters, { Filters } from '../QuotationFilters';
import {
  ButtonContainer,
  ListBody,
  ListBodyButtonContainer,
  ListBodyColumn,
  ListBodyValue,
  ListContainer,
  ListHeader,
  ListHeaderColumn,
  MenuTitleStyled,
  TitleContainer,
} from './styles';

export interface ListBodyValueProps {
  status?: string;
  hasName?: boolean;
}

const QuotationManager = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(getAuthenticatedToken);
  const quotations = useAppSelector(getQuotationList);
  const pagination = useAppSelector(getQuotationPagination);
  const status = useAppSelector(getQuotationListStatus);
  const menuList = useAppSelector(getMenus);
  const statusOptions = Object.keys(QuotationStatus);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<Filters>();

  const getMenuName = (id: string) => menuList.find((menu) => menu._id === id)?.name;

  const handleChangePage = (page: number, filters?: Filters) => {
    dispatch(fetchQuotationsAsync({ ...filters, token, page: page.toString() }));
    setFilters(filters);
  };

  useEffect(() => {
    dispatch(fetchQuotationsAsync({ token }));
    dispatch(fetchMenusAsync(token));
  }, []);

  const dispatchOnclick = (row: IQuotation) => {
    const path = bodyDisplayType.type.quotation.pathRedirect;
    navigate(`${path}/${row._id}`);
  };

  return (
    <>
      <ScrollBar>
        <MenuTitleStyled>
          <TitleContainer>
            <Text type="h1" textAlignment="left" alignment="start">
              Últimas cotizaciones
            </Text>
          </TitleContainer>
          <ButtonContainer>
            <Button size="xs" onClick={() => setOpenFilters(true)} variant="outline">
              Filtros
            </Button>
            <Button size="xs" onClick={() => navigate('crear')}>
              Crear Nueva
            </Button>
          </ButtonContainer>
        </MenuTitleStyled>

        <ListContainer>
          <ListHeader>
            <ListHeaderColumn>Fecha</ListHeaderColumn>
            <ListHeaderColumn>Estado</ListHeaderColumn>
            <ListHeaderColumn>Nombre</ListHeaderColumn>
            <ListHeaderColumn>Menú</ListHeaderColumn>
            <ListHeaderColumn>Personas</ListHeaderColumn>
            <ListHeaderColumn>Precio</ListHeaderColumn>
          </ListHeader>
          {status === 'loading' && !quotations.length ? (
            <Loading />
          ) : (
            quotations.map((quotation, idx) => {
              const { date } = toGMTDate(quotation.dateTime);
              const status = statusOptions.find((key) => QuotationStatus[key] === quotation.status);
              return (
                <ListBody key={idx}>
                  <ListBodyColumn>Fecha</ListBodyColumn>
                  <ListBodyValue>{transformDate(date)}</ListBodyValue>
                  <ListBodyColumn>Estado</ListBodyColumn>
                  <ListBodyValue status={status}>{status}</ListBodyValue>
                  <ListBodyColumn>Nombre</ListBodyColumn>
                  <ListBodyValue hasName>{capitalize(quotation.contact.fullName)} </ListBodyValue>
                  <ListBodyColumn>Menú</ListBodyColumn>
                  <ListBodyValue>{getMenuName(quotation.menuId)}</ListBodyValue>
                  <ListBodyColumn>Cantidad de personas</ListBodyColumn>
                  <ListBodyValue>
                    {getPeopleQuantity(quotation.peopleQuantity, quotation.childrenQuantity)}
                  </ListBodyValue>
                  <ListBodyColumn>precio</ListBodyColumn>
                  <ListBodyValue>
                    {'$' +
                      parseNumber.withMillerDot(getEventPrice(quotation.estimatedPrice, quotation.negotiatedPrice))}
                  </ListBodyValue>
                  <ListBodyColumn></ListBodyColumn>
                  <ListBodyValue>
                    <ListBodyButtonContainer>
                      <Button variant="outline" size="xs" onClick={() => dispatchOnclick(quotation)}>
                        Ver detalle
                      </Button>
                    </ListBodyButtonContainer>
                  </ListBodyValue>
                </ListBody>
              );
            })
          )}
        </ListContainer>
        {pagination && (
          <Pagination
            pages={pagination.total}
            currentPage={pagination.current}
            prevPage={pagination.prev}
            nextPage={pagination.next}
            onChange={(page: number) => handleChangePage(page, filters)}
          />
        )}
      </ScrollBar>
      <QuotationFilters
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        onSubmit={(filters) => handleChangePage(1, filters)}
      />
    </>
  );
};

export default QuotationManager;
