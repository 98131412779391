import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { StyledButtonsContainer } from './styles';

interface IRemoveItemMenuProps {
  isOpent: boolean;
  setIsOpent: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: () => void;
  name: string;
}

const RemoveSectionItem = ({ isOpent, setIsOpent, onSend, name }: IRemoveItemMenuProps) => {
  return (
    <Modal size="md" isOpent={isOpent} setIsOpent={setIsOpent} data-testid="remove-section-modal" isStatic>
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar {name} de esta sección</Text>
      <StyledButtonsContainer>
        <Button size="xs" variant="outline" onClick={() => setIsOpent(false)} data-testid="cancel-btn">
          Cancelar
        </Button>
        <Button data-testid="remove-section-modal-btn" size="xs" onClick={onSend}>
          Eliminar
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default RemoveSectionItem;
