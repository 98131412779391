import { useState } from 'react';
import { AlertType } from '../features/Quotation/Notification/constants';

export type TAlertNotification = {
  isOpent: boolean;
  type: AlertType;
  hasCheckbox: boolean;
};

const INITIAL_STATE: TAlertNotification = { isOpent: false, type: 'idle', hasCheckbox: false };

export const useQuoteNotification = () => {
  const [notification, setNotification] = useState<TAlertNotification>(INITIAL_STATE);

  const closeNotification = () => setNotification(INITIAL_STATE);

  const updateNotification = (type: TAlertNotification['type'], hasCheckbox = false) =>
    setNotification({ isOpent: true, type, hasCheckbox });

  return { notification, closeNotification, updateNotification };
};
