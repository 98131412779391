/* istanbul ignore file */
import { IDeleteGalleryResponse, IFetchedGalleryResponse, IPostGalleryResponse } from './models';
import URL from './url';

export const fetchGallery = async (token: string): Promise<IFetchedGalleryResponse> => {
  const { url, params } = URL.getGallery(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const createGallery = async (file: File, token: string): Promise<IPostGalleryResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  const { url, params } = URL.postGallery(token, formData);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const removeGallery = async (id: string, token: string): Promise<IDeleteGalleryResponse> => {
  const { url, params } = URL.removeGallery(token, id);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
