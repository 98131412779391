import styled from 'styled-components';

export const TextContainer = styled.div`
  width: 518px;
  padding: 10px 0 5px 0;
`;
export const ModalContainerWrapper = styled.div`
  width: 384px;
`;

export const ButttonContainer = styled.div`
  margin-top: 10px;
`;
