import { memo } from 'react';
import Loading from '../Loading';
import TableBody from './body';
import TableHeader from './header';
import { TableRowStyled, TableStyled } from './styles';

export interface ColumnsProps {
  name: string;
  dataIndex: string;
  data?: string;
  width?: string;
  minWidth?: string;
  display?: string;
  component?: (value: any) => React.ReactElement;
}

export type Tableprops = {
  data: any[];
  columns: ColumnsProps[];
  loading: boolean;
  disabledColumns?: number[];
};

const Table = ({ data, columns, loading, disabledColumns }: Tableprops) => {
  return (
    <TableStyled>
      <thead>
        <TableRowStyled>
          <TableHeader columns={columns} />
        </TableRowStyled>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={columns.length}>
              <Loading />
            </td>
          </tr>
        ) : (
          <TableBody columns={columns} list={data} disabledColumns={disabledColumns} />
        )}
      </tbody>
    </TableStyled>
  );
};

export default memo(Table);
