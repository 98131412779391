import { useState } from 'react';
import Icon from '../../components/Icon';
import { useWindowSize } from '../../hooks/useWindowSize';
import { WithChildren } from '../../models/General';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';
import Header from './header';
import SideBar from './sideBar';
import { LayoutContent, LayoutTemplate, LogoWrapperStyled, MyIcon, RightSideWrapper } from './styles';

const AdminLayout = ({ children }: WithChildren): JSX.Element => {
  const { width } = useWindowSize();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const handleShowSideBar = () => width && width < BREAKPOINTS.tablet && setShowSidebar(!showSidebar);

  return (
    <LayoutTemplate>
      <LogoWrapperStyled>
        <Icon data-testid="admin-layout-icon" name="sidebarMenu" onClick={() => setShowSidebar(!showSidebar)} />
        <MyIcon />
      </LogoWrapperStyled>
      <SideBar showSidebar={showSidebar} onClose={handleShowSideBar} />
      <RightSideWrapper>
        <Header />
        <LayoutContent>{children}</LayoutContent>
      </RightSideWrapper>
    </LayoutTemplate>
  );
};

export default AdminLayout;
