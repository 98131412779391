import styled from 'styled-components';

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
`;

export const Span = styled.span`
  width: inherit;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: #fafafa;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.32);
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Span} {
    background-color: #03ce2f;
  }

  &:checked + ${Span}:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
`;
