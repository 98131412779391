import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IDisabledDateState } from './models';
import { deleteDisabledDatesAsync, fetchDisabledDatesAsync, postDisabledDatesAsync } from './thunks';

const initialState: IDisabledDateState = {
  status: 'idle',
  list: [],
};

export const disabledDateSlice = createSlice({
  name: 'disabledDates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchDisabledDatesAsync
      .addCase(fetchDisabledDatesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisabledDatesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.list = action.payload;
      })
      .addCase(fetchDisabledDatesAsync.rejected, (state) => {
        state.status = 'failed';
        state.list = [];
      })
      //postDisabledDatesAsync
      .addCase(postDisabledDatesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postDisabledDatesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.list = action.payload.list;
      })
      .addCase(postDisabledDatesAsync.rejected, (state) => {
        state.status = 'failed';
        state.list = [];
      })
      //deleteDisabledDatesAsync
      .addCase(deleteDisabledDatesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDisabledDatesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.list = action.payload;
      })
      .addCase(deleteDisabledDatesAsync.rejected, (state) => {
        state.status = 'failed';
        state.list = [];
      });
  },
});

export const getDisabledDates = (state: RootState) => state.disabledDates.list;
export const getDisabledDatesStatus = (state: RootState) => state.disabledDates.status;

export default disabledDateSlice.reducer;
