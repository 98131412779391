import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { getAuthenticationStatus } from '../redux/auth';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import DessertList from '../features/Dessert/DessertManager';
//routes
import Home from '../features/Home';
import MenuList from '../features/MenuManager/MenuManager';
import CreateMenuSection from '../features/MenuManager/CreateMenuSection';
import Login from '../features/Login/Login';
import ResetPassword from '../features/Login/ResetPassword';
import QuotationManager from '../features/Quotation/QuotationManager';
import QuotationDetail from '../features/Quotation/QuotationDetails';
import SaladList from '../features/Salad/SaladManager';
import EditQuotation from '../features/Quotation/EditQuotation';
import CreateQuotation from '../features/Quotation/CreateQuotation';
import Agenda from '../features/Schedule';
import Gallery from '../features/Gallery/GalleryManager';
import ExtraServicesManager from '../features/ExtraServices/ExtraServicesManager';

const AppRouter = () => {
  const status = useAppSelector(getAuthenticationStatus);
  const isAuth = status === 'authenticated';

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute isAuthenticated={isAuth} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/recuperar-password/:code" element={<ResetPassword />} />
        </Route>
        <Route element={<PrivateRoute isAuthenticated={isAuth} />}>
          <Route path="/" element={<Home />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/postres" element={<DessertList />} />
          <Route path="/ensaladas" element={<SaladList />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/servicios" element={<ExtraServicesManager />} />
          <Route path="/menus">
            <Route index={true} element={<MenuList />} />
            <Route path="detalle/:id" element={<div>a</div>} />
            <Route path="create/:name" element={<CreateMenuSection />} />
            <Route path="editar/:id" element={<CreateMenuSection />} />
          </Route>
          <Route path="/cotizaciones">
            <Route index={true} element={<QuotationManager />} />
            <Route path="detalle/:id">
              <Route index={true} element={<QuotationDetail />} />
              <Route path="editar" element={<EditQuotation />} />
            </Route>
            <Route path="crear" element={<CreateQuotation />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
