import { IFormSchema } from '../../../../models/ISchema';
import { IUser } from '../../../../models/IUser';
import { expresions } from '../../../../utils/expresions/index';

export const fieldSchema: IFormSchema<Pick<IUser, 'email'>> = {
  email: {
    initialValue: '',
    rules: {
      required: true,
      conditions: {
        regex: expresions.email,
        errorMessage: 'ingrese un correo correctamente',
      },
    },
  },
};
