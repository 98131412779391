import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { StyledButtonsContainer } from '../RemoveSectionItem/styles';

interface IRemoveMenuProps {
  isOpent: boolean;
  setIsOpent: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: () => void;
}

const RemoveMenuSection = ({ isOpent, setIsOpent, onSend }: IRemoveMenuProps) => {
  return (
    <Modal size="md" isOpent={isOpent} setIsOpent={setIsOpent} data-testid="remove-section-modal" isStatic>
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar esta sección del menú?</Text>
      <StyledButtonsContainer>
        <Button size="xs" variant="outline" onClick={() => setIsOpent(false)}>
          Cancelar
        </Button>
        <Button data-testid="remove-section-modal-btn" size="xs" onClick={onSend}>
          Eliminar
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default RemoveMenuSection;
