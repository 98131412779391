import { IChildrenMenu, ICreateMenu } from '../../../../api/menu/models';
import { IFormSchema } from '../../../../models/ISchema';

interface IMenuSchema extends Omit<ICreateMenu, 'childrenMenu'>, IChildrenMenu {}

export const fieldSchema: IFormSchema<IMenuSchema> = {
  basic_person_price: {
    initialValue: 0,
    rules: {
      required: true,
      max: 1000000,
      positive: true,
    },
  },
  discount_price: {
    initialValue: 0,
    rules: {
      required: true,
      max: 1000000,
      positive: true,
    },
  },
  minimal_people: {
    initialValue: 0,
    rules: {
      required: true,
      max: 1000000,
      positive: true,
    },
  },
  name: {
    initialValue: '',
  },
  isActive: {
    initialValue: true,
  },
  extra_services: {
    initialValue: [],
  },
  sections: {
    initialValue: [],
  },
  hasChildrenMenu: {
    initialValue: false,
  },
  basic_children_price: {
    initialValue: 0,
    rules: {
      max: 1000000,
      positive: true,
    },
  },
  description: {
    initialValue: '',
  },
};
