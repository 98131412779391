import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { getAuthenticatedToken } from '../../../redux/auth';
import { deleteQuotationAsync } from '../../../redux/quotationSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface IRemoveQuotationProps {
  id: string;
  isOpent: boolean;
  setIsOpent: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveQuotation = ({ id, isOpent, setIsOpent }: IRemoveQuotationProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);

  const onDelete = () => {
    dispatch(deleteQuotationAsync({ token, id }));
    return navigate(-1);
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="md" isStatic>
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar esta cotización?</Text>
      <Button size="xs" variant="outline" onClick={() => setIsOpent(false)}>
        Cancelar
      </Button>
      <Button size="xs" onClick={onDelete}>
        Eliminar
      </Button>
    </Modal>
  );
};

export default RemoveQuotation;
