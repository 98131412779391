import styled from 'styled-components';
import { mediaQuery } from '../../../utils/mediaQuery';

export const StyledTextArea = styled.textarea`
  display: flex;
  max-width: 336px;
  width: 100%;
  height: 83px;
  resize: none;
  border: ${({ value }) => (value ? '1px solid #EB9F1A' : '1px solid #FFFFFF')};
  padding: 0.5rem;

  font-family: Mulish;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 22px;
  color: #ffffff;

  background-color: #222222;
  border-radius: 5px;
  border-width: 1px;
  border-image: none;
  &:focus {
    outline: none;
  }
  ${mediaQuery.mobileToLaptop(`
    width: 100%;
    max-width: 573px;
  `)}
`;

export const Label = styled.label`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: white;
  display: flex;
  align-self: baseline;
  margin-left: 5px;
  margin-bottom: 5px;
`;
