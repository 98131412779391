import styled from 'styled-components';
import { mediaQuery } from '../../../utils/mediaQuery';

export const DropDownContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: transparent;
  color: white;
  font-size: 18px;
  font-family: 'Mulish';
  border-radius: 4px;
  border: 1px solid #eb9f1a;
  padding: 1.3px 19px 1px 4px;
  appearance: none;
  -webkit-appearance: none;
  :focus {
    outline: none;
  }
  ${mediaQuery.tabletOnly(`
    padding: 0.8vh 3vh 0.4vh 0.1vh;
    z-index: 1;
  `)}
  ${mediaQuery.mobileOnly(`
    padding: 0.8vh 3vh 0.4vh 0.1vh;
    z-index: 1;
  `)}
`;

export const DropdownContent = styled.span`
  position: absolute;
  bottom: 10px;
  border: 1px solid #eb9f1a;
  height: 28px;
  min-width: auto;
  padding: 5px 8px;
  background-color: #2d2d2d;
  z-index: 1;
  top: 43px;
  right: -3px;
  border-radius: 5px;
  :hover {
    background: #eb9f1a;
  }
`;

export const OptionStyled = styled.option`
  text-overflow: ellipsis;
`;
