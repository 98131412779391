import { Dispatch, SetStateAction, useEffect } from 'react';
import { IExtraService, PricingType } from '../../../models/IExtraService';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/InputSelect';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import useForm from '../../../hooks/useForm';
import { getAuthenticatedToken } from '../../../redux/auth';
import { updateExtraServicesAsync } from '../../../redux/extraServiceSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getFieldSchema } from '../../../utils/getFieldSchema';
import { parseNumber } from '../../../utils/parseNumber';
import { fieldSchema } from '../CreateExtraService/schema';
import { ButtonContainer } from './styles';

type EditExtraServiceProps = {
  isOpent: boolean;
  setIsOpent: Dispatch<SetStateAction<boolean>>;
  extraServiceSelected: IExtraService;
  onDelete: () => void;
};

const EditExtraService = ({ isOpent, setIsOpent, extraServiceSelected, onDelete }: EditExtraServiceProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const { _id: id, name, price, pricingType } = extraServiceSelected;
  const schema = getFieldSchema({ fieldSchema, fieldValues: { name, price, pricingType } });
  const { fields, onChangeField, errors, isValidForm, updateValues } = useForm({ fieldSchema: schema });

  useEffect(() => {
    const { name, price, pricingType } = extraServiceSelected;
    updateValues({ name, price, pricingType });
  }, [extraServiceSelected]);

  const onSend = () => {
    dispatch(updateExtraServicesAsync({ token, id, extraService: fields }));
    setIsOpent(false);
  };

  return (
    <Modal isOpent={isOpent} setIsOpent={setIsOpent} size="sm" isStatic>
      <Text type="h2">Editar servicio</Text>
      <Input
        data-testid="edit-extra-service-name"
        label="Nombre"
        placeholder="Escribe el nombre del servicio"
        labelColor="selected"
        value={fields.name}
        onChange={(value) => onChangeField({ name: 'name', value })}
        error={errors?.name?.error}
        messageError={errors?.name?.message}
      />
      <Input
        data-testid="edit-extra-service-price"
        label="Precio"
        labelColor="selected"
        value={parseNumber.withMillerDot(fields.price)}
        onChange={(value) => onChangeField({ name: 'price', value: parseNumber.clean(value) })}
        error={errors?.price?.error}
        messageError={errors?.price?.message}
      />
      <Select
        label="Tipo precio"
        value={fields.pricingType}
        onChange={(value) => onChangeField({ name: 'pricingType', value })}
        options={Object.values(PricingType)}
      />
      <ButtonContainer>
        <Button data-testid="edit-extra-service-delete" size="xs" variant="outline" onClick={onDelete}>
          Eliminar
        </Button>
        <Button data-testid="edit-extra-service-save" size="xs" disabled={!isValidForm} onClick={onSend}>
          Guardar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default EditExtraService;
