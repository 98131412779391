import { IDetailQuotation } from '../../../models/IQuotation';

export const quotationTableHeader: IDetailQuotation[] = [
  {
    label: 'Cliente',
    value: 'fullName',
    icon: { name: 'user' },
  },
  {
    label: 'Mail',
    value: 'email',
    icon: { name: 'mail' },
  },
  {
    label: 'Teléfono',
    value: 'phone',
    icon: { name: 'phone' },
  },
  {
    label: 'Menú Elegido',
    value: 'menuId',
    icon: { name: 'book' },
  },
  {
    label: 'Fecha',
    value: 'dateTime',
    icon: { name: 'calendar' },
  },
  {
    label: 'Cantidad de personas',
    value: 'peopleQuantity',
    icon: { name: 'users' },
  },
  {
    label: 'Cantidad de niños',
    value: 'childrenQuantity',
    icon: { name: 'account' },
  },
  {
    label: 'Precio de cotización',
    value: 'negotiatedPrice',
    icon: { name: 'coin' },
  },
  {
    label: 'Servicios extra',
    value: 'extraServices',
    icon: { name: 'coin' },
  },
  {
    label: 'Tipo de evento',
    value: 'eventTypeId',
    icon: { name: 'fork' },
  },
  {
    label: 'Dirección del evento',
    value: 'address',
    icon: { name: 'location' },
  },
];
