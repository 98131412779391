export const parseNumber = {
  withMillerDot: (e) => {
    if (e && !isNaN(parseFloat(e))) {
      const value = parseFloat(e).toLocaleString('es-CL');
      return value;
    } else {
      return '';
    }
  },
  clean: (e: string) => {
    if (e && !isNaN(parseFloat(e))) {
      const inputValue = e.replace(/[,.]/g, '');
      const value = parseFloat(inputValue);
      return value;
    } else {
      return '';
    }
  },
};
