import { ISignInProps } from '../../../../models/IAuth';
import { IFormSchema } from '../../../../models/ISchema';
import { expresions } from '../../../../utils/expresions/index';

export const fieldSchema: IFormSchema<ISignInProps> = {
  email: {
    initialValue: '',
    rules: {
      required: true,
      conditions: {
        regex: expresions.email,
        errorMessage: 'ingrese un correo correctamente',
      },
    },
  },
  password: {
    initialValue: '',
    rules: {
      required: true,
      minLength: 6,
      maxLength: 32,
    },
  },
};
