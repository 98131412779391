import React, { ReactElement } from 'react';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';

type RouterProps = {
  isAuthenticated: boolean;
  redirectPath?: string;
} & RouteProps;

const PublicRoute = ({ isAuthenticated, redirectPath = '/' }: RouterProps): ReactElement => {
  return isAuthenticated ? <Navigate to={redirectPath} /> : <Outlet />;
};

export default PublicRoute;
