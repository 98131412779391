/* istanbul ignore file */
import {
  ICreateMenu,
  ICreatedMenuResponse,
  IFetchedMenuResponse,
  IMenu,
  IUpdateMenu,
  IUpdatedMenuResponse,
} from './models';
import URL from './url';

export const fetchAllMenus = async (token: string): Promise<IFetchedMenuResponse<IMenu[]>> => {
  const { url, params } = URL.getAllMenus(token);
  const response = await fetch(url, params);
  const data = await response.json();

  return data;
};

export const createMenu = async (token: string, body: ICreateMenu): Promise<ICreatedMenuResponse> => {
  const { url, params } = URL.postMenu(token, body);
  const response = await fetch(url, params);
  const data = await response.json();

  return data;
};

export const updateMenu = async (id: string, body: IUpdateMenu, token: string): Promise<IUpdatedMenuResponse> => {
  const { url, params } = URL.putMenu(id, body, token);
  const response = await fetch(url, params);
  const data = await response.json();

  return data;
};

export const deleteMenu = async (id: string, token: string): Promise<IFetchedMenuResponse<IMenu[]>> => {
  const { url, params } = URL.deleteMenu(id, token);
  const response = await fetch(url, params);
  const data = await response.json();

  return data;
};
