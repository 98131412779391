import { IMenu } from '../../../api/menu/models';
import Text from '../../../components/Text';
import { calculatePrices } from '../../../utils/calculatePrices';
import { parseNumber } from '../../../utils/parseNumber';
import { SummaryWrapperStyled } from './styles';

export interface SummaryWrapperProps {
  hasChildrenMenu: boolean;
}

interface SummaryProps {
  menuSelected: IMenu;
  calculatedPrices: ReturnType<typeof calculatePrices>;
  peopleQuantity: number;
  childrenQuantity: number;
  hasExtraServices: boolean;
}

const Summary = ({
  calculatedPrices,
  childrenQuantity,
  hasExtraServices,
  menuSelected,
  peopleQuantity,
}: SummaryProps) => {
  return (
    <>
      <Text alignment="start" type="h3" color="principal">
        Resumen
      </Text>
      <SummaryWrapperStyled hasChildrenMenu={menuSelected.hasChildrenMenu}>
        <div>
          <Text type="body2">Precio por adulto</Text>
          <Text type="body2"> ${parseNumber.withMillerDot(menuSelected?.basic_person_price)}</Text>
        </div>
        {menuSelected?.hasChildrenMenu && (
          <div>
            <Text type="body2">Precio menu para niños</Text>
            <Text type="body2">${parseNumber.withMillerDot(menuSelected?.childrenMenu?.basic_children_price)}</Text>
          </div>
        )}
        <section>
          <div>
            <Text type="body2">Menu adultos</Text>
            <Text type="body2">
              ${peopleQuantity ? parseNumber.withMillerDot(calculatedPrices?.adultMenuValue) : 0}
            </Text>
          </div>
          {menuSelected?.hasChildrenMenu && (
            <div>
              <Text type="body2">Menu niños</Text>
              <Text type="body2">
                ${childrenQuantity ? parseNumber.withMillerDot(calculatedPrices?.childrenMenuValue) : 0}
              </Text>
            </div>
          )}
          <div>
            <Text type="body2">Total menus</Text>
            <Text type="body2">
              $
              {calculatedPrices
                ? parseNumber.withMillerDot(calculatedPrices?.adultMenuValue + calculatedPrices?.childrenMenuValue)
                : 0}
            </Text>
          </div>
        </section>
        <section>
          <div>
            <Text type="body2">Total servicios extra</Text>
            <Text type="body2">
              ${hasExtraServices ? parseNumber.withMillerDot(calculatedPrices?.totalValueExtraServices) : 0}
            </Text>
          </div>
        </section>
        <div>
          <Text type="body2">Total sugerido</Text>
          <Text type="body2">${parseNumber.withMillerDot(calculatedPrices?.suggestedPrice)}</Text>
        </div>
        <div>
          <Text type="body2" color="principal" weight="bold">
            Total aproximado
          </Text>
          <Text type="body2" color="principal" weight="bold">
            ${parseNumber.withMillerDot(calculatedPrices?.total)}
          </Text>
        </div>
      </SummaryWrapperStyled>
    </>
  );
};

export default Summary;
