import React, { InputHTMLAttributes, useRef } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { StyledImage, ButtonContainer, StyledIcon, ImageContainer, StyledBg, StyledIconCamera } from './styles';

export interface ImageProps extends InputHTMLAttributes<unknown> {
  src?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ProductImageDetail = ({ src, onChange, ...otherProps }: ImageProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onButtonClick = () => {
    inputFileRef.current?.click();
  };
  return (
    <ImageContainer>
      {src ? (
        <StyledImage src={src} alt={'imagen del producto'} />
      ) : (
        <StyledBg>
          <StyledIconCamera>
            <Icon name="camera" />
          </StyledIconCamera>
        </StyledBg>
      )}
      <ButtonContainer>
        <Button variant="transparent" pill size="xxs" onClick={onButtonClick}>
          {src ? 'Cambiar' : 'Agregar'} foto
          <StyledIcon>
            <Icon name="camera" />
          </StyledIcon>
        </Button>
      </ButtonContainer>
      <input type="file" accept=".jpg, .jpeg, .png" onChange={onChange} ref={inputFileRef} {...otherProps} />
    </ImageContainer>
  );
};

export default ProductImageDetail;
