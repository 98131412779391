import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISalad } from '../../api/salads/models';
import { TFetchStatus } from '../../models/IFetch';
import { RootState } from '../../store';
import { ISaladState } from './models';
import { postSaladAsync, putSaladAsync, deleteSaladAsync, fetchAllSaladsAsync } from './thunks';

export const initialState: ISaladState = {
  salads: [],
  status: 'idle',
  error: '',
};

export const saladSlice = createSlice({
  name: 'salad',
  initialState,
  reducers: {
    updateSaladList: (state, action: PayloadAction<ISaladState['salads']>) => {
      state.salads = action.payload;
    },
  },
  extraReducers: (builder) => {
    /*Fetch Salads*/
    builder
      .addCase(fetchAllSaladsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllSaladsAsync.fulfilled, (state, action) => {
        state.salads = action.payload.data ? action.payload.data : [];
        state.status = 'idle';
      })
      .addCase(fetchAllSaladsAsync.rejected, (state) => {
        state.status = 'failed';
        state.salads = [];
      });
    /*Post Salad*/
    builder
      .addCase(postSaladAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSaladAsync.fulfilled, (state, action) => {
        state.salads = action.payload?.data ? action.payload.data.list : [];
        state.status = 'idle';
      })
      .addCase(postSaladAsync.rejected, (state) => {
        state.status = 'failed';
      });
    /*Put salad */
    builder
      .addCase(putSaladAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putSaladAsync.fulfilled, (state, action) => {
        state.salads = action.payload?.data ? action.payload.data.list : [];
        state.status = 'idle';
      })
      .addCase(putSaladAsync.rejected, (state) => {
        state.status = 'failed';
      });
    /*Delete salad*/
    builder
      .addCase(deleteSaladAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSaladAsync.fulfilled, (state, action) => {
        state.salads = action.payload?.data ? action.payload.data : [];
        state.status = 'idle';
      })
      .addCase(deleteSaladAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { updateSaladList } = saladSlice.actions;

export const getSalads = (state: RootState): ISalad[] => state.salads.salads;

export const getSaladListStatus = (state: RootState): TFetchStatus => state.salads.status;

export default saladSlice.reducer;
