import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { getAuthenticatedToken } from '../../../redux/auth';
import { deleteGalleryAsync } from '../../../redux/gallerySlice/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { StyledButtonsContainer } from '../../Dessert/RemoveDessert/styles';

interface RemoveGalleryItemProps {
  id?: string;
  isOpent: boolean;
  setIsOpent: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveGalleryItem = ({ id, isOpent, setIsOpent }: RemoveGalleryItemProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);

  const handleRemove = async () => {
    if (!id) return;
    await dispatch(deleteGalleryAsync({ id, token }));
    setIsOpent(false);
  };

  return (
    <Modal size="md" isOpent={isOpent} setIsOpent={setIsOpent} data-testid="remove-gallery-modal" isStatic>
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar esta imagen de la galería?</Text>
      <StyledButtonsContainer>
        <Button size="xs" variant="outline" onClick={() => setIsOpent(false)}>
          Cancelar
        </Button>
        <Button data-testid="remove-modal-btn" size="xs" onClick={handleRemove}>
          Eliminar
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default RemoveGalleryItem;
