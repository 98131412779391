import Icon from '../Icon';
import { CustomArrowStyled, OptionItem, SelectContainer, SelectError, SelectLabel, SelectStyled } from './styles';

export interface SelectProps {
  value?: string | number;
  onChange: (value: string | number) => void;
  options: string[] | number[];
  error?: boolean;
  errorMessage?: string;
  label?: string;
  withDefaultOption?: boolean;
}

const Select = ({
  value,
  onChange,
  options,
  label,
  error = false,
  errorMessage,
  withDefaultOption = false,
}: SelectProps) => {
  return (
    <>
      {label && <SelectLabel>{label}</SelectLabel>}
      <SelectContainer>
        <SelectStyled value={value} onChange={({ target }) => onChange(target.value || '')} data-testid="select-input">
          {withDefaultOption && <OptionItem value="">Seleccionar</OptionItem>}
          {options.map((option, idx) => (
            <OptionItem key={idx} value={option}>
              {option}
            </OptionItem>
          ))}
        </SelectStyled>
        <CustomArrowStyled>
          <Icon name="caret" />
        </CustomArrowStyled>
      </SelectContainer>
      {error && <SelectError>{errorMessage}</SelectError>}
    </>
  );
};

export default Select;
