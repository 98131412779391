import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICreateMenu, IUpdateMenu } from '../../../api/menu/models';
import { createMenu, deleteMenu, fetchAllMenus, updateMenu } from '../../../api/menu';

interface ICreateMenuParams {
  token: string;
  data: ICreateMenu;
}

interface IUpdateMenuParams {
  token: string;
  data: IUpdateMenu;
  id: string;
}

interface IDeleteMenuParams {
  token: string;
  id: string;
}

export const fetchMenusAsync = createAsyncThunk('menus/fetch', async (token: string, { rejectWithValue }) => {
  try {
    const response = await fetchAllMenus(token);
    if (response.statusCode !== 200) throw new Error(response.message);

    return response.data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

export const createMenuAsync = createAsyncThunk(
  'menus/create',
  async ({ token, data }: ICreateMenuParams, { rejectWithValue }) => {
    try {
      const response = await createMenu(token, data);
      if (response.statusCode !== 200) throw new Error(response.message);

      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateMenuAsync = createAsyncThunk(
  'menus/update',
  async ({ id, token, data }: IUpdateMenuParams, { rejectWithValue }) => {
    try {
      const response = await updateMenu(id, data, token);
      if (response.statusCode !== 200) throw new Error(response.message);

      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteMenuAsync = createAsyncThunk(
  'menus/delete',
  async ({ id, token }: IDeleteMenuParams, { rejectWithValue }) => {
    try {
      const response = await deleteMenu(id, token);
      if (response.statusCode !== 200) throw new Error(response.message);

      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
