import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLogin, forgotPassword, resetPassword } from '../../../api/auth';

interface ILoginData {
  email: string;
  password: string;
}

interface IResetPasswordParams {
  code: string;
  newPassword: string;
}

export const fetchLoginAsync = createAsyncThunk(
  'auth/fetchLoginAsync',
  async ({ email, password }: ILoginData, { rejectWithValue }) => {
    try {
      const data = await fetchLogin(email, password);
      if (data.statusCode === 401 || data.error)
        throw new Error('No fue posible iniciar sesión con tu cuenta, inténtelo nuevamente.');
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const forgotPasswordAsync = createAsyncThunk(
  'auth/forgotPasswordAsync',
  async ({ email }: Pick<ILoginData, 'email'>, { rejectWithValue }) => {
    try {
      const data = await forgotPassword(email);
      if (data.statusCode !== 200) throw new Error('El email ingresado no existe, inténtelo nuevamente.');
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPasswordAsync',
  async ({ code, newPassword }: IResetPasswordParams, { rejectWithValue }) => {
    try {
      const data = await resetPassword(code, newPassword);
      if (data.statusCode !== 200)
        throw new Error('El codigo ingresado no es válido o ha expirado, inténtelo nuevamente.');
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
