import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { StyledButtonsContainer } from '../RemoveSectionItem/styles';

interface IRemoveMenuProps {
  isOpent: boolean;
  setIsOpent: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
}

const RemoveMenu = ({ isOpent, setIsOpent, onDelete }: IRemoveMenuProps) => {
  return (
    <Modal size="md" isOpent={isOpent} setIsOpent={setIsOpent} data-testid="remove-menu-modal" isStatic>
      <Icon name="warning" />
      <Text type="body">¿Estás seguro que quieres eliminar este menú?</Text>
      <StyledButtonsContainer>
        <Button size="xs" variant="outline" onClick={() => setIsOpent(false)}>
          Cancelar
        </Button>
        <Button data-testid="remove-menu-modal-btn" size="xs" onClick={onDelete}>
          Eliminar
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default RemoveMenu;
