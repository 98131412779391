import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteDisabledDate, fetchDisabledDates, postDisabledDate } from '../../../api/quotations';

export interface IDisabledDateParams {
  token: string;
  date: string;
}

export const fetchDisabledDatesAsync = createAsyncThunk(
  'disabledDates/fetch',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await fetchDisabledDates(token);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const postDisabledDatesAsync = createAsyncThunk(
  'disabledDates/post',
  async ({ token, date }: IDisabledDateParams, { rejectWithValue }) => {
    try {
      const response = await postDisabledDate(date, token);
      if (response.statusCode !== 201) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteDisabledDatesAsync = createAsyncThunk(
  'disabledDates/delete',
  async ({ token, date }: IDisabledDateParams, { rejectWithValue }) => {
    try {
      const response = await deleteDisabledDate(date, token);
      if (response.statusCode !== 200) throw new Error(response.message);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
